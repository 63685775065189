import i18n from '../i18n'
import { getTabTranslation as getDepositTabTranslation } from './reports/depositReport'
import { getTabTranslation as getPartnerTabTranslation } from './reports/partnersReport'
import { getTabTranslation as getStatisticsTabTranslation } from './reports/statisticsReport'
const { t } = i18n.global

export enum REPORTS_TABS {
  SALES_REPORT = 'report.tabs.salesReport',
  STATISTICS_REPORT = 'report.tabs.statisticsReport',
  PARTNERS_REPORT = 'report.tabs.partnersReport',
  ACCOUNTING_REPORT = 'report.tabs.accountingReport',
  DEPOSIT_REPORT = 'report.tabs.depositReport',
}

export enum REPORTS_TYPES {
  SALES = 0,
  DEPOSIT = 1,
  PARTNERS = 2,
  STATISTICS = 3,
}
export const getReportsTabTranslation = (key: string) => {
  return (
    {
      [REPORTS_TABS.SALES_REPORT]: t('report.tabs.salesReport'),
      [REPORTS_TABS.STATISTICS_REPORT]: getStatisticsTabTranslation(),
      [REPORTS_TABS.DEPOSIT_REPORT]: getDepositTabTranslation(),
      [REPORTS_TABS.PARTNERS_REPORT]: getPartnerTabTranslation(),
    }[key] || key
  )
}
export enum SALES_REPORT_HEADER_TH {
  TYPE = 'type_label',
  SALES_ARTICLE = 'name',
  PAYOUT_DATE = 'payout_at',
  REFUNDED_DATE = 'refunded_at',
  CLIENTS_NAME = 'client',
  CLIENTS_COUNTRY = 'client_country',
  PRICE_LINE = 'price_line',
  QUANTITY = 'quantity',
  VAT = 'vat_percentage',
  SUM_WITH_VAT = 'total_price_with_vat',
  SUM_WITHOUT_VAT = 'total_price',
  TOTAL_VAT = 'total_vat',
  PARTNER_PAYOUT = 'partner_payout',
  COMMISSION_FEE = 'commission',
  REFUND_FEE = 'refunded_fees',
  NET = 'payout',
}

export const getTableHeaderTranslation = (key: string) => {
  return (
    {
      [SALES_REPORT_HEADER_TH.TYPE]: t('report.sales.header.type'),
      [SALES_REPORT_HEADER_TH.SALES_ARTICLE]: t(
        'report.sales.header.salesArticle',
      ),
      [SALES_REPORT_HEADER_TH.PAYOUT_DATE]: t('report.sales.header.payoutDate'),
      [SALES_REPORT_HEADER_TH.REFUNDED_DATE]: t(
        'report.sales.header.refundedDate',
      ),
      [SALES_REPORT_HEADER_TH.CLIENTS_NAME]: t(
        'report.sales.header.clientsName',
      ),
      [SALES_REPORT_HEADER_TH.CLIENTS_COUNTRY]: t(
        'report.sales.header.clientsCountry',
      ),
      [SALES_REPORT_HEADER_TH.PRICE_LINE]: t('report.sales.header.priceLine'),
      [SALES_REPORT_HEADER_TH.QUANTITY]: t('report.sales.header.quantity'),
      [SALES_REPORT_HEADER_TH.VAT]: t('report.sales.header.vat'),
      [SALES_REPORT_HEADER_TH.SUM_WITH_VAT]: t(
        'report.sales.header.sumWithVat',
      ),
      [SALES_REPORT_HEADER_TH.SUM_WITHOUT_VAT]: t(
        'report.sales.header.sumWithoutVat',
      ),
      [SALES_REPORT_HEADER_TH.TOTAL_VAT]: t('report.sales.header.totalVat'),
      [SALES_REPORT_HEADER_TH.PARTNER_PAYOUT]: t(
        'report.sales.header.partnerPayout',
      ),
      [SALES_REPORT_HEADER_TH.COMMISSION_FEE]: t(
        'report.sales.header.commissionFee',
      ),
      [SALES_REPORT_HEADER_TH.REFUND_FEE]: t('report.sales.header.refundFee'),
      [SALES_REPORT_HEADER_TH.NET]: t('report.sales.header.net'),
    }[key] || key
  )
}

export enum REPORT_DATA_TYPES {
  HOUSING = 'HOUSING',
  ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE',
  EVENT = 'EVENT',
  EXPERIENCE = 'EXPERIENCE',
  MONEYJAR = 'MONEYJAR',
}

export const getReportDataTypesTranslation = (key: string) => {
  return (
    {
      [REPORT_DATA_TYPES.ADDITIONAL_SERVICE]: t(
        'report.table.additionalService',
      ),
      [REPORT_DATA_TYPES.EVENT]: t('report.table.event'),
      [REPORT_DATA_TYPES.EXPERIENCE]: t('report.table.experience'),
      [REPORT_DATA_TYPES.MONEYJAR]: t('report.table.moneyjar'),
      [REPORT_DATA_TYPES.HOUSING]: t('report.table.housing'),
    }[key] || key
  )
}

export enum TRAVEL_PURPOSES {
  TRAVEL_PURPOSE = 'travel_purpose',
  ROOMS_SOLD_EE = 'rooms_sold_ee',
  HOUSING_OVERNIGHT_EE = 'housing_overnight_ee',
  ROOMS_SOLD_NON_EE = 'rooms_sold_non_ee',
  HOUSING_OVERNIGHT_NON_EE = 'housing_overnight_non_ee',
}

export enum TRAVELLERS_COUNTRIES {
  COUNTRY = 'country',
  COUNTRY_CODE = 'country_code',
  ROOMS_SOLD_PER_COUNTRY = 'rooms_sold',
  HOUSING_OVERNIGHT_PER_COUNTRY = 'housing_overnight',
}

export enum STATISTICS_REPORT_TRAVEL_PURPOSES_TYPES {
  WORK = 'WORK',
  VACATION = 'VACATION',
}

export const getStatisticsHeaderTranslation = (key: string) => {
  return (
    {
      [TRAVEL_PURPOSES.TRAVEL_PURPOSE]: t('travelPurpose'),
      [TRAVEL_PURPOSES.ROOMS_SOLD_EE]: t('roomsSoldEe'),
      [TRAVEL_PURPOSES.HOUSING_OVERNIGHT_EE]: t('housingOvernightEe'),
      [TRAVEL_PURPOSES.ROOMS_SOLD_NON_EE]: t('roomsSoldNonEe'),
      [TRAVEL_PURPOSES.HOUSING_OVERNIGHT_NON_EE]: t('housingOvernightNonEe'),
      [TRAVELLERS_COUNTRIES.COUNTRY]: t('travellersCountry.country'),
      [TRAVELLERS_COUNTRIES.COUNTRY_CODE]: t('travellersCountry.countryCode'),
      [TRAVELLERS_COUNTRIES.ROOMS_SOLD_PER_COUNTRY]: t(
        'travellersCountry.roomsSold',
      ),
      [TRAVELLERS_COUNTRIES.HOUSING_OVERNIGHT_PER_COUNTRY]: t(
        'travellersCountry.housingOvernight',
      ),
    }[key] || key
  )
}

export const getStatisticsReportTravelPurposesTranslations = (key: string) => {
  return (
    {
      [STATISTICS_REPORT_TRAVEL_PURPOSES_TYPES.WORK]: t('work'),
      [STATISTICS_REPORT_TRAVEL_PURPOSES_TYPES.VACATION]: t('vacation'),
    }[key] || key
  )
}

export const getStatisticsHeaderTranslationsArray = (headerNames: any) => {
  const translations = [] as Array<string>
  headerNames.forEach((item: string) => {
    translations.push(getStatisticsHeaderTranslation(item))
  })
  return translations
}
