import {
  AdminCompanyIsTestCompanyUpdateData,
  AdminCompanyListItem,
  AdminCompanyListParams,
  NewCompanyPayload,
  NewCompanyResponse,
} from '@/models/company.model'
import { Paginated, PaginationParams } from '@/models/shared.model'
import axios from 'axios'
import { Ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

// https://tkdodo.eu/blog/effective-react-query-keys
const companyKeys = {
  all: ['companies'] as const,
  lists: () => [...companyKeys.all, 'list'] as const,
  adminLists: () => [...companyKeys.lists(), 'adminList'] as const,
  adminList: (
    pagination: Ref<PaginationParams>,
    filters: Ref<AdminCompanyListParams>,
  ) => [...companyKeys.adminLists(), { pagination, filters }] as const,
  details: () => [...companyKeys.all, 'detail'] as const,
  detail: (id: number) => [...companyKeys.details(), id] as const,
}

export function useAddCompanyMutation() {
  const queryClient = useQueryClient()

  return useMutation(
    async (form: NewCompanyPayload) => {
      const { data } = await axios.post<NewCompanyResponse>(`companies/`, form)
      return data
    },
    {
      onSuccess: (data) => {
        // Set cache of this one company object
        queryClient.setQueryData(companyKeys.detail(data.id), data)
      },
    },
  )
}

/**
 * Returns paginated company list. Is not meant for global usage as options or other usages.
 * Only for Touringery admins.
 * @param pagination Pagination specific parameters
 * @param filters Parameters for ordering and filtering
 */
export function useAdminCompanyListQuery(
  pagination: Ref<PaginationParams>,
  filters: Ref<AdminCompanyListParams>,
) {
  return useQuery(
    companyKeys.adminList(pagination, filters),
    async () => {
      const { data } = await axios.get<Paginated<AdminCompanyListItem[]>>(
        `touringery/companies/`,
        {
          params: {
            ...filters.value,
            page: pagination.value.page,
            page_size: pagination.value.page_size,
          },
        },
      )
      return data
    },
    {
      retry: 0,
    },
  )
}

/**
 * Updates company's is_test_company flag.
 * Only for Touringery admins.
 */
export function useAdminCompanyIsTestCompanyUpdateMutation() {
  const queryClient = useQueryClient()

  return useMutation(
    async (payload: AdminCompanyIsTestCompanyUpdateData) => {
      const { data } = await axios.put<AdminCompanyListItem>(
        `touringery/companies/${payload.companyId}/is-test-company/`,
        {
          is_test_company: payload.isTestCompany,
        },
      )
      return data
    },
    {
      onSuccess: () => {
        // Invalidate admin company lists
        queryClient.invalidateQueries(companyKeys.lists())
      },
    },
  )
}
