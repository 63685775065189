<template>
  <div>
    <div class="contract__title-wrapper">
      <div class="contract__title">TOURINGERY ÜLDTINGIMUSED ÄRIKLIENTIDELE</div>
      <div class="contract__sub-title">Viimati muudetud 11.09.2023</div>
    </div>
    <div class="contract__chapter">
      <p>
        <span class="contract__bold">Turingery OÜ</span>, registrikood 16277040,
        KMKR number EE102442760, registrijärgne asukoht Tiku, Kiidjärve küla
        Põlva vald Põlvamaa 63604 (edaspidi:
        <span class="contract__bold">Touringery</span>) on veebisaidi
        touringery.ee (edaspidi: <span class="contract__bold">Platvorm</span>)
        omanik ja käitaja.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">
        1. PLATVORMI SISU JA ÜLDTINGIMUSED
      </div>
      <p class="contract__chapter-text">
        1.1. Platvorm on veebileht, mille kaudu saavad Touringery ärikliendid,
        kes on juriidilisest isikust või füüsilisest isikust teenusepakkujad
        (edaspidi: <span class="contract__bold">Partner</span>) ja erakliendid
        (edaspidi: <span class="contract__bold">Lõppkasutaja</span>) juurdepääsu
        Touringery osutatavatele platvormiteenustele (edaspidi:
        <span class="contract__bold">Platvormiteenused</span>).
      </p>
      <p class="contract__chapter-text">
        1.2. Platvorm on mõeldud selleks, et osutada Partnerile ja
        Lõppkasutajale broneerimiskeskkonna teenust, mille kaudu on Partneril
        võimalik korraldada enda teenuste (edaspidi:
        <span class="contract__bold">Teenused</span>) pakkumist Lõppkasutajale
        nii, et Lõppkasutaja saab Platvormi kaudu teha broneeringuid Teenustele
        ja nende eest tasuda.
      </p>
      <p class="contract__chapter-text">
        1.3. Üldtingimusi (edaspidi:
        <span class="contract__bold">Üldtingimused</span>) kohaldatakse kõigile
        õigussuhetele, mis tekivad Partneri ja Touringery vahel (Partner ja
        Touringery edaspidi ühiselt nimetatud ka kui
        <span class="contract__bold">Pooled</span>). Siinsed Üldtingimused
        kohalduvad ka muudele kokkulepetele, mis sõlmitakse Poolte vahel
        jooksvalt Platvormiteenuste kasutamiseks.
      </p>
      <p class="contract__chapter-text">
        1.4. Samuti võivad Pooled leppida kokku eritingimustes, mis moodustavad
        Üldtingimuste lahutamatu osa, kui Pooled on nendes kokku leppinud ja
        need allkirjastanud (edaspidi:
        <span class="contract__bold">Eritingimused</span>). Kui Eritingimused ja
        Üldtingimused on vastuolus, on ülimuslikud Eritingimused.
      </p>
      <p class="contract__chapter-text">
        1.5. Üldtingimustega nõustudes sõlmivad Touringery ja Partner omavahel
        lepingu, mille sisuks on Platvormiteenuste osutamine (edaspidi:
        <span class="contract__bold">Leping</span>). Kui Partner ei nõustu
        siinsete Üldtingimustega, ei ole tal lubatud kasutada Platvormi ega
        mistahes Platvormiteenuseid, mida Touringery osutab.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">2. KONTO JA LEPINGU SÕLMIMINE</div>
      <p class="contract__chapter-text">
        2.1. Selleks et kasutada Platvormiteenuseid ja saada juurdepääs
        Platvormile, peab Partner selleks konto registreerima (edaspidi:
        <span class="contract__bold">Konto</span>). Olenevalt sellest, kas
        Partner on juriidiline isik või füüsiline isik, peab ta looma kas
        juriidilise isiku Konto või füüsilise isiku Konto.
      </p>
      <p class="contract__chapter-text">
        2.2. Konto registreerimiseks peab Partner (või tema esindaja) sisestama
        nõutud andmed, muu hulgas Partneri nime, e-postiaadressi ja eelistatud
        salasõna.
      </p>
      <p class="contract__chapter-text">
        2.3. Pärast Konto registreerimist saadab Touringery e-kirjaga lingi,
        mille kaudu e-postiaadressi kinnitada.
      </p>
      <p class="contract__chapter-text">
        2.4. Lepingu sõlmimisel nõustub Partner siinsete Üldtingimustega ning
        avaldab ja kinnitab, et:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          2.4.1. ta on õiguslikult pädev Lepingut sõlmima;
        </p>
        <p class="contract__chapter-text">
          2.4.2. talle ei ole varem Platvormiteenuste osutamist peatatud ega
          lõpetatud;
        </p>
        <p class="contract__chapter-text">
          2.4.3. ta on ettevõtja Euroopa Liidu direktiivi 2005/29/EÜ ja kaupleja
          Euroopa Liidu direktiivi 2011/83/EL kohaselt;
        </p>
        <p class="contract__chapter-text">
          2.4.4. talle ei ole kehtestatud mistahes kaubandus- ega
          majandussanktsioone;
        </p>
        <p class="contract__chapter-text">
          2.4.5. tal ei ole praegu kehtivat Kontot;
        </p>
        <p class="contract__chapter-text">
          2.4.6. ta mõistab, et Platvorm on kommunikatsioonivahend ning
          Touringery ei osale Teenuste osutamises ega kaasnevas Lõppkasutaja
          ning Partneri vahelises õigussuhtes;
        </p>
        <p class="contract__chapter-text">
          2.4.7. ta on registreeritud Eestis või tema elukoht on Eestis;
        </p>
        <p class="contract__chapter-text">
          2.4.8. tema tegelike kasusaajate elukoht on Euroopa Liidu või Euroopa
          Majanduspiirkonna riigis.
        </p>
      </div>
      <p class="contract__chapter-text">
        2.5. Touringery’l on õigus otsustada, kellega Leping sõlmida, ja õigus
        sellest oma äranägemise järgi mistahes põhjusel või põhjuseta keelduda.
      </p>
      <p class="contract__chapter-text">
        2.6. Kui Konto on edukalt loodud, on Pooled sõlminud Lepingu, mille
        kohaselt Partneril on õigus kasutada Platvormi ja taotleda Touringery’lt
        Platvormiteenuste osutamist, kui need tehakse Platvormil kättesaadavaks.
        Kui Touringery seda nõuab, lepivad Partner ja Touringery enne Lepingu
        sõlmimist kokku ka Eritingimustes ja allkirjastavad need. Sellisel juhul
        loetakse Leping sõlmituks, kui Pooled on Eritingimused allkirjastanud.
      </p>
      <p class="contract__chapter-text">
        2.7. Partner kinnitab, et tema poolt Touringery’le esitatav teave on
        õige, täpne ja ajakohane.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">3. PLATVORMITEENUSTE OLEMUS</div>
      <p class="contract__chapter-text">
        3.1. Lõppkasutaja poolt Platvormilt Teenuse broneerimisel sõlmitakse
        vastava Teenuse osutamiseks leping, mille poolteks on Partner ning
        Lõppkasutaja.
      </p>
      <p class="contract__chapter-text">
        3.2. Platvorm on kõigest kommunikatsioonivahend Partneri ja Lõppkasutaja
        vahel ja Touringery ei osuta ise Teenuseid. Sellest tulenevalt ei
        vastuta Touringery Teenuste kvaliteedi ja puuduste eest ega taga seda,
        et Platvormi kaudu Teenuste osutamine toimub alati korrektselt ja
        puudusteta.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">4. TEENUSTE KORRALDAMINE</div>
      <p class="contract__chapter-text">
        4.1. Teenuste korraldamiseks peab Partner täpselt järgima Platvormil või
        muul viisil Touringery’lt saadud juhiseid. See tähendab, et sisestada
        tuleb kõik nõutud andmed, teha kõik nõutud toimingud jne.
      </p>
      <div>
        <i class="contract__chapter-offset-text">Uue Teenuse lisamine</i>
      </div>
      <p class="contract__chapter-text">
        4.2. Uue Teenuse lisamiseks peab Partner Platvormile sisestama Teenuse
        kohta nõutud andmed:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          4.2.1. uue Teenuse lisamiseks on kohustuslik täita Teenuse üldine info
          (nagu Teenuse nimi, kategooria, tüüp ja kirjeldus) ja Teenuse asukoha
          andmed (nagu Teenuse asukoht, kohalejõudmise juhised jms);
        </p>
        <p class="contract__chapter-text">
          4.2.2. kohustuslike andmete sisestamise järgselt on võimalik lisada
          Teenuse kohta muud andmed nagu hinnastus, eelistused, seotud
          ressursid, lisateenused ja -tooted ning Teenuse kategooriast sõltuvad
          andmed.
        </p>
      </div>
      <p class="contract__chapter-text">
        4.3. Iga Teenuse juurde tuleb lisada ka vastava Teenuse kohta kehtivad
        tingimused (nagu kasutustingimused ja privaatsustingimused).
      </p>
      <p class="contract__chapter-text">
        4.4. Platvormi tohib kasutada vaid selliste toodete ja teenuste
        pakkumiseks, millele ei kohaldu seaduses sätestatud tarbija 14-päevane
        taganemisõigus ning Partner kinnitab, et tema lisatud Teenustele ei
        kohaldu vastav 14-päevane taganemisõigus VÕS § 53 lg 4 mõttes (vt
        loetelu siit:
        <a href="https://www.riigiteataja.ee/akt/961235?leiaKehtiv"
          >https://www.riigiteataja.ee/akt/961235?leiaKehtiv</a
        >
        <span>).</span>
      </p>
      <div>
        <i class="contract__chapter-offset-text">Teenuse broneerimine</i>
      </div>
      <p class="contract__chapter-text">
        4.5. Teenuste osutamine Partneri poolt Platvormi vahendusel toimub
        Lõppkasutajate broneeringute alusel.
      </p>
      <p class="contract__chapter-text">
        4.6. Teenuse broneerimiseks siseneb Lõppkasutaja Platvormile
        iseseisevalt või Partneri veebilehel kättesaadavaks tehtud lingi kaudu.
        Lõppkasutaja saab Platvormi vahendusel teha Teenuse saamiseks
        broneeringu (edaspidi: <span class="contract__bold">Broneering</span>).
        Broneeringuid on võimalik teha Teenustele, mille osutamise alguskuupäev
        ei ole hiljem kui [12] kuud ega varem kui 2 tundi alates Broneeringu
        tegemisest. Samal kuupäeval toimuvale Teenuse on võimalik Broneering
        teha vaid Lõppkasutaja lisapäringuga ning tingimusel, et Partner on nõus
        Teenust samal kuupäeval osutama.
      </p>
      <p class="contract__chapter-text">
        4.7. Broneeringu tegemiseks Kasutaja:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          4.7.1. tutvub Teenuse sisu ja tingimustega;
        </p>
        <p class="contract__chapter-text">
          4.7.2. sisestab soovitud Teenuse nõutud andmed ja valikulised andmed
          (nagu lisateenused ja -tooted või erisoovid);
        </p>
        <p class="contract__chapter-text">
          4.7.3. vaatab ostukorvi üle, nõustub Lõppkasutaja üldtingimustega
          (kättesaadav siin: [link]; edaspidi:
          <span class="contract__bold">Lõppkasutaja Üldtingimused</span>) ja
          Teenuse tingimustega, mille Partner on Platvormi vahendusel
          Lõppkasutajale teatavaks teinud (vastavalt punktile 4.3) ja ning
          kinnitab Broneeringu.
        </p>
      </div>
      <p class="contract__chapter-text">
        4.8. Broneeringu kinnitamisel Lõppkasutaja poolt saab ta teha Teenuse
        eest makse (vastavalt punktile 4.20). Kui Lõppkasutaja on makse teinud,
        on Broneering automaatselt ja lõplikult kinnitatud.
      </p>
      <p class="contract__chapter-text">
        4.9. Pärast Broneeringu lõplikku kinnitamist (vastavalt punktile 4.8)
        tehakse Partnerile ja Lõppkasutajale Platvormi vahendusel teatavaks
        broneeritud Teenuse andmed nagu selle sisu, selle osutamise aeg ja koht
        ning Teenuse tellinud Lõppkasutaja andmed.
      </p>
      <p class="contract__chapter-text">
        4.10. Kui Broneering on kinnitatud (vastavalt punktile 4.8), kuid
        selgub, et Partneril ei ole võimalik Teenust vastaval kuupäeval pakkuda,
        siis on Partneril kohustus pakkuda Lõppkasutajale ilma täiendava tasuta
        paremat või samal tasemel Teenust samas piirkonnas või Teenust mõnel
        teisel, sobival kuupäeval. Partneri või Lõppkasutaja poolt Broneeringu
        muutmisele, tühistamisele ja raha tagastamisele kehtivad Partneri
        määratud tingimused, mis on Lõppkasutajale teatavaks tehtud (vastavalt
        punktile 4.11). Olukorras, kus Partner on määranud Teenuse toimumiseks
        kindlad eeldused (nagu näiteks osalejate miinimumarvu täitumine kindlaks
        kuupäevaks), ning need eeldused ei saabu, tagastab Touringery
        automaatselt Lõppkasutajale tema sooritatud makse Teenuse eest
        (vastavalt punktile 4.20) täies ulatuses.
      </p>
      <div>
        <i class="contract__chapter-offset-text"
          >Teenuse osutamise leping ja teenuse osutamine</i
        >
      </div>
      <p class="contract__chapter-text">
        4.11. Partneri ja Lõppkasutaja vahel sõlmitakse leping Partneri määratud
        ja avaldatud tingimustel ning arvestades käesolevas Lepingus sätestatud
        tingimusi (edaspidi: <span class="contract__bold">Teenuseleping</span>),
        kui Lõppkasutaja on Teenuselepingu tingimustega nõustunud (vastavalt
        puntkile 4.7.3) ja Broneering on kinnitatud (vastavalt punktile 4.8).
      </p>
      <p class="contract__chapter-text">
        4.12. Partneri ja Lõppkasutaja vahelist õigussuhet reguleerib
        Teenuseleping ning Teenuse osutamine toimub Teenuselepingu alusel.
      </p>
      <p class="contract__chapter-text">
        4.13. Pooled lepivad kokku, et Partner:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          4.13.1. osutab Teenuseid tingimustel, mille Partner on Platvormi
          vahendusel Lõppkasutajale teatavaks teinud (vastavalt punktile 4.3);
        </p>
        <p class="contract__chapter-text">
          4.13.2. osutab Teenuseid kooskõlas Touringery Lõppkasutaja
          Üldtingimustega, mida Touringery võib aeg-ajalt muuta;
        </p>
        <p class="contract__chapter-text">
          4.13.3. täidab Lõppkasutajate ees olevad kohustused, nagu on
          sätestatud Lõppkasutaja Üldtingimustes;
        </p>
        <p class="contract__chapter-text">
          4.13.4. ning tagab ja kinnitab, et ta osutab Teenuseid kooskõlas kõigi
          seadusest tulenevate nõuetega.
        </p>
      </div>
      <p class="contract__chapter-text">
        4.14. Touringery ei kontrolli tingimusi, mille Partner on Platvormi
        vahendusel Lõppkasutajale teatavaks teinud (vastavalt punktile 4.3) ning
        ta ei vastuta nende eest. See hõlmab ka Platvormil Partnerile Platvormi
        vahendusel kättesaadavaks tehtud abivahendeid näidistingimusi.
        Näidistingimused on mõeldud Partnerile vaid abivahendiks ning Touringery
        ei taga, et need tingimused on õiguspärased, ajakohased või sobivad
        konkreetse teenuse osutamiseks.
      </p>
      <p class="contract__chapter-text">
        4.15. Partneri kohustus on tagada, et tingimused vastavad Teenuse
        sisule, Partneri õigustahtele ja on õiguspärased.
      </p>
      <p class="contract__chapter-text">
        4.16. Pooled mõistavad, et Partneril on seadusest tulenev kohustus teha
        Lõppkasutajatele teatavaks teatud lepingueelne teave Teenuste kohta (nt
        taganemisõigus). Arvestades asjaolu, et Platvormi käitaja on Touringery,
        nõustuvad Pooled, et Touringery kohustus on kuvada Lõppkasutajatele
        Lõppkasutaja Üldtingimusi ning teha Partnerile võimalikuks teha
        Lõppkasutajatele teatavaks täiendavat teavet (sh Teenuselepingu
        tingimusi) Platvormi kaudu. Touringery’l on õigus määrata Platvormi
        kaudu edastatava teabe vorm.
      </p>
      <div>
        <i class="contract__chapter-offset-text"
          >Lisateenuste ja -toodete lisamine</i
        >
      </div>
      <p class="contract__chapter-text">
        4.17. Partner saab Platvormile lisada lisateenuseid ja -tooteid, mis on
        seotud Teenustega, mida Partner Lõppkasutajatele osutab.
      </p>
      <p class="contract__chapter-text">
        4.18. Lisateenuse või -toote lisamiseks peab Partner sisestama nõutud
        andmed, muu hulgas lisateenuse- või toote nimi, kirjeldus ja hind.
        Partner kohustub tagama, et esitatud teave on õige ja Platvormi kaudu
        Lõppkasutajatele kuvatavad andmed vastavad seaduse nõuetele.
      </p>
      <p class="contract__chapter-text">
        4.19. Lisateenused ja -tooted on olemuslikult vastava Teenuse osa ja
        nende osutamine (sh toodete pakkumine) toimub sama Teenuselepingu
        alusel, mille Partner ja Lõppkasutaja on sõlminud vastava Teenuse
        osutamiseks.
      </p>
      <div>
        <i class="contract__chapter-offset-text">Teenustasu</i>
      </div>
      <p class="contract__chapter-text">
        4.20. Lõppkasutaja maksab Partnerile Teenuse eest tasu (edaspidi:
        <span class="contract__bold">Teenustasu</span>) vastavalt
        Teenuselepingule.
      </p>
      <p class="contract__chapter-text">
        4.21. Partneril on õigus määrata Teenustasu suurus tingimusel, et ta
        suudab tasuda Teenustasu arvelt Touringery’le tasu Platvormiteenuse eest
        (defineeritud punktis 5.1; edaspidi:
        <span class="contract__bold">Touringery Tasu</span>).
      </p>
      <p class="contract__chapter-text">
        4.22. Kuivõrd Lõppkasutaja peab maksma Broneeringut tehes kogu
        Teenustasu, kuvatakse Lõppkasutajale Platvormil Teenuse hinda vaid
        Teenustasuna. Lõppkasutajale ei kuvata eraldi Touringery Tasu.
      </p>
      <p class="contract__chapter-text">
        4.23. Partner peab Teenustasust ise kinni kõik seadusega ette nähtud
        kohustuslikud maksud ja maksed.
      </p>
      <div>
        <i class="contract__chapter-offset-text"
          >Teenustasu maksmine ja vahendamine</i
        >
      </div>
      <p class="contract__chapter-text">
        4.24. Maksete vahendamiseks kasutab Touringery makseteenuse osutajat
        (edaspidi: <span class="contract__bold">Maksevahendaja</span>). Partner
        annab siinkohal Touringery’le volituse volitada Maksevahendajat võtma
        Lõppkasutajatelt Partneri nimel vastu makseid Teenuste eest, nagu on
        allpool üksikasjalikumalt kirjeldatud.
      </p>
      <p class="contract__chapter-text">
        4.25. Teenuseid saab Platvormi kaudu osta ja vastavaid makseid teha
        alljärgnevalt:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          4.25.1. Lõppkasutaja teeb Teenuse saamiseks Broneeringu. Broneeringu
          kinnitamiseks (vastavalt punktile 4.8) teeb Lõppkasutaja Teenuse eest
          Teenuselepingu kohaselt Maksevahendajale makse, milleks on Teenustasu.
        </p>
        <p class="contract__chapter-text">
          4.25.2. Lõppkasutaja Teenuselepingust tulenev kohustus tasuda
          Teenustasu loetakse täidetuks hetkest, kui Lõppkasutaja teeb edukalt
          Maksevahendajale makse. Kui Partner on selleks seaduse järgi
          kohustatud või kui Lõppkasutaja avaldab selleks soovi, väljastab
          Partner talle arve Teenuse kohta. Olukorras, kus Partner on
          Touringery’ga sõlminud vastava kirjaliku kokkuleppe, väljastab arve
          Partneri nimel Touringery.
        </p>
        <p class="contract__chapter-text">
          4.25.3. Maksevahendaja edastab Partnerile Lõppkasutajalt saadud
          Teenustasu, millelt on maha arveldatud Touringery Tasu.
        </p>
        <p class="contract__chapter-text">
          4.25.4. Maksevahendaja teeb Partnerile punktis 4.25.3 nimetatud
          ülekande, kui Maksevahendaja on saanud Touringery’lt ülekande
          tegemiseks kinnituse. Touringery annab Maksevahendajale vastava
          kinnituse vastavalt Partneri valitud eelistusele ning tingimusel, et
          Lõppkasutajal ei ole seoses Teenusega ühtegi kaebust, mis on
          Touringery’le teatavaks saanud.
        </p>
        <p class="contract__chapter-text">
          4.25.5. Kui Maksevahendaja on Touringery’lt punktis 4.25.4 nimetatud
          kinnituse saanud, teeb ta Partnerile ülekande alljärgnevalt:
        </p>
        <div class="contract__sub-sub-chapter">
          <p class="contract__chapter-text">
            i. pangamaksega laekunud Teenustasu reeglina ühe (1) tööpäeva
            jooksul ja mitte hiljem kui seitsme (7) päeva jooksul;
          </p>
          <p class="contract__chapter-text">
            ii. krediitkaardimaksetega laekunud Teenustasu viie (5) tööpäeva
            jooksul.
          </p>
        </div>
        <div>
          <i class="contract__chapter-offset-text">Lõppkasutajate nõuded</i>
        </div>
      </div>
      <p class="contract__chapter-text">
        4.26. Partner vastutab ainuisikuliselt Teenuse (ja lisateenuste ning
        -toodete) kvaliteediga seotud puuduste ning muude puudujääkide eest
        Teenuslepingu täitmisel. Touringery ei vastuta nõuete eest, mis
        tulenevad Teenuselepingust.
      </p>
      <p class="contract__chapter-text">
        4.27. Lõppkasutaja võib kasutada õiguskaitsevahendeid, nagu on ette
        nähtud Teenuselepingus ja seaduses. Sealhulgas võib Lõppkasutaja
        Teenuselepingus ja seaduses sätestatud juhtudel taganeda
        Teenuselepingust või esitada nõude seoses Teenuselepingu mittekohase
        täitmisega.
      </p>
      <p class="contract__chapter-text">
        4.28. Partner lahendab Teenuselepinguga seotud nõudeid ja kaebusi või
        Lõppkasutaja muid taotlusi ilma Touringery’it kaasamata. Touringery ei
        vastuta selliste nõuete ega kaebuste eest, kuid võib omal äranägemisel:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          4.28.1. pakkuda Lõppkasutajale klienditoe teenuseid;
        </p>
        <p class="contract__chapter-text">
          4.28.2. tegutseda vajadusel Partneri ja Lõppkasutaja vahendajana, sh
          tagastada Partneri nimel Maksevahendaja kaudu Lõppkasutajale
          Teenustasu või muu summa.
        </p>
      </div>
      <p class="contract__chapter-text">
        4.29. Kui Lõppkasutaja esitab Teenuselepingust tuleneva nõude või
        kaebuse otse Touringery’le, on Touringery volitatud vastavat nõuet või
        kaebust Partneri nimel lahendama nii, nagu ta peab mõistlikuks. See
        tähendab ka seda, et Touringery võib Partneri nimel mõistlikult
        tegutsedes ja oma äranägemise järgi teha järgmist:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          4.29.1. Edastada Lõppkasutaja nõue või kaebus Partnerile;
        </p>
        <p class="contract__chapter-text">
          4.29.2. kui Partnerilt ei saabu 3 tööpäeva jooksul tagasiside,
          rahuldada Lõppkasutaja nõue (nt tagastada Lõppkasutajale Teenustasu
          või maksta talle hüvitis ulatuses, mida Touringery peab mõistlikuks).
        </p>
      </div>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">5. TOURINGERY TASU</div>
      <p class="contract__chapter-text">
        5.1. Partner maksab Touringery’le Platvormiteenuste osutamise eest
        Touringery Tasu. Touringery Tasule lisatakse käibemaks, kui see on
        seaduse järgi nõutav.
      </p>
      <p class="contract__chapter-text">
        5.2. Touringery Tasu maksmise kohustus ja suurus sõltub Partneri poolt
        Platvormi vahendusel osutatud Teenuste käibest. Touringery Tasu on 5%
        Teenustasust.
      </p>
      <p class="contract__chapter-text">
        5.3. Touringery Tasu arvutatakse automaatselt Teenustasu laekumisel
        Maksevahendajale ning see muutub koheselt sissenõutavaks.
      </p>
      <p class="contract__chapter-text">
        5.4. Partneri kohustus maksta Touringery Tasu ei sõltu ühestki
        Lõppkasutaja võimalikust nõudest Partneri vastu. Seejuures kohustub
        Partner maksma Touringery Tasu ka siis, kui Touringery tagastab
        Lõppkasutajale Teenustasu täies ulatuses (sealhulgas punktis 4.10
        kirjeldatud olukord).
      </p>
      <p class="contract__chapter-text">
        5.5. Pooled võivad Eritingimustes kokku leppida teistsugustes Touringery
        Tasu tingimustes. Touringery Tasu tingimused on konfidentsiaalne teave
        ja kumbki Pool ei tohi neid kolmandatele isikutele avaldada.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">6. MAKSETINGIMUSED</div>
      <p class="contract__chapter-text">
        6.1. Pooled lepivad seoses arvete esitamise ja Lepingust tulenevate
        maksetega kokku alljärgnevas:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          6.1.1. kõik Touringery ja Partneri vahelised maksed tehakse
          võimalikult suures ulatuses tasaarvestuse teel;
        </p>
        <p class="contract__chapter-text">
          6.1.2. Touringery esitab kord kuus Partnerile arve eelmisel kuul
          osutatud Touringery Tasu kohta;
        </p>
        <p class="contract__chapter-text">
          6.1.3. Maksevahendaja kannab Lõppkasutajatelt saadud Teenustasu
          Partneri pangakontole nii, et Teenustasu on tasaarvestatud Touringery
          Tasu summaga, mis on muutunud sissenõutavaks Üldtingimuste punkti 5.3
          kohaselt või mistahes muu summaga, mille Partner peab maksma
          Touringery’le.
        </p>
        <p class="contract__chapter-text">
          olukorras, kus tasaarvestus pole võimalik (näiteks kui Lõppkasutajale
          on tagastatud Teenustasu täies ulatuses), täidab Partner oma rahalised
          kohustused Touringery ees talle esitatud arvete alusel.
        </p>
      </div>
      <p class="contract__chapter-text">
        6.2. Pooled võivad leppida Eritingimustes kokku teistsugustes
        maksetingimustes.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">
        7. ÜLDISED ÕIGUSED JA KOHUSTUSED
      </div>
      <p class="contract__chapter-text">
        7.1. Partner vastutab ainuisikuliselt kogu oma tegevuse eest Platvormil.
        Partner teavitab Touringery’t viivitamatult, kui ta saab teada Platvormi
        volitamata kasutamisest.
      </p>
      <p class="contract__chapter-text">
        7.2. Partner on kohustatud kasutama Platvormiteenuseid ja Platvormi
        kooskõlas Lepingu, õigusaktide ja hea tavaga. Kõik Partneriga seotud
        maksukohustused täidab Partner.
      </p>
      <p class="contract__chapter-text">
        7.3. Partner nõustub hoiduma Platvormil sellise sisu avaldamisest ja
        selliste toimingute tegemisest (muu hulgas Teenuste pakkumisest), mis on
        vastuolus Lepingu, Platvormi olemuse ja eesmärgi või hea tavaga,
        ebaseaduslik, Touringery äritegevust või mainet kahjustav,
        pornograafiline, diskrimineeriv, rassistlik, ebamoraalne, solvav,
        kahjulik, ebainimlik või muul viisil olemuselt ebasünnis, on seotud
        seadusega keelatud esemete või teenuste müügi või ostuga või kujutab
        endast eksitavat reklaami või ebaausat konkurentsi. Touringery võib oma
        äranägemise järgi otsustada, millist sisu peetakse Platvormi jaoks
        ebasobivaks, ja tal on õigus selline sisu Platvormilt eemaldada.
        Touringery ei vastuta kahju ega tagajärgede eest, mis tulenevad sellest,
        kui Touringery kasutab käesolevas punktis nimetatud õigust.
      </p>
      <p class="contract__chapter-text">
        7.4. Arvestades punktis 7.3 sätestatut, on Partneril eelkõige keelatud
        pakkuda ja osutada Platvormi vahendusel järgnevaid teenuseid ja tooteid:
        alkoholi- ja tubakatooted, tulirelvad, külmrelvad, pornograafilise
        sisuga tooted, e-sigaretid ja e-sigaretitooted, CBD tooted.
      </p>
      <p class="contract__chapter-text">
        7.5. Partner nõustub, et ta ei litsentsi, loo tuletatud teoseid,
        võõranda, müü ega müü edasi mis tahes teavet, sisu, materjale, andmeid
        ega teenuseid, mis on saadud Platvormilt. Samuti nõustub Partner mitte
        kasutama Platvormi viisil, milleks see ei ole ette nähtud, nagu
        Touringery on oma äranägemise järgi kindlaks määranud. See tähendab ka
        seda, et keelatud on Platvormi pöördkonstrueerimine, dekompileerimine ja
        lahti võtmine ning Platvormilt saadud teabe kogumine, kasutamine,
        kopeerimine ja edastamine kolmandatele isikutele Touringery nõusolekuta.
      </p>
      <p class="contract__chapter-text">
        7.6. Touringery’l on õigus keelduda Partnerile Platvormiteenuste
        osutamisest. Muu hulgas on Touringery’l õigus lõpetada või piirata igal
        ajal Partneri õigust Platvormiteenuste kasutamiseks, seal hulgas sulgeda
        Partneri Konto, kui Partner on mistahes viisil rikkunud Lepingut või
        kohaldatavat õigust või kui Touringery uurib sellist rikkumist
        põhjendatud kahtluse alusel või kui Touringery peab seda muul põhjusel
        vajalikuks. Touringery ei vastuta kahju ega tagajärgede eest, mis
        tulenevad sellest, kui Touringery kasutab käesolevas punktis nimetatud
        õigust.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">8. INTELLEKTUAALNE OMAND</div>
      <p class="contract__chapter-text">
        8.1. Intellektuaalse omandi õigused (muu hulgas tarkvaraga seotud
        õigused) seoses Platvormiteenuste (muu hulgas Platvormiga) ning andmete,
        meetodite ja protsessidega, mille alusel Platvormiteenuseid osutatakse,
        kuuluvad üksnes Touringery’le.
      </p>
      <p class="contract__chapter-text">
        8.2. Touringery annab siinkohal Partnerile lihtlitsentsi Platvormi
        kasutamiseks ulatuses, mis on vajalik Platvormiteenuste kasutamiseks.
        Litsents on piiratud Lepingu kehtivusajaga. Litsents ei ole piiratud
        territooriumiga ja see antakse all-litsentseerimisõiguseta.
        Litsentsitasu sisaldub Platvormiteenustasus.
      </p>
      <p class="contract__chapter-text">
        8.3. Üks Pool (edaspidi siinses punktis:
        <span class="contract__bold">Omanik</span>) annab teisele Poolele õiguse
        kasutada Omaniku kaubamärki, Omaniku nime ja graafilist logo (edaspidi:
        <span class="contract__bold">Kaubamärk</span>) teise Poole veebisaidil,
        müügikohas ja turundusmaterjalides, et viidata olenevalt asjaoludest
        sellele, et Omanik on Platvormiteenuste kasutaja või pakkuja. Kaubamärgi
        kasutamisel ei ole vaja Omaniku eelnevat heakskiitu, kuid Kaubamärki
        kasutav Pool peab järgima Omaniku kindlaksmääratud korda ja suuniseid
        ning hoiduma mistahes tegevusest, mis võib Kaubamärgi väärtust vähendada
        või Omaniku mainet kahjustada. Kaubamärgi kasutamiseks antav litsents on
        lihtlitsents, mis ei ole piiratud territooriumiga ja mis on
        all-litsentseeritav (näiteks võib Pool Kaubamärgi kasutamiseks anda
        all-litsentsi turundusettevõttele). Litsents on piiratud Lepingu
        kehtivusajaga. Partneri kui Omaniku jaoks on tasu litsentsi eest õigus
        saada Lepingu kohaselt kokkulepitud Platvormiteenuseid. Touringery kui
        Omaniku jaoks on tasu litsentsi eest hõlmatud Turingery Tasuga.
      </p>
      <p class="contract__chapter-text">
        8.4. Platvormile andmete või teoste sisestamisega annab Partner
        Touringery’le lihtlitsentsi seoses varaliste ja isiklike õigustega
        kasutada neid andmeid ja teoseid, mis on kaitstud intellektuaalomandi
        õigusega, juhul, kui see on vajalik Platvormi toimimiseks,
        Platvormiteenuste osutamiseks ning Platvormi või Platvormiteenuste
        arendamiseks. Litsents on piiratud Lepingu kehtivusajaga. Litsents ei
        ole piiratud territooriumiga ja see antakse all-litsentseerimisõigusega.
        Partneri jaoks on tasu litsentsi eest õigus saada Lepingu kohaselt
        kokkulepitud Platvormiteenuseid.
      </p>
      <p class="contract__chapter-text">
        8.5. Partner kinnitab, et tal on õigus anda punktis 8 nimetatud
        litsentsid Lepingus sätestatu kohaselt ja sellega ei rikuta kolmandate
        isikute õigusi.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">9. VASTUTUS</div>
      <p class="contract__chapter-text">
        9.1. Kui Lepingus ei ole teisiti sätestatud, vastutavad Pooled
        teineteise ees kahju eest, mis on tekitatud teisele Poolele Lepingu
        rikkumisega.
      </p>
      <p class="contract__chapter-text">
        9.2. Touringery vastutab Partneri ees vaid siis, kui ta on Lepingut
        rikkunud tahtlikult või raskest hooletusest. Touringery ei vastuta
        ühelgi juhul mittevaralise kahju, saamata jäänud tulu ega lepinguvälise
        kahju (muu hulgas puhtmajandusliku kahju) eest. Touringery vastutus on
        igal juhul piiratud summaga, mille Partner maksis Turingery Tasuna 6 kuu
        jooksul, mis eelnesid asjaolule, millest väidetav vastutus tuleneb.
      </p>
      <p class="contract__chapter-text">
        9.3. Touringery ei taga nende andmete täpsust ega terviklikkust, mille
        Partner on Platvormile sisestanud, ega vastuta selliste andmetega seotud
        tagajärgede eest.
      </p>
      <p class="contract__chapter-text">
        9.4. Touringery ei vastuta Lõppkasutaja käitumise eest, sh Lõppkasutaja
        poolt Teenuselepingu rikkumise või mistahes Parterile kahju tekitava
        käitumise eest.
      </p>
      <p class="contract__chapter-text">
        9.5. Platvormi ja Platvormiteenuseid pakutakse olemasoleval kujul ja
        kättesaadavuse alusel ilma igasuguse garantiita. Muu hulgas ei taga
        Touringery, et Platvormiteenuseid saab kasutada katkestusteta või
        veatult, ega anna mistahes garantiid seoses Platvormiteenuste
        töövõimeaja või tulemustega, mida võib Platvormiteenuste kasutamisel
        saavutada.
      </p>
      <p class="contract__chapter-text">
        9.6. Kui Partneril tekib Touringery ees makseviivitusi, peab ta tasuma
        viivist 0,5% maksmata summalt kalendripäeva kohta.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">10. MUUDATUSED</div>
      <p class="contract__chapter-text">
        10.1. Touringery’l on õigus siinseid Üldtingimusi oma äranägemise järgi
        ühepoolselt muuta, muu hulgas juhul, kui muudatused on vajalikud
        Platvormi tehnilise või sisulise arendamise tõttu.
      </p>
      <p class="contract__chapter-text">
        10.2. Muudatuste tegemise korral teavitatakse Partnerit e-kirja teel
        vähemalt 15 päeva enne nende jõustumist. Pärast 15 päeva möödumist
        eeldatakse, et Partner nõustus muudetud Üldtingimustega, kui ta ei ole
        selle aja jooksul esitanud teadet Lepingu ülesütlemise kohta.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">11. UUENDUSED</div>
      <p class="contract__chapter-text">
        11.1. Touringery võib Platvormi ja Platvormiteenuseid oma äranägemise
        järgi automaatselt uuendada, muu hulgas juhul, kui ta soovib parandada
        tulemuslikkust, täiustada funktsioone, kajastada operatsioonisüsteemi
        muudatusi, kõrvaldada turvaprobleeme, teha hooldustöid või ennetada
        andmelekkeid.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">12. ISIKUANDMETE TÖÖTLEMINE</div>
      <p class="contract__chapter-text">
        12.1. Olukorras, kus Touringery töötleb füüsiliste isikute isikuandmeid
        (edaspidi: <span class="contract__bold">isikuandmed</span>) seoses
        Lepingu täitmisega, peetakse Partnerit vastutavaks töötlejaks ja
        Touringery’t volitatud töötlejaks Euroopa Parlamendi ja nõukogu määruse
        (EL) 2016/679 tähenduses (edaspidi:
        <span class="contract__bold">GDPR</span>), kui Touringery
        privaatsuspoliitikas ei ole teisiti määratud. Isikuandmete töötlemise
        põhimõtted on määratud kindlaks punktis 12 esitatud isikuandmete
        töötlemise lepingus (edaspidi: <span class="contract__bold">ITL</span>).
      </p>
      <p class="contract__chapter-text">
        12.2. Touringery nõustub alljärgnevaga ja tagab, et:
      </p>
      <div class="contract__sub-chapter">
        <p class="contract__chapter-text">
          12.2.1. Touringery töötleb isikuandmeid üksnes Partneri nimel ning
          kooskõlas Partneri antud ja korras esitatud juhistega, muu hulgas
          seoses isikuandmete edastamisega kolmandasse riiki või
          rahvusvahelisele organisatsioonile, välja arvatud juhul, kui see on
          nõutud seadusega, mida kohaldatakse Touringery’le. Sellisel juhul
          teavitab Touringery Partnerit sellest õigusnõudest enne andmete
          töötlemist, kui selline teavitamine ei ole olulise avaliku huvi tõttu
          kõnealuse õigusega keelatud;
        </p>
        <p class="contract__chapter-text">
          12.2.2. ei esine asjaolusid, mis takistavad Touringery’t Partneri
          antud juhiste järgimisel või korrast tulenevate kohustuste täitmisel
          või välistavad selle;
        </p>
        <p class="contract__chapter-text">
          12.2.3. isikuandmeid töötlema volitatud isikud on kohustunud järgima
          konfidentsiaalsusnõuet;
        </p>
        <p class="contract__chapter-text">
          12.2.4. ta võtab kõik GDPR artikli 32 kohaselt nõutud meetmed ning on
          rakendanud tehnilisi ja korralduslikke kaitsemeetmeid, et kaitsta
          isikuandmeid juhusliku või õigusvastase hävitamise, kaotsimineku,
          muutmise, volitamata avaldamise ja juurdepääsu eest, eelkõige juhul,
          kui töötlemine hõlmab andmete edastamist võrgu kaudu, ning mis tahes
          muu ebaseadusliku töötlemise eest;
        </p>
        <p class="contract__chapter-text">
          12.2.5. võttes arvesse isikuandmete töötlemise laadi, aitab ta
          võimaluse piires Partnerit asjakohaste tehniliste ja korralduslike
          meetmete abil, kui Partner vastab taotlustele seoses andmesubjekti
          GDPR III peatükis kindlaks määratud õiguste kasutamisega;
        </p>
        <p class="contract__chapter-text">
          12.2.6. ta aitab Partneril tagada vastavuse GDPR artiklitele 32–36,
          võttes arvesse isikuandmete töötlemise laadi ja talle kättesaadavat
          teavet;
        </p>
        <p class="contract__chapter-text">
          12.2.7. ta teeb taotluse korral kättesaadavaks teabe, mis on vajalik,
          et tõendada GDPR artiklis 28 kindlaks määratud kohustuste järgimist,
          ning võimaldab isikuandmete töötlemise vahendeid, korda ja asukohta
          puudutavat auditit, mille viib läbi Poolte vahel kokku lepitud
          kontrolliorgan (vajaduse korral kooskõlastatakse valik
          järelevalveasutusega), mis koosneb sõltumatutest liikmetest, kellel on
          vajalikud kutsealased oskused ja kes on kohustatud järgima
          konfidentsiaalsusnõuet. Mis tahes auditis ja selle tingimustes, muu
          hulgas ajastuses ja vahendites, lepivad Pooled eelnevalt kokku.
          Partner katab auditiga seotud kulu. Touringery teavitab Partnerit
          viivitamatult, kui auditi tegemiseks antud korraldusega rikutakse tema
          arvates seadust;
        </p>
        <p class="contract__chapter-text">
          12.2.8. Touringery käsitleb nõuetekohaselt ja mõistliku aja jooksul
          kõiki Partneri taotlusi, mis on seotud isikuandmete töötlemisega, ja
          järgib isikuandmete töötlemisel järelevalveasutuse kõiki juhiseid.
        </p>
      </div>
      <p class="contract__chapter-text">
        12.3. Partner võib edastada Touringery’le järgmist tüüpi isikuandmed:
        nime, kontaktandmed, makseandmed ja asjaolu, et andmesubjekt on
        Partneriga seotud.
      </p>
      <p class="contract__chapter-text">
        12.4. Touringery säilitab isikuandmeid kuni lepingu lõpetamiseni.
        Seejärel kustutab Touringery kõik isikuandmed ja olemasolevad koopiad
        (või muudab need anonüümseks), välja arvatud juhul, kui isikuandmete
        säilitamine on seadusega nõutud.
      </p>
      <p class="contract__chapter-text">
        12.5. Isikuandmete töötlemise eesmärk on Lepingu täitmine.
      </p>
      <p class="contract__chapter-text">
        12.6. Isikuandmete töötlemine hõlmab oma olemuselt mis tahes toimingut
        või toimingute kogumit, mida tuleb teha seoses isikuandmete või nende
        kogumiga, et töödelda isikuandmeid ITL-i kohaselt.
      </p>
      <p class="contract__chapter-text">
        12.7. Partner annab siinkohal Touringery’le volituse kaasata
        alltöötlejaid, kes on loetletud Touringery privaatsuspoliitikas
        veebisaidil [link], ja edastada neile isikuandmeid.
      </p>
      <p class="contract__chapter-text">
        12.8. Samuti annab Partner siinkohal Touringery’le üldise volituse uute
        alltöötlejate kaasamiseks oma äranägemise järgi. Kui Touringery soovib
        kaasata uut alltöötlejat, teavitab ta Partnerit. Partneril on õigus
        esitada kahe nädala jooksul alates teate saamisest vastuväide. Juhul kui
        vastuväide esitatakse, peab Partner lõpetama isikuandmete esitamise
        Touringery’le ja Lepingu täitmine peatatakse. Sellisel juhul alustavad
        Pooled läbirääkimisi uue alltöötleja vastuvõtmiseks. Kui kokkuleppele ei
        jõuta, on mõlemal Poolel õigus Leping 90-päevase etteteatamisega
        erakorraliselt üles öelda.
      </p>
      <p class="contract__chapter-text">
        12.9. Touringery sõlmib alltöötlejatega lepingud, mis annavad piisava
        tagatise, et rakendatakse asjakohaseid tehnilisi ja korralduslikke
        meetmeid sellisel viisil, et töötlemine vastaks seaduse nõuetele.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">
        13. LEPINGU TÄHTAEG JA ÜLESÜTLEMINE
      </div>
      <p class="contract__chapter-text">
        13.1. Leping on sõlmitud määramata tähtajaks.
      </p>
      <p class="contract__chapter-text">
        13.2. Pool võib Lepingu igal ajal üles öelda, teatades teisele Poolele
        vähemalt 30 päeva ette.
      </p>
      <p class="contract__chapter-text">
        13.3. Touringery’l on õigus leping viivitamatult ühepoolselt üles öelda
        juhul, kui Partner rikub lepingut.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">
        14. KOHALDATAV ÕIGUS JA VAIDLUSTE LAHENDAMINE
      </div>
      <p class="contract__chapter-text">
        14.1. Lepingule ning sellest tulenevatele Partneri ja Touringery
        vahelistele õigussuhetele kohaldatakse Eesti Vabariigi õigust.
      </p>
      <p class="contract__chapter-text">
        14.2. Pooled püüavad kõik Lepingust tulenevad ja sellega seotud
        vaidlused lahendada läbirääkimiste teel. Touringery’le saab kaebusi
        esitada e-posti aadressil support@touringery.ee. Touringery menetleb
        kaebusi mõistliku aja jooksul. Kui probleem jääb läbirääkimiste teel
        lahendamata, on kõigi Lepingust tulenevate ja sellega seotud vaidluste
        lahendamisel esimese astme kohtuna ainupädev Harju Maakohus.
      </p>
    </div>
    <div class="contract__chapter">
      <div class="contract__chapter-title">15. MUUD SÄTTED</div>
      <p class="contract__chapter-text">
        15.1. <span class="contract__bold">Kahju hüvitamine.</span> Partner
        nõustub hüvitama Touringery’le, tema juhatuse liikmetele,
        konsultantidele, töötajatele ja muudele partneritele kahju ning võtma
        nende eest kogu vastutuse mistahes hagi, kohustuse, kulu, nõude,
        kaotuse, kahju või menetluse eest, mis tuleneb otseselt või kaudselt
        sellest, et Partner rikkus Lepingut või kohaldatavat õigust.
      </p>
      <p class="contract__chapter-text">
        15.2. <span class="contract__bold">Lepingusätete lahusus.</span> Kui
        Lepingu mõni säte või selle osa osutub kehtetuks, ebaseaduslikuks,
        muudetakse seda vähimal vajalikul määral, et tunnistada see kehtivaks ja
        seaduslikuks. Kui muutmine ei ole võimalik, loetakse vastav säte või
        selle osa kustutatuks. Sätte või selle osa muutmine või kustutamine
        selle punkti alusel ei mõjuta ülejäänud Lepingu kehtivust ega kohustuse
        sissenõutavust.
      </p>
      <p class="contract__chapter-text">
        15.3.
        <span class="contract__bold">Lepingu loovutamine.</span> Touringery võib
        loovutada oma Lepingust tulenevad õigused ja kohustused teisele isikule.
        Touringery teavitab sellisel juhul Partnerit ja tagab, et loovutamine ei
        mõjuta Partneri Lepingust tulenevaid õigusi.
      </p>
      <p class="contract__chapter-text">
        15.4. <span class="contract__bold">Keel.</span> Lepingu tingimused
        võidakse teha kättesaadavaks mitmes keeles. Lepingu versioonide
        erinevuste korral on ülimuslik Lepingu eestikeelne versioon.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import '~/src/assets/scss/typography.scss';

.contract {
  &__title {
    font-size: $sp-24;
    font-weight: 700;

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: $sp-16;
      margin-bottom: $sp-24;
    }
  }

  &__bold {
    font-weight: 700;
  }

  &__chapter {
    display: flex;
    flex-direction: column;
    gap: $sp-8;
    margin-bottom: $sp-16;

    &-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: $sp-8;
    }

    &-text {
      text-align: justify;
    }

    &-offset-text {
      font-weight: 700;
    }
  }

  &__sub-chapter {
    display: flex;
    flex-direction: column;
    gap: $sp-8;
    margin-left: $sp-24;
  }

  &__sub-sub-chapter {
    display: flex;
    flex-direction: column;
    gap: $sp-8;
    margin-left: $sp-48;
  }
}
</style>
