import i18n from '@/i18n'
import {
  DepositReport,
  DepositReportTypeData,
} from '@/models/reports/depositReport'
import { GenericReportSettings } from '@/models/reports/genericReportComponent.model'
import { formatCurrencyDecimal } from '@/utils/formatters'

const { t } = i18n.global

export const getTabTranslation = () => t('report.tabs.depositReport')

// While string labels are supported, outside of components we need to use functions to keep them reactive.
export const genericReportSettings: GenericReportSettings = {
  showSectionFooter: true,
  showTableFooter: true,
  columns: [
    {
      // No such key in , but the data exists on section level.
      key: 'type',
      header: {
        label: () => t('type'),
      },
      cell: {
        parseValue: ({ section }) => {
          return (section?.typeData as DepositReportTypeData)?.type_label
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeLabel = (section?.typeData as DepositReportTypeData)
            ?.type_label as string

          return t('dynamicNameTotal', { name: typeLabel })
        },
      },
      tableFooter: {
        parseValue: () => t('total.total'),
        contentClass: 'uppercase',
      },
    },
    {
      key: 'name',
      header: {
        label: () => t('service'),
      },
    },
    {
      key: 'client',
      header: {
        label: () => t('report.sales.header.clientsName'),
      },
    },
    {
      key: 'price_line',
      header: {
        label: () => t('report.sales.header.priceLine'),
      },
    },
    {
      key: 'paid_at',
      header: {
        label: () => t('report.deposit.header.paidAt'),
      },
    },
    {
      key: 'refunded_at',
      header: {
        label: () => t('report.deposit.header.refundedAt'),
      },
    },
    {
      key: 'payout_at',
      header: {
        label: () => t('report.deposit.header.payoutAt'),
      },
    },
    {
      key: 'deposits_before_period',
      header: {
        label: () => t('report.deposit.header.depositsBeforePeriod'),
      },
      cell: {
        parseValue: ({ value }) => {
          if (Number(value)) {
            return formatCurrencyDecimal(value as string, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeData = section?.typeData as DepositReportTypeData

          if (Number(typeData?.deposits_before_period)) {
            return formatCurrencyDecimal(typeData?.deposits_before_period, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const depositReport = data?.depositReport as DepositReport

          if (Number(depositReport?.deposits_before_period)) {
            return formatCurrencyDecimal(
              depositReport?.deposits_before_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
    },
    {
      key: 'deposits_during_period',
      header: {
        label: () => t('report.deposit.header.depositsDuringPeriod'),
      },
      cell: {
        parseValue: ({ value }) => {
          if (Number(value)) {
            return formatCurrencyDecimal(value as string, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeData = section?.typeData as DepositReportTypeData

          if (Number(typeData?.deposits_during_period)) {
            return formatCurrencyDecimal(typeData?.deposits_during_period, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const depositReport = data?.depositReport as DepositReport

          if (Number(depositReport?.deposits_during_period)) {
            return formatCurrencyDecimal(
              depositReport?.deposits_during_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
    },
    {
      key: 'refunded_during_period',
      header: {
        label: () => t('report.deposit.header.refundedDuringPeriod'),
      },
      cell: {
        parseValue: ({ value }) => {
          if (Number(value)) {
            return formatCurrencyDecimal(value as string, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeData = section?.typeData as DepositReportTypeData

          if (Number(typeData?.refunded_during_period)) {
            return formatCurrencyDecimal(typeData?.refunded_during_period, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const depositReport = data?.depositReport as DepositReport

          if (Number(depositReport?.refunded_during_period)) {
            return formatCurrencyDecimal(
              depositReport?.refunded_during_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
    },
    {
      key: 'paid_out_provider_during_period',
      header: {
        label: () => t('report.deposit.header.serviceProviderPayouts'),
      },
      cell: {
        parseValue: ({ value }) => {
          if (Number(value)) {
            return formatCurrencyDecimal(value as string, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeData = section?.typeData as DepositReportTypeData

          if (Number(typeData?.paid_out_provider_during_period)) {
            return formatCurrencyDecimal(
              typeData?.paid_out_provider_during_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const depositReport = data?.depositReport as DepositReport

          if (Number(depositReport?.paid_out_provider_during_period)) {
            return formatCurrencyDecimal(
              depositReport?.paid_out_provider_during_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
    },
    {
      key: 'paid_out_others_during_period',
      header: {
        label: () => t('report.deposit.header.partnersPayouts'),
      },
      cell: {
        parseValue: ({ value }) => {
          if (Number(value)) {
            return formatCurrencyDecimal(value as string, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeData = section?.typeData as DepositReportTypeData

          if (Number(typeData?.paid_out_others_during_period)) {
            return formatCurrencyDecimal(
              typeData?.paid_out_others_during_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const depositReport = data?.depositReport as DepositReport

          if (Number(depositReport?.paid_out_others_during_period)) {
            return formatCurrencyDecimal(
              depositReport?.paid_out_others_during_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
    },
    {
      key: 'commissions_during_period',
      header: {
        label: () => t('report.deposit.header.commissionsDuringPeriod'),
      },
      cell: {
        parseValue: ({ value }) => {
          if (Number(value)) {
            return formatCurrencyDecimal(value as string, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeData = section?.typeData as DepositReportTypeData

          if (Number(typeData?.commissions_during_period)) {
            return formatCurrencyDecimal(typeData?.commissions_during_period, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const depositReport = data?.depositReport as DepositReport

          if (Number(depositReport?.commissions_during_period)) {
            return formatCurrencyDecimal(
              depositReport?.commissions_during_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
    },
    {
      key: 'deposits_end_of_period',
      header: {
        label: () => t('report.deposit.header.depositsEndOfPeriod'),
      },
      cell: {
        parseValue: ({ value }) => {
          if (Number(value)) {
            return formatCurrencyDecimal(value as string, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const typeData = section?.typeData as DepositReportTypeData

          if (Number(typeData?.deposits_end_of_period)) {
            return formatCurrencyDecimal(typeData?.deposits_end_of_period, {
              minDigits: 2,
            })
          } else {
            return '- €'
          }
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const depositReport = data?.depositReport as DepositReport

          if (Number(depositReport?.deposits_end_of_period)) {
            return formatCurrencyDecimal(
              depositReport?.deposits_end_of_period,
              {
                minDigits: 2,
              },
            )
          } else {
            return '- €'
          }
        },
      },
    },
  ],
}
