import { Country } from '@/models/settings.model'
import { Option, OrderingObject } from '@/models/shared.model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNumeric(n: any) {
  /*
        isNumeric(undefined) -> false
        isNumeric(null) -> false
        isNumeric('') -> false
        isNumeric(0)  -> true
        isNumeric('0')  -> true
        isNumeric(12.44)  -> true
        isNumeric('12.44')  -> true
        isNumeric(NaN) -> false
        isNumeric(Infinity) -> false
     */
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function getOptionKeys(optionList: Option[]) {
  return optionList.map((option) => option.key)
}

export function getOrdering(orderingObj: OrderingObject): string | null {
  if (orderingObj.value !== null) {
    return orderingObj.value ? orderingObj.asc : orderingObj.desc
  }
  return null
}

export function updateOrderValue(initialValue: boolean | null) {
  switch (initialValue) {
    case null:
      return true
      break
    case true:
      return false
      break
    case false:
      return null
      break
  }
}

export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512,
) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const getDefaultRegistrationCountry = (): number | '' => {
  const country = process.env.VUE_APP_DEFAULT_REGISTRATION_COUNTRY
  return parseInt(country) ?? ''
}

export const getCountryByAlpha3 = (
  countries: Country[],
  alpha3: string,
): Country | undefined => {
  return countries.find((country: Country) => {
    return country.alpha_3 === alpha3
  })
}
