import { Country, CountryFilters } from '@/models/settings.model'
import axios from 'axios'
import { ComputedRef } from 'vue'
import { useQuery } from 'vue-query'
import i18n from '../i18n'

// https://tkdodo.eu/blog/effective-react-query-keys
const countryKeys = {
  all: ['country'] as const,
  lists: () => [...countryKeys.all, 'list'] as const,
  list: (locale: ComputedRef<string>, params: CountryFilters) =>
    [...countryKeys.lists(), { locale, params }] as const,
}

export function useCountryQuery(params: CountryFilters) {
  const localeRef: any = i18n.global.locale
  return useQuery(
    countryKeys.list(localeRef, params),
    () => fetchCountries(params),
    {
      staleTime: 1000 * 60 * 60 * 8,
      retry: 0,
    },
  )
}

export async function fetchCountries(
  params: CountryFilters,
): Promise<Country[]> {
  const { data } = await axios.get<Country[]>('countries/', {
    params,
  })
  return data
}
