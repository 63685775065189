export enum FORMATS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TAB = 'tab',
  ACTIVE_TAB = 'active-tab',
  ICON = 'icon',
  ICON_CIRCULAR = 'icon-circular',
}

export enum CATEGORIES {
  DELETE = 'delete',
}

export enum SIZES {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum ALERT_TYPES {
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}
