import { OrderItemReferral } from '@/models/public/order.model'
import { PublicServiceDetails } from '@/models/public/service.model'
import { PropType } from 'vue'

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_LINK = 'BANK_LINK',
}

export enum BANK {
  SWEDBANK = 'SWEDBANK',
  SEB = 'SEB',
  LHV = 'LHV',
  LUMINOR = 'NORDEA',
  COOP = 'KREDIIDIPANK',
  POCOPAY = 'POCOPAY',
}

export enum ORDER_STATUS {
  CART = 1,
  CART_CONFIRMED = 2,
  PAID = 3,
  TIMED_OUT = 4,
  CANCELLED = 5,
  PARTIALLY_CANCELLED = 6,
  CLIENT_CONFIRMED = 7,
}

export enum MAKSEKESKUS_TRANSACTION_STATUS {
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum ORDER_ITEM_TYPE {
  EXPERIENCE = 'EXPERIENCE',
  EVENT_SERVICE = 'EVENT_SERVICE',
  HOUSING = 'HOUSING',
  ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE',
  MONEYJAR = 'MONEYJAR',
  PRODUCT = 'PRODUCT',
}

export enum ORDER_ITEM_PRICE_LINE_TYPE {
  EVENT = 'EVENT',
  ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE',
  HOUSING = 'HOUSING_OVERNIGHT',
  PACKAGED = 'PACKAGED',
  HOURLY = 'HOURLY',
  MONEYJAR = 'MONEYJAR',
  PRODUCT = 'PRODUCT',
}

export enum VAT {
  EXPERIENCE_TYPE = 20,
  HOUSING_TYPE = 9,
}

export enum TERMS_MODAL {
  PROVIDER = 'PROVIDER',
  TOURINGERY = 'TOURINGERY',
}

export enum TRANSLATION_TYPE {
  USER = 'USER',
  MACHINE = 'MACHINE',
}

export const servicePublicDetailsCommonProps = {
  serviceId: {
    // It can be either id or its hashed string form
    type: [Number, String] as PropType<number | string>,
    required: true,
  },
  service: {
    type: Object as PropType<PublicServiceDetails>,
    required: true,
  },
  isPreview: {
    type: Boolean,
    default: false,
  },
  enableOrdering: {
    type: Boolean,
    default: false,
  },
  referral: {
    type: Object as PropType<OrderItemReferral | undefined>,
    default: undefined,
  },
}
