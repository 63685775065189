import routes from '@/router/routes'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        // "Sometimes we need to wait a bit before scrolling in the page. For example,
        // when dealing with transitions, we want to wait for the transition to finish before scrolling."
        const scrollElement = document.querySelector(to.hash)
        if (scrollElement) {
          scrollElement.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500)
    }
  },
})

export default router
