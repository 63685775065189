import i18n from '../i18n'
const { t } = i18n.global

export enum RESOURCE_CATEGORY_TYPE {
  PERSON = 'PERSON',
  OBJECT = 'OBJECT',
}
export enum RESOURCE_CATEGORY_TYPE_NUMBER {
  PERSON = 1,
  OBJECT = 2,
}

export enum RESOURCE_FILTERS {
  TYPE = 'TYPE',
  RELATED_USER = 'RELATED_USER',
  RESOURCE_NAME = 'RESOURCE_NAME',
}

export enum RESOURCE_TABS {
  RESOURCES = 'RESOURCES',
  RESOURCE_GROUPS = 'RESOURCE_GROUPS',
}

export const getResourcesTabTranslation = (key: string) => {
  return {
    [RESOURCE_TABS.RESOURCES]: t('resources'),
    [RESOURCE_TABS.RESOURCE_GROUPS]: t('resourceGroups'),
  }[key]
}

export enum TASK_RESOURCE_TYPE {
  GROUP = 'GROUP',
  RESOURCE = 'RESOURCE',
  RESOURCE_GROUP = 'RESOURCE_GROUP',
}
