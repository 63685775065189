import { onBeforeMount, onBeforeUnmount, ref } from 'vue'

export function useBreakpoints() {
  const mql = window.matchMedia('(max-width: 800px)')
  const isMobile = ref(mql.matches)

  function isMobileMatch(event: MediaQueryListEvent) {
    isMobile.value = event.matches
  }

  onBeforeMount(() => {
    mql.addEventListener('change', isMobileMatch)
  })

  onBeforeUnmount(() => {
    mql.addEventListener('change', isMobileMatch)
  })

  return {
    isMobile,
  }
}
