import { StringDate, StringDecimal } from '@/models/shared.model'

export enum TripPurpose {
  VACATION = 'VACATION',
  BUSINESS = 'BUSINESS',
  CONFERENCE = 'CONFERENCE',
  OTHER = 'OTHER',
}

interface DistributionData {
  people: number
  nights: number
}

interface CountryRowData {
  id: number
  label: string
  country_code: string | null
}

export interface CountryDistributionRowData
  extends DistributionData,
    CountryRowData {}

interface StatisticsReportMetadata {
  total_check_ins: number
  unconfirmed_check_ins: number
  missing_check_ins: number
}

interface StatisticsReportGuestDistribution {
  local: DistributionData
  foreign: DistributionData
}

interface StatisticsReportDistributionByTripPurpose {
  [TripPurpose.VACATION]: StatisticsReportGuestDistribution
  [TripPurpose.BUSINESS]: StatisticsReportGuestDistribution
  [TripPurpose.CONFERENCE]: StatisticsReportGuestDistribution
  [TripPurpose.OTHER]: StatisticsReportGuestDistribution
  total: StatisticsReportGuestDistribution
}

interface StatisticsReportPeriodCapacity {
  income_with_vat: StringDecimal
  days_open: number
  rooms: number
  bed_places: number
  non_bed_places: number
  room_nights_sold: number
}

interface StatisticsReportPeriodicData {
  capacity: StatisticsReportPeriodCapacity
  TRAVEL_PURPOSE: StatisticsReportDistributionByTripPurpose
  country_distribution: CountryDistributionRowData[]
  metadata: StatisticsReportMetadata
}

export interface StatisticsReportCompanyInfo {
  reg_code: string
  reg_country: string
  vat_number: string
  name: string
  email: string
  phone: string
  address: string
}

export interface StatisticsReport {
  company_info: StatisticsReportCompanyInfo
  period: StatisticsReportPeriodicData
  previous_period: StatisticsReportPeriodicData
}

export interface StatisticsReportRequestParams {
  start: StringDate
  services?: number[]
}
