export interface MergeObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string | number]: any
}

export function deepMergeExistingKeys(
  obj: MergeObject,
  objToMerge: MergeObject,
): MergeObject {
  const mergedObj: MergeObject = {}
  Object.keys(obj).forEach((key) => {
    if (objToMerge[key] !== undefined) {
      if (typeof obj[key] === 'object') {
        // todo: Without these 'as' conversions, ts complains about it
        mergedObj[key] = deepMergeExistingKeys(
          obj[key] as MergeObject,
          objToMerge[key] as MergeObject,
        )
      } else {
        mergedObj[key] = objToMerge[key]
      }
    } else {
      mergedObj[key] = obj[key]
    }
  })
  return mergedObj
}
