import { ALERT_TYPES } from '@/enums/components'
import { MAKSEKESKUS_TRANSACTION_STATUS, ORDER_STATUS } from '@/enums/public'
import { Order, Visit } from '@/models/public/order.model'
import { ExperiencePeriodAvailabilitySearch } from '@/models/public/service.model'
import { Alert } from '@/models/shared.model'
import { formatIntlDateAndTime } from '@/utils/formatters'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import i18n from '../i18n'

const { t } = i18n.global

interface OrderState {
  order: Order
  availabilitySearch: Visit | ExperiencePeriodAvailabilitySearch
}

const defaultOrder: Order = {
  uuid: '',
  status: ORDER_STATUS.CART,
  client: null,
  payment_method: null,
  payment_methods: null,
  bank: null,
  agreed_to_terms: false,
  total_discount: '0.00',
  total_vat: '0.00',
  total_price_with_vat: '0.00',
  order_items: [],
  confirmed_at: null,
  client_confirmed_at: null,
  paid_at: null,
  cancelled_at: null,
  expires_at: null,
  can_cancel: false,
  refundable_amount: '0',
  refunded_amount: '0',
  client_confirmation_email_at: '',
  can_resend_confirmation_email: false,
}

export const useOrderStore = defineStore('order', {
  state: (): OrderState => ({
    order: { ...defaultOrder },
    // TODO: EXPERIENCE: HOUSING: Visit and ExperiencePeriodAvailabilitySearch aren't equivalent, should be separated?
    availabilitySearch: {
      check_in: null,
      check_out: null,
      start: null,
      end: null,
      group_size: 1,
      adults: 1,
      children: 0,
    },
  }),

  getters: {
    computedOrder: (state) => state.order,
    orderUuid: (state) => state.order?.uuid,
    items: (state) => state.order?.order_items || [],
    isOrderWithItems: (state) => {
      return state.order.uuid && state.order.order_items.length > 0
    },
    totalPriceWithoutVat: (state) =>
      Number(state.order.total_price_with_vat) - Number(state.order.total_vat),
    isItemsEditable: (state) => state.order.status === ORDER_STATUS.CART,
    computedAvailabilitySearch: (state) => {
      return state.availabilitySearch
    },
    orderAlerts: (state) => {
      const alertList: Alert[] = []
      const order = state.order
      // Reference has to be here to work properly
      const route = useRoute()

      let hasTickets = false
      order.order_items.forEach((orderItem) => {
        if (orderItem.has_tickets) hasTickets = true
      })

      if (order.status == ORDER_STATUS.CANCELLED) {
        alertList.push({
          text: t('cartPage.orderStatusCancelledAlert'),
          alertType: ALERT_TYPES.DANGER,
        })
      } else if (order.status == ORDER_STATUS.TIMED_OUT) {
        alertList.push({
          text: t('cartPage.orderStatusTimedOutAlert'),
          alertType: ALERT_TYPES.DANGER,
        })
      } else if (order.status == ORDER_STATUS.PAID) {
        alertList.push({
          text: hasTickets
            ? t('cartPage.orderStatusPaidAlertWithTickets')
            : t('cartPage.orderStatusPaidAlertWithoutTickets'),
          alertType: ALERT_TYPES.SUCCESS,
          'button-label': hasTickets ? t('downloadTickets') : null,
          'button-icon': hasTickets ? 'download-simple' : null,
        })
      } else if (order.expires_at) {
        const expiresAt = formatIntlDateAndTime(order.expires_at)
        if (order.status == ORDER_STATUS.CLIENT_CONFIRMED) {
          if (route && route.query.json) {
            const response = JSON.parse(route.query.json as string)
            if (response.status === MAKSEKESKUS_TRANSACTION_STATUS.COMPLETED) {
              alertList.push({
                text: hasTickets
                  ? t('cartPage.orderStatusPendingAlertWithTickets')
                  : t('cartPage.orderStatusPendingAlertWithoutTickets'),
                alertType: ALERT_TYPES.SUCCESS,
                'button-label': hasTickets ? t('downloadTickets') : null,
                'button-icon': hasTickets ? 'download-simple' : null,
              })
            } else if (
              response.status === MAKSEKESKUS_TRANSACTION_STATUS.CANCELLED
            ) {
              alertList.push({
                text: t('cartPage.orderStatusCancelledAlert'),
                alertType: ALERT_TYPES.DANGER,
              })
            }
          } else {
            alertList.push({
              text: t('cartPage.clientConfirmedOrderExpiresAtAlert', {
                expiresAt,
              }),
              alertType: ALERT_TYPES.WARNING,
            })
          }
        } else {
          alertList.push({
            text: t('cartPage.cartConfirmedOrderExpiresAtAlert', {
              expiresAt,
            }),
            alertType: ALERT_TYPES.WARNING,
          })
        }
      }

      return alertList
    },
    isCartMode: (state) =>
      [
        ORDER_STATUS.CART,
        ORDER_STATUS.CART_CONFIRMED,
        ORDER_STATUS.CLIENT_CONFIRMED,
      ].includes(state.order.status),
    showRefundable: (state) =>
      [ORDER_STATUS.PAID, ORDER_STATUS.PARTIALLY_CANCELLED].includes(
        state.order.status,
      ),
    showRefunded: (state) =>
      [ORDER_STATUS.CANCELLED, ORDER_STATUS.PARTIALLY_CANCELLED].includes(
        state.order.status,
      ) && !!state.order.paid_at,
  },

  actions: {
    updateOrder(order: Order) {
      this.order = order
      sessionStorage.setItem('order-uuid', String(order.uuid))
    },
    updateHousingAvailabilitySearch(
      availabilitySearch: Visit | ExperiencePeriodAvailabilitySearch,
    ) {
      this.availabilitySearch = availabilitySearch
      sessionStorage.setItem(
        'availiability-search-housing',
        JSON.stringify(availabilitySearch),
      )
    },
    updateAvailabilitySearch(
      availabilitySearch: Visit | ExperiencePeriodAvailabilitySearch,
    ) {
      this.availabilitySearch = availabilitySearch
      sessionStorage.setItem(
        'availiability-search',
        JSON.stringify(availabilitySearch),
      )
    },
    reset() {
      this.order = defaultOrder
    },
  },
})
