export interface SyncEntity {
  id: number
  name: string
  type: GoogleCalendarSettingType
  subtype: 'PERSON' | 'OBJECT' | null
  setting: GoogleCalendarSetting | null
}

export enum GoogleCalendarSettingType {
  HOUSING = 'HOUSING',
  EXPERIENCE = 'EXPERIENCE',
  EVENT = 'EVENT',
  MONEYJAR = 'MONEYJAR',
  RESOURCE = 'RESOURCE',
  TASK = 'TASK',
}

export interface GoogleCalendarSetting {
  id: number
  summary: string
  description: string
  event_name: string
  is_active: boolean
  send_reminders: boolean
  remind_minutes: number
  google_calendar_id: number
}

export interface GoogleCalendarSettingPayload {
  summary: string
  description: string
  is_active: boolean
  send_reminders: boolean
  remind_minutes: number
}

export interface DisplayEvent {
  id: number
  realStart: Date // actual start and end date of event
  realEnd: Date
  start: Date // start and end for displaying in the calendar, min event duration is 15mins
  end: Date
  summary: string
  description: string
  type: GoogleCalendarSettingType
  related_object_id: number
  calendar_id: string
  overlapStep?: number
  length?: number
  track?: number
  color?: string
  orderColor?: string
  order_uuid: string
  order_status: OrderOverviewStatus
  source: string
  service_hash: string
  allDayEvent: boolean
  reservation_id?: number
}

export interface BackendEvent {
  id: number
  start: string
  end: string
  summary: string
  description: string
  type: GoogleCalendarSettingType
  related_object_id: number
  calendar_id: string
  order_uuid: string
  order_status: OrderOverviewStatus
  source: string
  service_hash: string
  reservation_id?: number
}

export interface Order {
  order_uuid: string
  reference_number: string
  status: OrderOverviewStatus
  client_name: string | null
  comment: string
  events: DisplayEvent[]
  orderColor: string
}

export interface ShowEventTypes {
  type: string
  shown_object_ids: number[]
}

export interface EventsRequest {
  start: Date
  end: Date
  types?: ShowEventTypes[]
}

export interface CalendarListItem {
  id: string
  type: string
  name: string
  related_object_id: number
  color: string
  active: boolean
}

export interface StatusListItem {
  id: string
  status: OrderOverviewStatus
  color: string
  backgroundColor: string
  active: boolean
}

export interface OrderEventGroup {
  type: GoogleCalendarSettingType
  events: DisplayEvent[]
}

export enum CalendarType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum OverviewType {
  GENERAL = 'GENERAL',
  ORDERS = 'ORDERS',
  ORDERS_CARDS = 'ORDERS_CARDS',
}

// TODO: @Lukas Pertel ärikliendi nime lisamine?
export enum EventNameVariable {
  FIRST_NAME = '[FIRST_NAME]',
  LAST_NAME = '[LAST_NAME]',
  FULL_NAME = '[FULL_NAME]',
  NAME = '[NAME]',
  SERVICE_NAME = '[SERVICE_NAME]', // if object itself is not service
  UNIT_NAME = '[UNIT_NAME]',
  QUANTITY = '[QUANTITY]',
  GROUP_SIZE = '[GROUP_SIZE]',
}

export enum OrderOverviewStatus {
  OFFER = 'OFFER',
  EXPIRED = 'EXPIRED',
  CONFIRMED_OFFER = 'CONFIRMED_OFFER',
  CONFIRMED_ORDER = 'CONFIRMED_ORDER',
  LOCKED = 'LOCKED',
  COMPLETED = 'COMPLETED',
}
