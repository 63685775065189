import i18n from '@/i18n'
import { TripPurpose } from '@/models/reports/statisticsReport'

const { t } = i18n.global

export const getTabTranslation = () => t('report.tabs.statisticsReport')

export enum CAPACITY_TABLE_ROWS_FIELDS {
  DAYS_OPEN = 'days_open',
  ROOMS = 'rooms',
  BED_PLACES = 'bed_places',
  CAMPING_PLACES = 'non_bed_places',
  ROOM_NIGHTS_SOLD = 'room_nights_sold',
}

export const getStatisticReportTranslations = (key: string) => {
  return (
    {
      [CAPACITY_TABLE_ROWS_FIELDS.DAYS_OPEN]: t('reports.statistics.openDaysLabel'),
      [CAPACITY_TABLE_ROWS_FIELDS.ROOMS]: t('reports.statistics.roomsLabel'),
      [CAPACITY_TABLE_ROWS_FIELDS.BED_PLACES]: t('reports.statistics.bedPlacesLabel'),
      [CAPACITY_TABLE_ROWS_FIELDS.CAMPING_PLACES]: t('reports.statistics.campingPlacesLabel'),
      [CAPACITY_TABLE_ROWS_FIELDS.ROOM_NIGHTS_SOLD]: t('reports.statistics.roomNightsSoldLabel'),
    }[key] || key
  )
}

export const getStatisticReportTripPurposeTranslations = (key: string) => {
  return (
    {
      [TripPurpose.VACATION]: t('reports.statistics.tripPurpose.vacation'),
      [TripPurpose.BUSINESS]: t('reports.statistics.tripPurpose.business'),
      [TripPurpose.CONFERENCE]: t('reports.statistics.tripPurpose.conference'),
      [TripPurpose.OTHER]: t('reports.statistics.tripPurpose.other'),
      total: t('reports.statistics.tripPurpose.total'),
    }[key] || key
  )
}