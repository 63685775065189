import { Option } from '@/models/shared.model'
import i18n from '../i18n'
const { t } = i18n.global

export enum TASKS_TABS {
  TASKS = 'tasks',
}

export const getTasksTabTranslation = (key: string) => {
  return {
    [TASKS_TABS.TASKS]: t('tasks'),
  }[key]
}

export enum TaskType {
  FIXED_TIMESLOT = 'FIXED_TIMESLOT',
  OPEN_TIMESLOT = 'OPEN_TIMESLOT',
}

export const getTaskTypeTranslation = (key: string) => {
  return {
    [TaskType.FIXED_TIMESLOT]: t('fixedTimeslot'),
    [TaskType.OPEN_TIMESLOT]: t('openTimeslot'),
  }[key]
}

export const getTaskTypeOptionList = (): Option[] => {
  return Object.keys(TaskType).map((type) => ({
    key: type,
    label: getTaskTypeTranslation(type),
  }))
}

export enum TASK_STATUS {
  UPCOMING = 'UPCOMING',
  ONGOING = 'ONGOING',
  NOT_DONE = 'NOT_DONE',
  DONE = 'DONE',
}

export const getTaskStatusTranslation = (key: string) => {
  return {
    [TASK_STATUS.UPCOMING]: t('upcoming'),
    [TASK_STATUS.ONGOING]: t('ongoing'),
    [TASK_STATUS.NOT_DONE]: t('notDone'),
    [TASK_STATUS.DONE]: t('done'),
  }[key]
}

export enum TaskColors {
  BLUE = '#0000FF',
  BLUE_PURPLE = '#324ab2',
  BLUE_GREEN = '#0D98BA',
  RED = '#FF0000',
  RED_ORANGE = '#ff5349',
  RED_PURPLE = '#953553',
  YELLOW = '#FFFF00',
  YELLOW_ORANGE = '#FFAE42',
  YELLOW_GREEN = '#9ACD32',
  GREEN = '#00FF00',
  PURPLE = '#A020F0',
  ORANGE = '#FFA500',
}

export const getTaskColorsTranslation = (key: string) => {
  return {
    [TaskColors.BLUE]: t('color.blue'),
    [TaskColors.BLUE_PURPLE]: t('color.blue_purple'),
    [TaskColors.BLUE_GREEN]: t('color.blue_green'),
    [TaskColors.RED]: t('color.red'),
    [TaskColors.RED_ORANGE]: t('color.red_orange'),
    [TaskColors.RED_PURPLE]: t('color.red_purple'),
    [TaskColors.YELLOW]: t('color.yellow'),
    [TaskColors.YELLOW_ORANGE]: t('color.yellow_orange'),
    [TaskColors.YELLOW_GREEN]: t('color.yellow_green'),
    [TaskColors.GREEN]: t('color.green'),
    [TaskColors.PURPLE]: t('color.purple'),
    [TaskColors.ORANGE]: t('color.orange'),
  }[key]
}

export const getTaskColors = () => {
  return Object.values(TaskColors).map((value) => ({
    key: value,
    label: getTaskColorsTranslation(value),
    style: { 'background-color': value },
  }))
}

export enum TASK_FILTERS {
  SEARCH = 'SEARCH',
  RECIPIENT = 'RECIPIENT',
  PERIOD = 'PERIOD',
  SERVICE = 'SERVICE',
  STATUS = 'STATUS',
  ID = 'ID',
  START = 'START',
}

export enum TASK_GENERATOR_TRIGGER {
  RESERVATION_START = 'RESERVATION_START',
  RESERVATION_END = 'RESERVATION_END',
}

export const getTaskGeneratorTriggerOptionList = (): Option[] => {
  return Object.keys(TASK_GENERATOR_TRIGGER).map((trigger) => ({
    key: trigger,
    label: getTaskGeneratorTriggerTranslation(trigger),
  }))
}

export const getTaskGeneratorTriggerTranslation = (key: string) => {
  return {
    [TASK_GENERATOR_TRIGGER.RESERVATION_START]: t('reservationStart'),
    [TASK_GENERATOR_TRIGGER.RESERVATION_END]: t('reservationEnd'),
  }[key]
}

export enum TASK_RECIPIENT_RESPONSE {
  REFUSED = 'REFUSED',
  ACCEPTED = 'ACCEPTED',
  NOT_DONE = 'NOT_DONE',
  DONE = 'DONE',
}

export const getTaskResponseTranslation = (key: string) => {
  return {
    [TASK_RECIPIENT_RESPONSE.REFUSED]: t('refused'),
    [TASK_RECIPIENT_RESPONSE.ACCEPTED]: t('accepted'),
    [TASK_RECIPIENT_RESPONSE.NOT_DONE]: t('notDone'),
    [TASK_RECIPIENT_RESPONSE.DONE]: t('done'),
  }[key]
}

export const getTaskStatusOptionList = (): Option[] => {
  return Object.keys(TASK_STATUS).map((status) => ({
    key: status,
    label: getTaskStatusTranslation(status as TASK_STATUS),
  }))
}
