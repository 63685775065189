import {
  CalendarEventServiceListItem,
  CalendarServiceListItem,
} from '@/models/calendar.model'
import {
  RequiredSettingsOverview,
  SettingsGeneral,
  SettingsPayment,
  SettingsProfile,
} from '@/models/settings.model'
import axios from 'axios'
import { computed, Ref, ref } from 'vue'
import { useMutation, useQuery, useQueryClient } from 'vue-query'

// https://tkdodo.eu/blog/effective-react-query-keys
const settingKeys = {
  all: ['settings'] as const,
  general: () => [...settingKeys.all, 'general'] as const,
  payment: () => [...settingKeys.all, 'payment'] as const,
  profile: () => [...settingKeys.all, 'profile'] as const,
  checkInQuestion: () => [...settingKeys.all, 'checkInQuestion'] as const,
  companyLogo: (companyId: Ref<number | null>) =>
    [...settingKeys.all, 'companyLogo', { companyId }] as const,
  housingsList: (companyId: Ref<number | null>) =>
    [...settingKeys.all, 'housingsList', { companyId }] as const,
  experiencesList: (companyId: Ref<number | null>) =>
    [...settingKeys.all, 'experiencesList', { companyId }] as const,
  requiredSettingsOverview: (companyId: Ref<number | null>) =>
    [...settingKeys.all, 'requiredSettingsOverview', { companyId }] as const,
}

export function useSettingsGeneralQuery() {
  return useQuery(
    settingKeys.general(),
    async () => {
      const { data } = await axios.get<SettingsGeneral>(`settings/general/`)
      return data
    },
    { retry: 0 },
  )
}

export function useUpdateSettingsGeneralMutation() {
  const queryClient = useQueryClient()

  return useMutation(
    async (form: SettingsGeneral) => {
      const { data } = await axios.post<SettingsGeneral>(
        `settings/general/`,
        form,
      )
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(settingKeys.general(), data)
      },
    },
  )
}

export function useFetchSettingsPayments(companyId: Ref<number | null>) {
  return useMutation(
    async () => {
      const { data } = await axios.get<SettingsPayment>(
        `companies/${companyId.value}/payment/`,
      )
      return data
    },
    { retry: 0 },
  )
}

export function useCreateSettingsPaymentMutation(
  companyId: Ref<number | null>,
) {
  const queryClient = useQueryClient()

  return useMutation(
    async (form: SettingsPayment) => {
      const { data } = await axios.post<SettingsPayment>(
        `companies/${companyId.value}/payment/`,
        form,
      )
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(settingKeys.general(), data)
        //queryClient.invalidateQueries(settingKeys.requiredSettingsOverview(companyId))
      },
    },
  )
}

export function useUpdateSettingsPaymentMutation(
  companyId: Ref<number | null>,
) {
  const queryClient = useQueryClient()

  return useMutation(
    async (form: SettingsPayment) => {
      const { data } = await axios.put<SettingsPayment>(
        `companies/${companyId.value}/payment/`,
        form,
      )
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(settingKeys.general(), data)
        //queryClient.invalidateQueries(settingKeys.requiredSettingsOverview(companyId))
      },
    },
  )
}

export function useSettingsProfileQuery(companyId: Ref<number | null>) {
  return useQuery(
    settingKeys.profile(),
    async (): Promise<SettingsProfile> => {
      const { data } = await axios.get<SettingsProfile>(
        `companies/${companyId.value}/profile/`,
      )
      return data
    },
    { retry: 0 },
  )
}

export function useGetSettingsProfileMutation(companyId: Ref<number | null>) {
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const { data } = await axios.get<SettingsProfile>(
        `companies/${companyId.value}/profile/`,
      )
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(settingKeys.profile(), data)
      },
    },
  )
}

export function useUpdateSettingsProfileMutation(
  companyId: Ref<number | null>,
) {
  const queryClient = useQueryClient()

  return useMutation(
    async (form: SettingsProfile) => {
      const { data } = await axios.put<SettingsProfile>(
        `companies/${companyId.value}/profile/`,
        form,
      )
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(settingKeys.profile(), data)
        //queryClient.invalidateQueries(settingKeys.requiredSettingsOverview(companyId))
      },
    },
  )
}

export function useCompanyLogoQuery(companyId: Ref<number | null>) {
  return useQuery(
    settingKeys.companyLogo(companyId),
    async (): Promise<any> => {
      const { data } = await axios.get<any>(
        `companies/${companyId.value}/logo/`,
      )
      return data
    },
    { retry: 0 },
  )
}

export function useFetchCompanyLogo(companyId: number | null) {
  return useMutation(async () => {
    const { data } = await axios.get<any>(`/companies/${companyId}/logo/`)
    return data
  })
}

export function useCreateCompanyLogoMutation(companyId: Ref<number | null>) {
  const queryClient = useQueryClient()

  return useMutation(
    async (item: any) => {
      const formData = new FormData()
      formData.append('company', String(companyId.value))
      formData.append('file', item.file)
      formData.append('heading', item.heading)
      const { data } = await axios.post<any>(
        `/companies/${companyId.value}/logo/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(settingKeys.companyLogo(ref(companyId)))
      },
    },
  )
}

export function useSettingsHousingServicesQuery(companyId: Ref<number | null>) {
  return useQuery(
    settingKeys.housingsList(companyId),
    async () => {
      const { data } = await axios.get<CalendarEventServiceListItem[]>(
        `companies/${companyId.value}/settings/services/housings/`,
        {
          params: {},
        },
      )
      return data
    },
    {
      placeholderData: () => [],
      enabled: computed(() => !!companyId.value),
      retry: 0,
    },
  )
}

export function useSettingsExperienceServicesQuery(
  companyId: Ref<number | null>,
) {
  return useQuery(
    settingKeys.experiencesList(companyId),
    async () => {
      const { data } = await axios.get<CalendarServiceListItem[]>(
        `companies/${companyId.value}/settings/services/experiences/`,
        {
          params: {},
        },
      )
      return data
    },
    {
      placeholderData: () => [],
      enabled: computed(() => !!companyId.value),
      retry: 0,
    },
  )
}

export function useRequiredSettingsOverviewQuery(
  companyId: Ref<number | null>,
) {
  return useQuery(
    settingKeys.requiredSettingsOverview(companyId),
    async () => {
      const { data } = await axios.get<RequiredSettingsOverview>(
        `companies/${companyId.value}/required-settings-overview/`,
      )
      return data
    },
    {
      enabled: computed(() => !!companyId.value),
      retry: 0,
    },
  )
}
