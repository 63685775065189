import {
  AriregisterBasic,
  AriregisterBeneficialOwner,
  AriregisterDetails,
} from '@/models/ariregister.model'
import axios from 'axios'
import { Ref } from 'vue'
import { useMutation } from 'vue-query'

export function useFetchAriregisterBasic() {
  return useMutation(async (companyName: Ref<string>) => {
    const { data } = await axios.get<AriregisterBasic[]>(
      `/ariregister/lihtandmed/${companyName.value}/`,
    )
    return data
  })
}

export function useFetchAriregisterDetails() {
  return useMutation(async (companyCode: number) => {
    const { data } = await axios.get<AriregisterDetails>(
      `/ariregister/detailandmed/${companyCode}/`,
    )
    return data
  })
}

export function useFetchAriregisterBeneficialOwners() {
  return useMutation(async (companyCode: number) => {
    const { data } = await axios.get<AriregisterBeneficialOwner[]>(
      `/ariregister/tegelikudkasusaajad/${companyCode}/`,
    )
    return data
  })
}
