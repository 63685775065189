import { Option } from '@/models/shared.model'
import i18n from '../i18n'
import { RESOURCE_CATEGORY_TYPE } from './resources'
const { t } = i18n.global

export enum SERVICE_TABS {
  GENERAL_INFO = 'general_info',
  LOCATION = 'location',
  SERVICE_HOUSING = 'service_housing',
  SERVICE_EVENT = 'service_event',
  SERVICE_PERIODS = 'service_periods',
  SERVICE_PERIODS_FOR_EVENT = 'service_periods_for_event',
  PREFERENCES = 'preferences.preferences',
  RELATED_RESOURCES = 'related_resources',
  RELATED_SERVICES = 'related_services',
  RELATED_TEMPLATES = 'related_templates',
  RELATED_CHECK_IN_QUESTIONS = 'related_check-in-questions',
  TASK_GENERATOR = 'task_generator',
  MULTIMEDIA = 'multimedia',
  PREVIEW = 'preview',
  ADVANCED = 'advanced',
}

export const getServiceTabTranslation = (key: string): string => {
  return (
    {
      [SERVICE_TABS.GENERAL_INFO]: t('general_info'),
      [SERVICE_TABS.SERVICE_HOUSING]: t('housing'),
      [SERVICE_TABS.SERVICE_EVENT]: t('service_event'),
      [SERVICE_TABS.SERVICE_PERIODS]: t('service_periods'),
      // Alternative SERVICE_PERIODS tab name for event service
      [SERVICE_TABS.SERVICE_PERIODS_FOR_EVENT]: t(
        'tabs.servicePeriodsForEvent',
      ),
      [SERVICE_TABS.PREFERENCES]: t('preferences.preferences'),
      [SERVICE_TABS.RELATED_RESOURCES]: t('related_resources'),
      [SERVICE_TABS.RELATED_SERVICES]: t('additionalServices'),
      [SERVICE_TABS.RELATED_TEMPLATES]: t('related_templates'),
      [SERVICE_TABS.RELATED_CHECK_IN_QUESTIONS]: t(
        'related_check_in_questions',
      ),
      [SERVICE_TABS.TASK_GENERATOR]: t('taskGenerator'),
      [SERVICE_TABS.MULTIMEDIA]: t('multimedia'),
      [SERVICE_TABS.PREVIEW]: t('preview'),
      [SERVICE_TABS.ADVANCED]: t('serviceAdvancedTab'),
    }[key] ?? key
  )
}

export enum SERVICES_TABS {
  SERVICES = 'services',
  ADDITIONAL_SERVICES = 'additional_services',
}

export const getServicesTabTranslation = (key: string) => {
  return {
    [SERVICES_TABS.SERVICES]: t('services'),
    [SERVICES_TABS.ADDITIONAL_SERVICES]: t('additionalServices'),
  }[key]
}

export enum ADDITIONAL_SERVICE_TABS {
  GENERAL_INFO = 'general_info',
  RELATED_SERVICE = 'related_service',
  MULTIMEDIA = 'multimedia',
}

export const getAdditionalServiceTabTranslation = (key: string) => {
  return {
    [ADDITIONAL_SERVICE_TABS.GENERAL_INFO]: t('general_info'),
    [ADDITIONAL_SERVICE_TABS.RELATED_SERVICE]: t('related_service'),
    [ADDITIONAL_SERVICE_TABS.MULTIMEDIA]: t('multimedia'),
  }[key]
}

export enum ServiceType {
  EXPERIENCE = 'EXPERIENCE',
  HOUSING = 'HOUSING',
  ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE',
  EVENT = 'EVENT',
  MONEYJAR = 'MONEYJAR',
  PRODUCT = 'PRODUCT',
}

// Currently the values are identical
export { ServiceType as CategoryType }

export const getServiceTypeTranslation = (key: string) => {
  return {
    [ServiceType.EXPERIENCE]: t('serviceType.experienceLabel'),
    [ServiceType.HOUSING]: t('serviceType.housingLabel'),
    [ServiceType.EVENT]: t('serviceType.eventLabel'),
    [ServiceType.MONEYJAR]: t('serviceType.moneyJarLabel'),
    [ServiceType.PRODUCT]: t('serviceType.productLabel'),
  }[key]
}

export enum ACCESS_OPTIONS {
  is_signposted = 'is_signposted',
  is_hard_surfaced_road = 'is_hard_surfaced_road',
  access_by_tour_bus = 'access_by_tour_bus',
  access_by_public_transport = 'access_by_public_transport',
  access_by_car = 'access_by_car',
  access_on_foot = 'access_on_foot',
}

export enum DEFAULT_COORDINATES {
  LATITUDE_ESTONIA = 58.595272,
  LONGITUDE_ESTONIA = 25.013607,
}

export enum ReservationType {
  ONE_QUANTITY_PER_PERSON = 'ONE_QUANTITY_PER_PERSON',
  ONE_CAPACITY_PER_PERSON = 'ONE_CAPACITY_PER_PERSON',
  ONE_QUANTITY_PER_RESERVATION = 'ONE_QUANTITY_PER_RESERVATION',
  ONE_QUANTITY_PER_EVENT = 'ONE_QUANTITY_PER_EVENT',
}

export const ReservationTypeListForServiceType = {
  [ServiceType.EVENT]: [
    ReservationType.ONE_QUANTITY_PER_PERSON,
    ReservationType.ONE_CAPACITY_PER_PERSON,
    ReservationType.ONE_QUANTITY_PER_EVENT,
  ],
  [ServiceType.EXPERIENCE]: [
    ReservationType.ONE_QUANTITY_PER_PERSON,
    ReservationType.ONE_CAPACITY_PER_PERSON,
    ReservationType.ONE_QUANTITY_PER_RESERVATION,
  ],
} as Record<ServiceType, ReservationType[]>

export const ReservationTypeListForResourceCategoryType = {
  // Person resource always has only one quantity, so cannot add per person variations.
  [RESOURCE_CATEGORY_TYPE.PERSON]: [
    ReservationType.ONE_QUANTITY_PER_RESERVATION,
    ReservationType.ONE_QUANTITY_PER_EVENT,
  ],
  [RESOURCE_CATEGORY_TYPE.OBJECT]: [
    ReservationType.ONE_QUANTITY_PER_PERSON,
    ReservationType.ONE_CAPACITY_PER_PERSON,
    ReservationType.ONE_QUANTITY_PER_RESERVATION,
    ReservationType.ONE_QUANTITY_PER_EVENT,
  ],
} as Record<RESOURCE_CATEGORY_TYPE, ReservationType[]>

export enum ReservationSource {
  TOURINGERY = 'Touringery',
  BOOKING = 'Booking',
  AIRBNB = 'Airbnb',
}

export enum SERVICE_FILTERS {
  CATEGORIES = 'CATEGORIES',
  TYPE = 'TYPE',
  STATE = 'STATE',
  PRIVATE_STATE = 'PRIVATE_STATE',
  CREATED_AT = 'CREATED_AT',
}

export enum ADDITIONAL_SERVICE_FILTERS {
  CREATED_AT = 'CREATED_AT',
}

export enum ServiceUnit {
  PERSON = 'PERSON',
  PET = 'PET',
  UNIT = 'UNIT',
  HOUR = 'HOUR',
  KG = 'KG',
  G = 'G',
  L = 'L',
  ML = 'ML',
  PCS = 'PCS',
  OTHER = 'OTHER',
}

export const getServiceUnitLabel = (key: ServiceUnit) => {
  return {
    [ServiceUnit.PERSON]: t('serviceUnits.person'),
    [ServiceUnit.PET]: t('serviceUnits.pet'),
    [ServiceUnit.UNIT]: t('serviceUnits.unit'),
    [ServiceUnit.HOUR]: t('serviceUnits.hour'),
    [ServiceUnit.KG]: t('serviceUnits.kg'),
    [ServiceUnit.G]: t('serviceUnits.g'),
    [ServiceUnit.L]: t('serviceUnits.l'),
    [ServiceUnit.ML]: t('serviceUnits.ml'),
    [ServiceUnit.PCS]: t('serviceUnits.pcs'),
    [ServiceUnit.OTHER]: t('serviceUnits.other'),
  }[key]
}

export const getServiceTypeOptionList = (): Option[] => {
  const list = []
  for (const type in ServiceType) {
    if (type !== ServiceType.ADDITIONAL_SERVICE) {
      list.push({
        key: type,
        label: getServiceTypeTranslation(type as ServiceType),
      })
    }
  }
  return list
}

export enum SERVICE_EVENT_FILTERS {
  DATE = 'DATE',
  SHOW_ARCHIVED = 'SHOW_ARCHIVED',
}

export enum SERVICE_STATE_FILTER {
  SHOW_ARCHIVED = 'SHOW_ARCHIVED',
  SHOW_ACTIVE = 'SHOW_ACTIVE',
  SHOW_ALL = 'SHOW_ALL',
}

export enum SERVICE_PRIVATE_STATE_FILTER {
  ALL = 'ALL',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum TICKET_VALIDITY_TYPE {
  NONE = 'NONE',
  FIXED_DATES = 'FIXED_DATES',
  PERIOD_FROM_PURCHASE = 'PERIOD_FROM_PURCHASE',
}

export const getServiceArchiveTranslation = (key: string) => {
  return {
    [SERVICE_STATE_FILTER.SHOW_ARCHIVED]: t('serviceStateFilter.showArchived'),
    [SERVICE_STATE_FILTER.SHOW_ACTIVE]: t('serviceStateFilter.showActive'),
    [SERVICE_STATE_FILTER.SHOW_ALL]: t('serviceStateFilter.showAll'),
  }[key]
}

export const getServiceStateOptionList = (): Option[] => {
  const list = []
  for (const type in SERVICE_STATE_FILTER) {
    list.push({
      key: type,
      label: getServiceArchiveTranslation(type as SERVICE_STATE_FILTER),
    })
  }
  return list
}

export const getServicePrivateStateTranslation = (
  key: SERVICE_PRIVATE_STATE_FILTER,
) => {
  return {
    [SERVICE_PRIVATE_STATE_FILTER.PUBLIC]: t(
      'servicePrivateStateFilter.publicLabel',
    ),
    [SERVICE_PRIVATE_STATE_FILTER.PRIVATE]: t(
      'servicePrivateStateFilter.privateLabel',
    ),
    [SERVICE_PRIVATE_STATE_FILTER.ALL]: t('servicePrivateStateFilter.allLabel'),
  }[key]
}

export const getServicePrivateStateOptionList = (): Option[] => {
  const list = []
  for (const type in SERVICE_PRIVATE_STATE_FILTER) {
    list.push({
      key: type,
      label: getServicePrivateStateTranslation(
        type as SERVICE_PRIVATE_STATE_FILTER,
      ),
    })
  }
  return list
}

export const getDefaultPrivateStateOption = (): Option => {
  const options = getServicePrivateStateOptionList()
  return (
    options.find((option) => option.key === SERVICE_PRIVATE_STATE_FILTER.ALL) ||
    options[0]
  )
}
