import { StringDate, StringDateTime, TimeObject } from '@/models/shared.model'
import { isSameDay, Locale, minutesToMilliseconds } from 'date-fns'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import format from 'date-fns/format'
import formatDuration from 'date-fns/formatDuration'
import intervalToDuration from 'date-fns/intervalToDuration'
import { enUS, et, fi, ja, ru } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'
import defaultsDeep from 'lodash/defaultsDeep'
import { ComputedRef, isRef } from 'vue'
import i18n from '../i18n'

/**
 * Options for Intl.DateTimeFormat that will produce following format (based on locale):
 * - et: 7. juuni 2023, 23:00
 * - en: Jun 7, 2023, 23:00
 */
export const dateAndTimeOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
}

export const shortDateAndTimeOptions: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
}

/**
 * Options for Intl.DateTimeFormat that will produce following format (based on locale):
 * - et: 7. juuni 2023
 * - en: Jun 7, 2023
 */
export const dateOnlyOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
}

export const dateNumericOptions: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
}

export const shortDateNumericOptions: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
}

export const weekdayOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
}

export const shortWeekdayOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
}

export const monthAndYearOptions: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
}

export const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
}

export function getGlobalLocale(): string {
  const i18nLocale: string | ComputedRef = i18n.global.locale
  if (isRef(i18nLocale)) {
    return i18nLocale.value as string
  } else {
    return i18nLocale as string
  }
}

export function getDateFnLocale(
  locale: string | undefined = undefined,
): Locale {
  // i18n.global.locale is a ComputedRef
  if (!locale) {
    locale = getGlobalLocale()
  }
  if (locale === 'et') return et
  if (locale === 'ru') return ru
  if (locale === 'fi') return fi
  if (locale === 'ja') return ja
  return enUS
}

export const formatCurrency = (
  value: number | bigint | string | null | undefined,
) => {
  if (value === null || value === undefined) {
    return '-'
  }

  const currencyFormatter = new Intl.NumberFormat('et', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  })
  return currencyFormatter.format(Number(value))
}

interface FormatCurrencyDecimalOptions {
  minDigits?: number
}

interface FormatCurrencyDecimalOptionsWithDefaults
  extends FormatCurrencyDecimalOptions {
  minDigits: number
}

export const formatCurrencyDecimal = (
  value: number | bigint | string,
  options?: FormatCurrencyDecimalOptions,
) => {
  options = defaultsDeep(options, {
    minDigits: 0,
  }) as FormatCurrencyDecimalOptionsWithDefaults
  const currencyFormatter = new Intl.NumberFormat('et', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: options.minDigits,
  })
  return currencyFormatter.format(Number(value))
}

export interface FormatDateTimeOptions {
  format: string
  localeKey: string
  default: string
}

export function _formatIntlDateTimeFormat(
  d: StringDate | StringDateTime | Date | null | undefined,
  options: Intl.DateTimeFormatOptions = dateAndTimeOptions,
): string {
  if (d === null || d === undefined) {
    return ''
  } else if (typeof d === 'string') {
    d = parseISO(d)
  }

  const locale: string = getGlobalLocale()
  const dateFormat = Intl.DateTimeFormat(locale, options)
  return dateFormat.format(d)
}

export function formatIntlTime(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, timeOptions)
}

export function formatIntlDateAndTime(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, dateAndTimeOptions)
}

export function formatIntlShortDateAndTime(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, shortDateAndTimeOptions)
}

export function formatIntlDateOnly(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, dateOnlyOptions)
}

export function formatIntlDateNumeric(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, dateNumericOptions)
}

export function formatIntlShortDateNumeric(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, shortDateNumericOptions)
}

export function formatIntlWeekday(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, weekdayOptions)
}

export function formatIntlShortWeekday(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, shortWeekdayOptions)
}

export function formatIntlMonthAndYear(
  d: StringDate | StringDateTime | Date | null | undefined,
): string {
  return _formatIntlDateTimeFormat(d, monthAndYearOptions)
}

/**
 * Method for formatting dates. Handles parsing and not existing values.
 *
 * This should be the new go to method for formatting dates where possible.
 * @param d Date to be formatted. Can be both Date and string. Null and undefined will return defualt value.
 * @param options Configuration variables for input and output:
 *                  - format: controls output format. Defaults to "yyyy-MM-dd".
 *                  - localeKey: controls output locale. Defaults to current locale.
 *                  - default: controls output in case of invalid input like null. Defaults to empty string.
 * @returns
 */
export function formatDateTime(
  d: StringDate | StringDateTime | Date | null | undefined,
  options: Partial<FormatDateTimeOptions> = {},
): string {
  if (d === null || d === undefined) {
    return options.default || ''
  } else if (typeof d === 'string') {
    d = parseISO(d)
  }

  const outputFormat = options.format || 'yyyy-MM-dd'
  const locale = getDateFnLocale(options.localeKey)
  return format(d, outputFormat, { locale })
}

/**
 * Utility method to format date or equivalent string as date and time format that server expects.
 *
 * @param d Value we want to formatted in specific date format.
 * @returns 'yyyy-MM-dd HH:mm' date string or original value if format failed.
 */
export function formatServerDateTime(
  d: StringDate | StringDateTime | Date | null | undefined,
): StringDate | StringDateTime | null {
  return (
    formatDateTime(d, {
      format: 'yyyy-MM-dd HH:mm',
      default: '',
    }) || null
  )
}

/**
 * Utility method to format date or equivalent string as date which server expects.
 *
 * @param d Value we want to formatted in specific date format.
 * @returns 'yyyy-MM-dd' date string or original value if format failed.
 */
export function formatServerDate(
  d: StringDate | StringDateTime | Date | null | undefined,
): StringDate | StringDateTime | null {
  return (
    formatDateTime(d, {
      format: 'yyyy-MM-dd',
      default: '',
    }) || null
  )
}

export function formatDurationHoursAndMinutes(
  start: StringDate | StringDateTime | Date,
  end: StringDate | StringDateTime | Date,
): string {
  // TODO: Convert years, months, ... to hours
  if (typeof start === 'string') {
    start = parseISO(start)
  }
  if (typeof end === 'string') {
    end = parseISO(end)
  }
  const duration = intervalToDuration({ start, end })

  return formatDuration(duration, {
    locale: getDateFnLocale(),
  })
}

export function formatPeriodNumberOfNights(
  start: StringDate | StringDateTime | Date,
  end: StringDate | StringDateTime | Date,
): string {
  // TODO: Convert years, months, ... to hours
  if (typeof start === 'string') {
    start = parseISO(start)
  }
  if (typeof end === 'string') {
    end = parseISO(end)
  }

  const numberOfNights = differenceInCalendarDays(end, start)
  return i18n.global.t('dynamicNightWithCount', { count: numberOfNights })
}

export function formatMinutesToDuration(minutes: number) {
  const initialDate = new Date()
  initialDate.setSeconds(0, 0)
  const secondDate = new Date(initialDate.valueOf())
  secondDate.setTime(initialDate.getTime() + minutesToMilliseconds(minutes))
  return formatDurationHoursAndMinutes(initialDate, secondDate)
}

export function formatWithLocale(
  date: StringDate | StringDateTime | Date,
  outputFormat: string,
): string {
  if (typeof date === 'string') {
    date = parseISO(date)
  }

  return format(date, outputFormat, {
    locale: getDateFnLocale(),
  })
}

export function formatStringTimeToTimeObject(time: string | null) {
  if (!time) {
    return null
  }
  const timeList = time.split(':')
  return {
    hours: Number(timeList[0]),
    minutes: Number(timeList[1]),
    seconds: Number(timeList[2]),
  }
}

export function calculateHourLater(time: string | null) {
  const timeObject = formatStringTimeToTimeObject(time)
  if (!timeObject) {
    return null
  }
  timeObject.hours += 1
  return formatTimeObjectToStringTime(timeObject, true)
}

export function formatTimeObjectToStringTime(
  time: TimeObject,
  onlyHourMin: boolean,
) {
  let hours: string = time.hours.toString()
  if (time.hours < 10) {
    hours = '0' + hours
  }
  let minutes: string = time.minutes.toString()
  if (time.minutes < 10) {
    minutes = '0' + minutes
  }
  let seconds: string = time.seconds.toString()
  if (time.seconds < 10) {
    seconds = '0' + seconds
  }
  return onlyHourMin ? `${hours}:${minutes}` : `${hours}:${minutes}:${seconds}`
}

export function formatSameDay(
  start: StringDate | StringDateTime | Date,
  end: StringDate | StringDateTime | Date,
): string {
  if (typeof start === 'string') {
    start = parseISO(start)
  }
  if (typeof end === 'string') {
    end = parseISO(end)
  }

  if (isSameDay(start, end)) {
    return `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`
  } else {
    return `${formatIntlDateAndTime(start)} - ${formatIntlDateAndTime(end)}`
  }
}

export function formatDateToTimePicker(date: Date) {
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  }
}
