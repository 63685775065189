import i18n from '@/i18n'
import { GenericReportSettings } from '@/models/reports/genericReportComponent.model'
import {
  PartnersReport,
  PartnersReportCompanyData,
} from '@/models/reports/partnersReport'
import { formatCurrencyDecimal } from '@/utils/formatters'

const { t } = i18n.global

export const getTabTranslation = () => t('report.tabs.partnersReport')

// While string labels are supported, outside of components we need to use functions to keep them reactive.
export const genericReportSettings: GenericReportSettings = {
  showSectionFooter: true,
  showTableFooter: true,
  columns: [
    {
      // No such key in companies, but the data exists on section level.
      key: 'company',
      header: {
        label: () => t('serviceProvider'),
      },
      cell: {
        parseValue: ({ section }) => {
          return (section?.companyData as PartnersReportCompanyData)?.company
            ?.name
        },
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const companyName = (
            section?.companyData as PartnersReportCompanyData
          )?.company?.name as string

          return t('dynamicNameTotal', { name: companyName })
        },
      },
      tableFooter: {
        parseValue: () => t('total.total'),
        contentClass: 'uppercase',
      },
    },
    {
      key: 'payout_to',
      header: {
        label: () => t('report.partners.header.payoutTo'),
      },
    },
    {
      key: 'type_label',
      header: {
        label: () => t('type'),
      },
    },
    {
      key: 'service',
      header: {
        label: () => t('service'),
      },
    },
    {
      key: 'payout_amount',
      header: {
        label: () => t('report.partners.header.payoutAmount'),
      },
      cell: {
        parseValue: ({ value }) =>
          formatCurrencyDecimal(value as string, { minDigits: 2 }),
      },
      sectionFooter: {
        parseValue: ({ section }) => {
          const companyData = section?.companyData as PartnersReportCompanyData

          return formatCurrencyDecimal(companyData?.payout_amount, {
            minDigits: 2,
          })
        },
      },
      tableFooter: {
        parseValue: ({ data }) => {
          const partnersReport = data?.partnersReport as PartnersReport
          const payoutAmount: string | number =
            partnersReport?.payout_amount ?? 0

          return formatCurrencyDecimal(payoutAmount, {
            minDigits: 2,
          })
        },
      },
    },
  ],
}
