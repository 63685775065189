export default {
  "CheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
  "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "SWIFT/BUC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWIFT/BUC"])},
  "Search for address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi osoite"])},
  "aboutTheExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää tietoa"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksyä"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty"])},
  "access": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääsy"])},
    "byCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autolla"])},
    "byPublicTransport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkisella kulkuneuvolla"])},
    "byTourBus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retkibussilla"])},
    "isHardSurfacedRoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kovapäällysteinen tie"])},
    "isSignPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opaspylväät tai liikennemerkit"])},
    "onFoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalan"])}
  },
  "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilit"])},
  "activation": {
    "activateUserError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjän aktivointi epäonnistui"])},
    "activateUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä aktivoitu onnistuneesti"])},
    "invalidActivationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen aktivointikoodi"])}
  },
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätä"])},
  "addAnotherDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää alennus"])},
  "addAnotherHousingUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää asuntoyksikkö"])},
  "addAnotherPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää toinen jakso"])},
  "addAnotherPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää toinen hinta"])},
  "addAnotherUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää toinen käyttäjä"])},
  "addAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää liitteitä"])},
  "addBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää yritys"])},
  "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi"])},
  "addNewBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi yritys"])},
  "addNewClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi asiakas"])},
  "addNewResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi resurssi"])},
  "addNewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi tehtävä"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ostoskoriin"])},
  "addToCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätty ostoskoriin"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätty"])},
  "addedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätty päivämäärä"])},
  "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäinformaatio"])},
  "additionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäpalvelu/tuote"])},
  "additionalServiceDuplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiointi onnistui"])},
  "additionalServiceDuplicationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monistus epäonnistui"])},
  "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäpalvelut/tuotteet"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoite"])},
  "address_": {
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakennus"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lääni"])},
    "farmApartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatila/asunto"])},
    "villageSuburbTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kylä/esikaupunki/kaupunki"])}
  },
  "admin": {
    "companyListPage": {
      "filters": {
        "showTestCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä testipalveluntarjoajat"])}
      },
      "headers": {
        "isTestCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testaa palveluntarjoajia"])},
        "lastUserLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeinen kirjautuminen"])},
        "publishedServicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaistu myyntiartikkeleita"])},
        "servicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeleita"])},
        "usersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])}
      },
      "updateIsTestCompanyErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Palveluntarjoajan \"", _interpolate(_named("company")), "\" testitilan päivitys epäonnistui:"])},
      "updateIsTestCompanySuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Palveluntarjoajan \"", _interpolate(_named("company")), "\" testitilan päivitys onnistui."])}
    },
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "postponedRefundsPage": {
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luontipäivämäärä"])},
      "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritä uudelleen"])},
      "retryRefundErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisinmaksu epäonnistui"])},
      "retryRefundSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisinmaksu onnistui"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyvitykset lykätty"])}
    },
    "tabs": {
      "commissionReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komission kertomus"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talletusraportti"])},
      "providerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palveluntarjoajat"])}
    }
  },
  "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikuiset"])},
  "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelut"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])},
  "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneisto"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkisto"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma?"])},
  "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saapuminen"])},
  "attendants:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelijoiden määrä:"])},
  "automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaattisesti"])},
  "availableTickets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("maxGroupSize")), " lippuja jäljellä"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin"])},
  "baseMultimedia": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit lisätä, nimetä, muuttaa näyttöjärjestystä, poistaa kuvia."])}
  },
  "basicInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perustiedot"])},
  "bed_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sänky"])},
  "beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sängyt"])},
  "bedsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sänky(t)"])},
  "beneficialOwners": {
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää edunsaaja"])},
    "beneficialOwners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todelliset omistajat"])},
    "beneficialOwnersLocationInAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkempia tietoja tosiasiallisista edunsaajista löytyy seuraavan lain 9 §:stä"])},
    "linkToAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viron rahanpesun ja terrorismin rahoituksen torjuntalaki"])},
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todellisia omistajia ei ole määritelty."])},
    "whyWeCollectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery kerää tietoja palveluntarjoajien tosiasiallisista omistajista paikallisten lakien ja tapahtumakäsittelijöidemme vaatimusten mukaisesti."])}
  },
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskutus"])},
  "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syntymäpäivä"])},
  "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjanpito"])},
  "book_keeping": {
    "goToTrigonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry Trigon-kirjanpitoohjelmaan"])}
  },
  "bookingCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaus luotu onnistuneesti"])},
  "bookingCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varauksen luominen epäonnistui"])},
  "bookingForm": {
    "buttons": {
      "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää varaus"])}
    },
    "clientSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakastiedot"])}
    },
    "editExistingClientAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet päivittämässä olemassa olevan asiakkaan yhteystietoja uuden luomisen sijaan.\n\nUuden asiakkaan luomiseksi sinun on poistettava olemassa oleva asiakas valinnasta"])},
    "fields": {
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesto"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieraiden lukumäärä"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä"])},
      "timeslotDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varauksen kesto"])},
      "timeslotStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaus alkaa"])},
      "timeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aika"])}
    },
    "loadingDatePickerTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan saatavuutta"])},
    "noTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vapaita aikoja ei löytynyt"])},
    "removeClientLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista asiakas"])},
    "serviceSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli"])}
    },
    "timeslotsNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse myyntiartikkeli ja sitten päivämäärä nähdäksesi vapaat aikavälit pdalvelu ja sitten päivämäärä nähdäksesi vapaat ajat"])}
  },
  "bookingIntegration": {
    "activateConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoi yhteys"])},
    "activateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoi huoneyhteydet"])},
    "bookingAgreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ymmärrän ja hyväksyn integraation rajoitukset ja vaikutuksen Booking.com-kanavaan"])},
    "bookingProperty": {
      "checkPropertyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä yhteyden tila"])},
      "refreshRoomRatesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä huoneet ja hintasuunnitelmat"])}
    },
    "cancelConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta yhteys"])},
    "cancelRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta huoneyhteydet"])},
    "closedRestrictionsCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen tarkistanut suljetut rajoitukseni Booking.comista"])},
    "completedErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrointi Booking.comiin epäonnistui. \nPeruuta yhteydet ja yritä uudelleen tai ota yhteyttä tukeemme"])},
    "completedSuccessMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Integrointi Booking.comin kanssa on suoritettu onnistuneesti. \n", _interpolate(_named("new_reservations_created")), " uutta varausta luotiin"])},
    "connectRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä huoneisiin"])},
    "connectToPropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä omaisuuteen"])},
    "connecting": {
      "activatePropertyFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt kun tarvittavat vaiheet Booking.comissa on tehty, on aika aloittaa yhteyden muodostaminen ja kartoittaminen puolellamme."])},
      "activatePropertySecondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensimmäinen asia on luoda yhteys kiinteistösi booking.comissa. \nTätä varten sinun tulee syöttää kiinteistön tunnuksesi booking.com-sivustosta ja painaa \"Aktivoi yhteys\"."])},
      "activatePropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys omaisuuteen"])},
      "activateRoomsFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun yhteys on aktivoitu, on aika kartoittaa kiinteistösi huoneet vastaaviin asuntoihinsa Touringeryssa. \nAsuntoyhteyksien aktivoimiseksi sinun tulee kartoittaa kaikki listatut huoneet booking.comista"])},
      "activateRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneiden kartoitus"])},
      "activeConnectionFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun huoneet on kartoitettu ja yhdistetty onnistuneesti, Touringery alkaa synkronoida saatavuus-, hinta- ja rajoitustiedot booking.com-sivustolle. \nSynkronoinnin nopeus riippuu kokonaisintegraatiotoiminnasta ja kaikkien yhteyksien synkronoimiseen vaadittavien päivitysten määrästä."])},
      "activeConnectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen yhteys"])},
      "closedRestrictionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olemassa olevat suljetut rajoitukset on poistettava ennen integroinnin määrittämistä. \nTämä on välttämätöntä, koska sovellusliittymä ei mahdollista aiempien suljettujen rajoitusten poistamista, eikä niitä voida poistaa Booking.com-kalenterin kautta, kun yhteyspalveluntarjoaja on aktivoitu."])},
      "closedRestrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suljetut rajoitukset"])},
      "connectToConnectivityProviderLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asennus ja työskentely yhteydentarjoajan kanssa"])},
      "connectivityProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensimmäinen asia, joka on tehtävä, on muodostaa yhteys tuettuun yhteyspalvelun tarjoajaamme osoitteessa Booking.com."])},
      "connectivityProviderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyden tarjoaja"])},
      "connectivityProviderZodomusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuetun yhteyspalveluntarjoajan nimi on \"Zodomus\"."])},
      "connectivityTutorialLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä varten tarvittavat toimenpiteet esitellään seuraavassa Booking.comin artikkelissa"])},
      "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä integraatiota varten odotamme, että sinulla on Booking.com-kiinteistö, jossa on huoneet ja huonehinnat."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka muodostaa yhteys"])}
    },
    "deleteConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista yhteys"])},
    "description": {
      "centralizedToTouringeryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämän integroinnin avulla voit hallita kiinteistösi saatavuutta, hintaa ja rajoituksia Touringeryn kautta."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus"])}
    },
    "housingHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse luettelosta vastaava Touringery-asunto"])},
    "limitations": {
      "bookingReservationsFunctionalityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.comissa tehdyt varaukset näkyvät Touringeryn kalenterissa. \nOn mahdollista nähdä joitain perusasiakkaita tietoja. \nMyös sähköpostimalli ja sisäänkirjautumistoiminto ovat voimassa."])},
      "durationPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestoon perustuvia hintamuutoksia ei synkronoida Booking.comin kanssa. \nNe vaikuttavat vain Touringeryn hintoihin."])},
      "initialConnectionUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suurin määrä päivityksiä vaaditaan ensimmäisen yhteyden yhteydessä, koska kaikki hinnat ja saatavuus on synkronoitava siinä vaiheessa. \nPäivitysten määrä riippuu liitettyjen asuntojesi hinnoittelujaksojen monimutkaisuudesta ja niiden Touringeryssä olevien varausten määrästä."])},
      "numberOfUpdatesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitysten määrä"])},
      "priceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hinnat"])},
      "reservationsLimitedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uudet ja nykyiset tulevat varaukset synkronoidaan Booking.comista Touringeryyn, mutta niiden toiminnallisuus järjestelmässämme on rajoitettu."])},
      "reservationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaukset"])},
      "standardPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuntomme hinnoittelujaksoissa määritellyt täydet hinnat asetetaan Booking.comin huonehintojen vakiohinnaksi."])},
      "standardPricingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com tarjoaa useita hinnoittelutyyppejä, joista tuemme vain vakiohinnoittelumallia."])},
      "syncBookingReservationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toisaalta Booking.comissa tehtyjä varauksia koskevat päivitykset käsitellään erikseen, eikä jaetun jonon pituus vaikuta niihin."])},
      "syncOnDemandText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sen jälkeen synkronointi suoritetaan, kun Touringeryssä on tehty asiaankuuluvia muutoksia. \nNäitä ovat muun muassa muutokset asumisjaksoissa, niihin liittyvät mieltymykset, niihin liittyvät varaukset ja sulkemiset."])},
      "syncingFrequencyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutosten synkronointi Touringerysta Booking.comiin ei tapahdu hetkessä. \nKaikki päivitykset menevät jaettuun jonoon, mikä tarkoittaa, että Booking.com-sivustosi päivittämiseen kuluva aika riippuu päivitystesi sijainnista siinä."])},
      "syncingTimeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä taajuus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rajoitukset"])}
    },
    "propertyActivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe ominaisuuden aktivoinnissa"])},
    "propertyActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omaisuus aktivoitu onnistuneesti"])},
    "propertyCancellationnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe yhteyden katkaisemisessa"])},
    "propertyCancellationnSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys peruutettu onnistuneesti"])},
    "propertyHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita integrointi Booking.comiin lisäämällä ensin omaisuuden tunnuksesi varaukseen. \nLöydät sen booking.com-järjestelmänvalvojan sivuston otsikosta Booking-logon vieressä."])},
    "propertyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteistön tunnus"])},
    "roomIdLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com-huoneen tunnus"])},
    "roomsActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuntoliitännät aktivoitu onnistuneesti"])},
    "roomsctivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe kotelon liitäntöjen aktivoinnissa"])},
    "statusActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivoitu"])},
    "statusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
    "statusInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epäaktiivinen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com-kanavan johtaja"])},
    "updateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä huoneyhteydet"])},
    "zodomusConnectedCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen asettanut Zodomuksen yhteydentarjoajaksi Booking.comissa"])}
  },
  "bookingsCalendar": {
    "onlyTransactionsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vain tapahtumien yhteydessä"])},
    "reservationDetails": {
      "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieras"])},
      "openDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoin varaus"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varauksen alkuperä"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erikoispyynnöt"])},
      "seeAllBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki varaukset tältä vieraalta"])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonaishinta"])}
    }
  },
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakennus"])},
  "businessDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen kuvaus"])},
  "businessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritystiedot"])},
  "businessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen nimi"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenteri"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuttaa"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta varaus"])},
  "cancelMessageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tässä voit kuvata peruutuksen syyn"])},
  "cancelOrder": {
    "cancelServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe tilauksen peruutuksen aikana."])},
    "cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus peruutettu onnistuneesti"])},
    "cannotCancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä tilausta ei voi peruuttaa tällä hetkellä"])},
    "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti peruuttaa tämän tilauksen?"])},
    "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta tilaus"])},
    "removeOrderItemConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän tuotteen tilauksesta?"])},
    "removeOrderItemConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista tilauksesta"])},
    "removeOrderItemServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe poistettaessa tuotetta tilauksesta"])},
    "removeOrderItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauksesta poistettu onnistuneesti"])}
  },
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasiteetti"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kärry"])},
  "cartOverview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilausnäkymä tilaukselle #", _interpolate(_named("referenceNumber"))])},
  "cartPage": {
    "cartConfirmErrorAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe ostoskorin vahvistuksen aikana"])},
    "cartConfirmSuccessAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostoskori vahvistettiin onnistuneesti."])},
    "cartConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ostoskori on vahvistettu ja se vanhenee ", _interpolate(_named("expiresAt")), "."])},
    "clientConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Asiakastiedot on vahvistettu ostoskorin lisäksi ja ne vanhenevat ", _interpolate(_named("expiresAt")), "."])},
    "noItemsInCartText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostoskärry on tyhjä"])},
    "orderStatusCancelledAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus on peruutettu."])},
    "orderStatusPaidAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus on maksettu. \nLataa liput."])},
    "orderStatusPaidAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus on maksettu."])},
    "orderStatusPendingAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauppa on suoritettu onnistuneesti. \nlopullinen vahvistus toimitetaan sähköpostiin viiveellä. \nLataa liput."])},
    "orderStatusPendingAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauppa on suoritettu onnistuneesti. \nlopullinen vahvistus toimitetaan sähköpostiin viiveellä."])},
    "orderStatusTimedOutAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus on vanhentunut."])}
  },
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luokat"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
  "ceoOrBoardMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toimitusjohtaja tai hallituksen jäsen"])},
  "changeHousingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta majoitustietoja"])},
  "channel": {
    "activateChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteys kanavaan onnistui."])},
    "activateRoomsSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteistön huoneiden yhdistäminen onnistui."])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kanava"])}
  },
  "channelManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavan johtaja"])},
  "channels": {
    "activateChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyden muodostaminen kanavaan epäonnistui."])},
    "activatePropertyRoomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhdistä huoneet"])},
    "activateRoomsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteistön huoneiden yhdistäminen epäonnistui."])},
    "bookingActivation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi Booking.com-kanava"])}
    },
    "bookingProperty": {
      "checkPropertyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyden tilan päivittäminen epäonnistui."])},
      "checkPropertySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyden tila päivitetty."])},
      "refreshRoomRatesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteistöjen huoneiden ja niihin liittyvien hintasuunnitelmien päivittäminen epäonnistui."])},
      "refreshRoomRatesSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onnistuneesti päivitetty kiinteistöhuoneet ja niihin liittyvät hintasuunnitelmat."])}
    },
    "bookingPropertyChannelTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kiinteistö ", _interpolate(_named("property"))])},
    "confirmPropertyDeactivationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän kanavan käytöstä?"])},
    "connectPropertyChannelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kytkeä"])},
    "connectRoomsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jotta voit yhdistää Booking.comin huoneet kiinteistösi, sinun on valittava niiden vakiohintasuunnitelma ja vastaava asuntoartikkeli."])},
    "deleteChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavan poistaminen epäonnistui."])},
    "deleteChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavan poistaminen onnistui."])},
    "fetchPropertyChannelDetailsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavan tietojen lataaminen epäonnistui."])},
    "header": {
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteistön tunnus"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavia ei löytynyt."])},
    "noPropertyRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteistöstä ei löytynyt huoneita."])},
    "propertyConnectionNeededForRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneet näytetään, kun ne on yhdistetty majoituspaikkaan."])},
    "ratePlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintasuunnitelma"])},
    "roomHousingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitun asuntotuotteen asuntoyksiköiden määrän tulee olla yhtä suuri tai pienempi kuin huoneen. \nMuuten virheitä heitetään yritettäessä synkronoida korkeampi saatavuus kuin Booking.com hyväksyy."])},
    "roomRatePlanInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintasuunnitelma, joka tulee päivittää Touringeryn tietojen perusteella. \nVain vakiohintaisia ​​suunnitelmia tuetaan."])},
    "roomTitleWithNameAndId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen tunnus"])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epäaktiivinen"])},
      "PROPERTY_ACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osittain kytketty"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanavat"])}
  },
  "checkAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista saatavuus"])},
  "checkIn": {
    "aboutClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietoja asiakkaasta"])},
    "aboutClientStaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos varaus on ostettu muille, älä valitse seuraavaa vaihtoehtoa. \nJos sinulla on varauksia usealle huoneelle, valitse seuraava vaihtoehto vain varaukselle, jossa aiot yöpyä."])},
    "aboutTravelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täytä lomake kaikille kanssasi matkustaville"])},
    "aboutTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkatiedot"])},
    "aboutYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietosi"])},
    "addTravelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää matkakumppani"])},
    "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäkysymys"])},
    "additionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäkysymyksiä"])},
    "additionalsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit muotoilla lisäkysymyksiä, jotka lisätään valittujen palvelujen lähtöselvityslomakkeeseen. \nVastaus on tekstikenttä, jossa on enintään 1000 merkkiä"])},
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikuinen"])},
    "ageGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikäryhmä"])},
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma?"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun muutat kysymystä, muista, että se muuttuu takautuvasti myös jo tallennettuihin kysymyksiin"])},
    "checkInDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista tiedot"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapsi"])},
    "confirmCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistan, että antamani tiedot ovat oikein"])},
    "detailsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityiskohdat matkastasi"])},
    "downloadForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa lomake"])},
    "estimatedCheckInTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioitu sisäänkirjautumisaika"])},
    "groupSizeHasChanged": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vierailijoiden määrä on muuttunut, ", _interpolate(_named("guestsPlural")), " on määritetty alkuperäisen ", _interpolate(_named("originalCount")), " sijaan. Tämä voi johtaa muutoksiin palvelussa"])},
    "hoursRange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aikana ", _interpolate(_named("check_in_hours_range"))])},
    "housingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunnon yksityiskohdat"])},
    "isClientStayingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas oleskelee tässä varauksessa"])},
    "isForConferenceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konferenssiin osallistuminen"])},
    "isNotSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä tiedot ja tallenna sisäänkirjautumislomake"])},
    "isSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomake toimitetaan"])},
    "isWrongReservationTypeAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomaketta ei voi lähettää nykyisellä varauksen tilalla"])},
    "openCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry ostoskoriin"])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkan tarkoitus"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe tietojen tallentamisessa"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietojen tallennus onnistui"])},
    "serviceSelectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse myyntiartikkeli nähdäksesi asiaankuuluvat kysymykset"])},
    "statusSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetty"])},
    "statusUnSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetty peruttu"])},
    "tavelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkakumppani"])},
    "tavelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkakumppanit"])},
    "travelData": {
      "clientHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas"])}
    },
    "travelPurpose": {
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut"])}
    },
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loma"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehdä työtä"])}
  },
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapset"])},
  "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kategoria"])},
  "chooseSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse alaluokka"])},
  "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kansalaisuus"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaupunki/kunta"])},
  "cityRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kylä/kaupunki/alue"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia selvä"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asiakas"])},
  "clientBookings": {
    "tableHeaders": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesto"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieraita"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyynnöt"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähde"])}
    }
  },
  "clientCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakas luotiin onnistuneesti"])},
  "clientCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan luominen epäonnistui"])},
  "clientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakastiedot"])},
  "clientSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakastiedot tallennetaan"])},
  "clientSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakastietojen tallentaminen epäonnistui"])},
  "clientTab": {
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskutus"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaukset"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaute"])}
  },
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaat"])},
  "clients_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaan nimi"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suljettu"])},
  "color": {
    "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sininen"])},
    "blue_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinivihreä"])},
    "blue_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violetti"])},
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vihreä"])},
    "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oranssi"])},
    "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violetti"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punainen"])},
    "red_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirkkaanpunainen"])},
    "red_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magenta"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väri"])},
    "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keltainen"])},
    "yellow_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keltaisen vihreä"])},
    "yellow_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keltainen"])}
  },
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentti"])},
  "commentUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentin lisääminen epäonnistui"])},
  "commentUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentti lisätty!"])},
  "companyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen luominen onnistui"])},
  "companyCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen luominen epäonnistui"])},
  "companyLocation": {
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän sijainnin?"])},
    "createLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainnin luominen epäonnistui"])},
    "createLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo sijainti"])},
    "createLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainti luotu onnistuneesti"])},
    "deleteLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainnin poistaminen epäonnistui"])},
    "deleteLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainti poistettu onnistuneesti"])},
    "fetchLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainnin lataaminen epäonnistui"])},
    "generalBlockTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleistä tietoa"])},
    "updateLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainnin päivittäminen epäonnistui"])},
    "updateLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä sijainti"])},
    "updateLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainti päivitetty onnistuneesti"])}
  },
  "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen logo"])},
  "companyNotifications": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen ilmoitukset"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä automaattiset sähköposti-ilmoitukset, jotka lähetetään yrityksen sähköpostiin"])},
    "notification": {
      "eventCancelDelayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuman peruutus myöhässä"])},
      "eventCancelDelayedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään aina, kun automaattinen tapahtuman peruutus on tilapäisesti viivästynyt. \nViivästyksen syynä voi olla odottava maksu."])},
      "eventCancelPassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtumaan osallistujia on saavutettu"])},
      "eventCancelPassedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään, kun tapahtuman vähimmäisosallistujamäärä on saavutettu automaattista peruutusta tarkistettaessa. \nTämä tarkoittaa, että automaattinen peruutus poistetaan käytöstä tietyn tapahtuman osalta."])},
      "eventCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma peruttu"])},
      "eventCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään aina, kun tapahtuma peruutetaan automaattisesti"])},
      "orderPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi järjestys"])},
      "orderPaidDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään aina, kun palveluntarjoajan myyntituotteet on maksettu, eli syntyy uusi tilaus"])},
      "partnerInviteResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaus kumppanuuskutsuun"])},
      "partnerInviteResponseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään, kun kumppanuuskutsusi yritykseen on hyväksytty"])},
      "partnerPayoutChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuusmaksuprosentin muutos"])},
      "partnerPayoutChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään, kun kumppani on muuttanut maksuprosenttiaan"])},
      "partnerStatusChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuuden tilan muutos"])},
      "partnerStatusChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään, kun kumppanuustila on muuttunut"])},
      "reservationCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaus peruttu"])},
      "reservationCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetään aina, kun asiakas on peruuttanut varauksensa"])}
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähettää sähköpostia"])}
  },
  "companyRole": {
    "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää rooli"])},
    "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestelmänvalvojan roolia ei voi muuttaa tai poistaa. \nYrityksessä tulee olla vähintään yksi järjestelmänvalvojan rooli"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukea"])},
    "removeRoleConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän roolin?"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittää"])}
  },
  "companyRoles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
    "permissios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttöoikeudet"])}
  },
  "compucash": {
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashin asiakastunnus"])},
    "clientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashin asiakas salaisuus"])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietoja, joita tarvitaan Compucash-integraatiota varten, jotta tuotteet voidaan synkronoida Compucashista Touringeryyn. Saadaksesi tarvittavat tiedot, ota yhteyttä Compucashiin."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash-integraation asetukset"])}
  },
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistaa"])},
  "confirmAndPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksaa"])},
  "confirmDialog": {
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma?"])}
  },
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistus"])},
  "confirmationEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaussähköposti lähetetty onnistuneesti"])},
  "confirmationEmailSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostin lähettämisessä ilmeni ongelmia"])},
  "contactServiceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteystiedot"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota meihin yhteyttä"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkaa"])},
  "coordinates": {
    "DD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD (desimaaliaste)"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinaatit"])}
  },
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioitu!"])},
  "copyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi URL-osoite"])},
  "countdown": {
    "inPast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menneisyydessä"])}
  },
  "countryOfResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuinmaa"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lääni"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luoda"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo tili"])},
  "createBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo joukko"])},
  "createNewResourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi ryhmä"])},
  "createServiceDraftError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin luonnoksen luominen epäonnistui"])},
  "currentCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nykyinen"])},
  "customerRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakasarvio"])},
  "dataIsSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedot tallennetaan"])},
  "dataSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietojen tallennus epäonnistui"])},
  "dataSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietojen tallennus onnistui"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärä"])},
  "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivämäärät"])},
  "dayNoHourOption": {
    "fromTheStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päivän aloitus"])},
    "untilEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päivän loppu"])}
  },
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päivää"])},
  "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaraja"])},
  "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viive"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistaa"])},
  "deleteClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista asiakas"])},
  "deleteResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista resurssi"])},
  "deleteTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista tehtävä"])},
  "departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähtöpäivä"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityiskohdat"])},
  "disableTrackList": {
    "addItemModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi suljin"])}
  },
  "disabledTrackItem": {
    "allExcludedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki"])},
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletko varma?"])},
    "deleteTrackErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkemisen poistaminen epäonnistui."])},
    "deleteTrackSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkeminen poistettu onnistuneesti."])},
    "exclusionTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkemisen tyyppi"])},
    "form": {
      "endLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loppu (poissuljettu)"])},
      "startLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita (sisältyy)"])}
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättäminen"])},
    "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])},
    "trackType": {
      "fullDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje kaikki vieraat"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje kaikki yksiköt"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje kaikki"])}
      },
      "quantityDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje joitakin vieraita"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje jotkin yksiköt"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje muutama"])}
      }
    },
    "updateItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkemisen päivittäminen epäonnistui."])},
    "updateItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkeminen päivitetty onnistuneesti."])},
    "updateModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä sulkeminen"])}
  },
  "disabledTrackList": {
    "addItemButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää sulku"])},
    "createItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkemisen luominen epäonnistui."])},
    "createItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkeminen onnistui."])},
    "experience": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieraita"])}
    },
    "housing": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunnot"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sulkuja."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulkemiset"])}
  },
  "discount": {
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikä"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alennus"])},
    "inDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivissä"])},
    "inHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunteissa"])},
    "numOfDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka monta päivää"])},
    "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka monta vierasta"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta"])},
    "untilDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asti (päivien lukumäärä)"])},
    "untilGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asti (vieraiden määrä)"])},
    "untilSumLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(kuinka paljon)"])},
    "untilYearLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(kuinka vanha)"])}
  },
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alennukset"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etäisyys"])},
  "distanceToStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etäisyys pysähtymiseen"])},
  "documentExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakirjan viimeinen voimassaolopäivä"])},
  "documentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakirjan numero"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehty"])},
  "downloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa lasku"])},
  "downloadTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa lippu"])},
  "downloadTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa liput"])},
  "dragHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tai vedä tähän"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesto"])},
  "durationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesto manuaalisesti"])},
  "durationPicker": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesto"])}
  },
  "durations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestoajat"])},
  "dynamicNameTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " yhteensä"])},
  "dynamicNight": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["yötä"]), _normalize(["yö"]), _normalize(["yötä"])])},
  "dynamicNightWithCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " yö"]), _normalize([_interpolate(_named("count")), " yö"]), _normalize([_interpolate(_named("count")), " yötä"])])},
  "editCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihdettu onnistuneesti"])},
  "editUsers": {
    "changeRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda käyttäjän roolia"])},
    "editUsersLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa käyttäjää"])},
    "goToRolesView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry roolinäkymään"])},
    "resendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kutsu uudelleen"])}
  },
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
  "emailNotificationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikataulutetut sähköpostit"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loppu"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättymispäivä"])},
  "erfLogo": {
    "altText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Euroopan aluekehitysrahaston logo"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma"])},
  "eventCalendarDetails": {
    "eventReservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaukset"])},
    "noEventPeriodsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tapahtumia."])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei varauksia."])}
  },
  "eventCancelAtInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tapahtuu, jos vähintään ", _interpolate(_named("minCapacity")), " lippua myydään yhteensä ", _interpolate(_named("cancelDuration")), " jälkeen."])},
  "eventCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma luotiin onnistuneesti"])},
  "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tämä vaihtoehto, jos haluat luoda uuden tapahtuman. \nTapahtuma on kertaluonteinen myyntiartikkeli, joka tapahtuu tiettynä ajankohtana."])},
  "eventError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuman luominen epäonnistui"])},
  "eventOrderItemRefundableUntil": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Täysin palautettavissa ", _interpolate(_named("until")), " asti (2 päivää ennen tapahtumaa). \nTämän päivämäärän jälkeen tehdystä peruutuksesta ei anneta hyvitystä."])},
  "eventResultCard": {
    "priceForGroupText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hinta: ", _interpolate(_named("groupSize"))])}
  },
  "eventService": {
    "eventIsCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma on peruttu."])},
    "eventSalesEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuman lipunmyynti on päättynyt."])}
  },
  "eventUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuman päivitys epäonnistui"])},
  "eventUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuman päivitys onnistui"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtumat"])},
  "eventsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtumat luotu onnistuneesti"])},
  "eventsCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtumien luominen epäonnistui"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokea"])},
  "experienceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tämä vaihtoehto, jos haluat luoda uuden kokemuksen."])},
  "experienceSearch": {
    "calendarLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan saatavuutta, tämä voi kestää jonkin aikaa."])}
  },
  "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelut"])},
  "farm/apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatila/asunto"])},
  "feedbackSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautteen lähetys onnistui"])},
  "feedbackSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautteen lähettäminen epäonnistui"])},
  "fileUploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedoston lataus epäonnistui!"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etunimi"])},
  "fixedTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteä aikaväli"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unohtuiko salasana?"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "gender": {
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nainen"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukupuoli"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mies"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut"])}
  },
  "general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleistä tietoa"])},
  "general_information_unfilled_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiset tiedot on täytettävä ja tallennettava ennen kuin voit jatkaa lomakkeen täyttämistä"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuottaa"])},
  "generatedEventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotuja tapahtumia"])},
  "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry ostoskoriin"])},
  "googleCalendar": {
    "eventModal": {
      "relatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liittyvä resurssi"])},
      "relatedService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liittyvä palvelu"])}
    },
    "settings": {
      "authError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe kirjautuessa Google-tilille"])},
      "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
      "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronoidaksesi Touringery-kalenterit Google-kalenterin kanssa, kirjaudu Google-tiliisi ja hyväksy ehdot."])},
      "modal": {
        "calendarName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenteri"])},
        "calendarType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenterin tyyppi"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka kauan ennen tapahtumaa?"])},
        "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimerkki"])},
        "exampleHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mökki"])},
        "exampleHousingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["majoitusyksiköt 1,2"])},
        "exampleTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siivous"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etunimi"])},
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko nimi"])},
        "googleCalendarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-kalenterin kuvaus"])},
        "googleCalendarEventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-kalenterin tapahtuman nimi"])},
        "googleCalendarSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-kalenterin asetukset"])},
        "googleCalendarSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-kalenterin nimi"])},
        "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän koko"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukunimi"])},
        "moneyjarEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tänään myydyt rahapurkit resurssille \"", _interpolate(_named("name")), "\": ", _interpolate(_named("quantity"))])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])},
        "resourceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattinen kalenteri Touringery-järjestelmästä resurssille \"", _interpolate(_named("name")), "\""])},
        "resourceEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": määrä ", _interpolate(_named("quantity"))])},
        "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenterin asetusten tallennus epäonnistui"])},
        "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenterin asetukset tallennettu!"])},
        "sendReminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluan muistutuksia ennen tapahtumia"])},
        "sendUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluan ilmoituksia kalenteripäivityksistä"])},
        "serviceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattinen kalenteri Touringery-järjestelmästä myyntiartikkelille \"", _interpolate(_named("name")), "\""])},
        "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin nimi"])},
        "syncActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synkronointi käytössä"])},
        "taskEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (", _interpolate(_named("serviceName")), ", ", _interpolate(_named("unitName")), ")"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa Google-kalenterin asetuksia"])},
        "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoitusyksikkö(ö)"])},
        "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odota hetki, tallennus voi kestää hetken..."])}
      },
      "table": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei aktiivinen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
        "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muistutukset"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-kalenterin asetukset"])}
    },
    "type": {
      "EVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma"])},
      "EXPERIENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelu"])},
      "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoitus"])},
      "MONEYJAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurkki"])},
      "RESOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssi"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä"])}
    }
  },
  "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän nimi"])},
  "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vieraita"])},
  "guestsCountriesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoituneiden henkilöiden jakautuminen asuinmaan mukaan"])},
  "guestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietoja vieraista"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tässä"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuntia"])},
  "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talo"])},
  "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuminen"])},
  "housingCalendar": {
    "changeReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta varausta"])},
    "goToCartView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostoskorinäkymä"])},
    "goToReservationView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varausnäkymä"])},
    "noTimelineEventsInPeriodText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei varauksia tai sulkemisia tänä aikana."])},
    "showInfo": {
      "availableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatavilla olevat yksiköt"])}
      },
      "disabledUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksiköt pois lukien"])}
      },
      "reservationsCount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varatut yksiköt"])}
      },
      "reservedAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Varattu ", "|", " Saatavilla) yksikköä"])}
      },
      "selectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä"])},
      "totalAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Yhteensä ", "|", " Saatavilla) yksikköä"])}
      },
      "totalAndUnavailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Yhteensä ", "|", " ei saatavilla) yksikköä"])}
      },
      "totalUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksiköt yhteensä"])}
      }
    },
    "unspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrittelemätön"])}
  },
  "housingCalendarDetails": {
    "availableGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatavilla"])},
    "availableHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatavilla"])},
    "closedHousingUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suljettu"])},
    "guestsPerUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieras per yksikkö"])},
    "housingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunnot"])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei varauksia."])},
    "reservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaukset"])},
    "reservedHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varattu"])},
    "totalGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki yhteensä"])},
    "totalHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki yhteensä"])}
  },
  "housingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tämä vaihtoehto, jos haluat rakentaa uuden asunnon"])},
  "housingOrderCard": {
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aikuinen"])},
    "adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aikuisia"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lapsi"])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lapset"])}
  },
  "housingOvernightEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkailijat Virosta yön yli"])},
  "housingOvernightNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkustajat muista maista yhdessä yössä"])},
  "housingRoom": {
    "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen mukavuudet"])},
    "seeAllAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki"])}
  },
  "housingSalesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoituskapasiteetti"])},
  "housingSalesTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuntojen myynti yhteensä"])},
  "housingUnit": {
    "displayAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki huoneet"])},
    "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovikoodi"])},
    "housingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunnot"])},
    "identificator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnistin"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää tämäntyyppisen majoituksen määrä ja yksilöllinen tunniste"])},
    "infoHelperDoorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovikoodia (ei pakollinen) voidaan käyttää muuttujana sähköpostimalleissa"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuntoyksikkö"])},
    "numOfUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuntojen määrä"])}
  },
  "housings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunnot"])},
  "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka monta"])},
  "howManyAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka monta teitä on?"])},
  "howToGetThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miten sinne pääsee"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "idCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID-koodi"])},
  "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunniste"])},
  "insertEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää sähköposti"])},
  "invitationsSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsut lähetettiin onnistuneesti"])},
  "invitationsSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsujen lähettäminen epäonnistui"])},
  "inviteNewUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu uusia käyttäjiä"])},
  "inviteUsersForm": {
    "roleAdminInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin - voi tehdä kaiken yrityksen sisällä, jos yrityksiä on useita, niin kaikissa."])},
    "roleUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä - voi tarkastella ja muokata hänelle osoitettuja tehtäviä, kalenteria (muutoksista lähetetään ilmoitus järjestelmänvalvojalle)."])}
  },
  "inviteUsersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsutut käyttäjät rekisteröityvät Touringeryyn ja saavat sähköpostikutsun sivustolle."])},
  "invoiceAndDeliveryNotePrefixes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskun ja lähetysluettelon etuliitteet"])},
  "invoicePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laskun etuliite"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
  "languageEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englanti"])},
  "languageEt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virolainen"])},
  "languageFi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suomalainen"])},
  "languageJa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanilainen"])},
  "languageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venäjän kieli"])},
  "languageServiceGeneralBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä sovellukselle määrittämääsi kieltä, kun lisäät tietoja kenttiin."])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukunimi"])},
  "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeisin päivitys"])},
  "lastView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeinen näkymä"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan..."])},
  "loadingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainti"])},
  "locationPreferences": {
    "adviseChangeCompanyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit muuttaa yrityksen sijaintitietoja kohdassa Asetukset -> Sijainti"])},
    "cannotSaveErrorNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen sijaintia ei voi tallentaa täältä, siirry yrityksen asetuksiin"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä yrityksen sijaintia"])},
    "locationPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijaintiasetukset"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää eri palvelupiste"])},
    "warnEnterCompanyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää yrityksen sijaintitiedot kohtaan Asetukset -> Sijainti"])}
  },
  "locationPublicTransport": {
    "addTransportLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää joukkoliikenne"])},
    "distanceKmLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Km"])}
  },
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjautua ulos"])},
  "logoExplanationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo näkyy sivuvalikossa, kirjautumislomakkeessa ja rekisteröintilomakkeessa. \nEsitettäessä logon koko skaalautuu tilan mukaan, mutta maksimikorkeus on 108 pikseliä."])},
  "manageAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallitse tiliä"])},
  "markAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse tehdyksi"])},
  "markAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse tekemättä"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enimmäismäärä"])},
  "mealPlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ateriasuunnitelma"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viesti"])},
  "minAdvanceTimePastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimivarausaika etukäteen on kulunut"])},
  "minParticipantsInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cancelTime")), " vaatii vähintään ", _interpolate(_named("minGroupSize")), " myytyä lippua"])},
  "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimi"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pöytäkirja"])},
  "moneyJarCalendarDetails": {
    "noPaidOrderItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurkkeja ei ole ostettu tänä päivänä."])},
    "orderItemsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurkit"])},
    "totalUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myydyt yksiköt"])}
  },
  "moneyJarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tämä vaihtoehto, jos haluat luoda uuden palvelumaisen myyntiartikkelin, jonka avulla voit myydä tuotteita ottamatta huomioon saatavuuden rajoituksia jne."])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multimedia"])},
  "multimediaTab": {
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kuvia"])},
    "baseFileLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enintään 5 tiedostoa kerrallaan"])},
    "fetchMultimediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeleiden multimedian lataaminen epäonnistui"])},
    "fileDeleteExistingHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olemassa olevan otsikon poistaminen epäonnistui"])},
    "fileDeleteFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiedoston \"", _interpolate(_named("heading")), "\" poistaminen epäonnistui"])},
    "fileHeadingIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsikko on pakollinen."])},
    "fileHeadingTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otsikon enimmäispituus on 200 merkkiä. \nTällä hetkellä siinä on ", _interpolate(_named("length")), " merkkiä."])},
    "fileOverLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiedoston \"", _interpolate(_named("name")), "\" koko on yli 5 Mt"])},
    "fileUpdateFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiedoston \"", _interpolate(_named("heading")), "\" päivitys epäonnistui"])},
    "fileUploadFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiedoston \"", _interpolate(_named("heading")), "\" lataaminen epäonnistui"])},
    "fileUploadHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuden otsikon lataaminen epäonnistui"])},
    "fileWrongFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiedoston \"", _interpolate(_named("name")), "\" muotoa ei tueta. \nTuetut: SVG, JPG, JEPG, PNG"])},
    "filesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedostojen tallennus epäonnistui"])},
    "filesSaveFailedPartially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joidenkin tiedostojen tallentaminen epäonnistui"])},
    "filesSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedostot tallennettu onnistuneesti"])},
    "gallery": {
      "altTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Tag"])},
      "altTagHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kuvausteksti, jos kuva ei näy"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsikko"])},
      "headingHelperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kuvan otsikko. \nEnimmäispituus on 200 merkkiä."])},
      "maxSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suositeltu enimmäiskoko: 5 Mt"])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suositellut vähimmäismitat: 960 x 540 pikseliä"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galleria"])}
    },
    "instructions": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohjeet"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää houkuttelevia ja laadukkaita kuvia."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomaa, että kuvien kokoja ja mittoja koskevia suuntauksia tulee ottaa huomioon."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valokuvatiedostojen nimet tulisi mieluiten olla englanniksi."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmista, että immateriaalioikeus kuuluu sinulle ja sinulla on oikeus ladata multimedia matkailutietojärjestelmään. \nKun lisäät multimediaa, annat järjestelmänvalvojalle ilmaisen maailmanlaajuisen yksinkertaisen lisenssin käyttää lisäämiäsi tietoja."])}
    },
    "isServiceHeaderLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsikkokuva"])},
    "ticket": {
      "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kuva"])},
      "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit lisätä kuvan myyntituotteesi lippuun"])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suositellut vähimmäismitat: 150 px leveämpi sivu"])},
      "noPricesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettävää hintoja"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lippujen esikatselu"])},
      "showPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä esikatselu"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lippu"])}
    }
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
  "nameOfBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pankin nimi"])},
  "nearbyTransportStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähellä julkisen liikenteen pysäkit"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi tapahtuma"])},
  "newResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi resurssi"])},
  "newService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi myyntiartikkeli"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava"])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yö"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yöt"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei"])},
  "noAvaiableDatesInMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä kuukausi ei sisällä käytettävissä olevia päivämääriä"])},
  "noAvailabilityRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tarpeeksi saatavuutta näinä päivinä. \nKatso muut tämän palveluntarjoajan tarjoukset"])},
  "noAvailabilityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tarpeeksi saatavuutta näinä päivinä. \nKokeile eri päivämääriä"])},
  "noEventAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole tapahtumajaksoja. Tarkista suodattimet tai luo vähintään yksi piste."])},
  "noExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei olemassa olevia asiakkaita"])},
  "noOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei vaihtoehtoja"])},
  "noPermissionsForRouteAccessError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pääsy evätty! \nSinulla ei ole sivun \"", _interpolate(_named("route")), "\" katseluoikeuksia."])},
  "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei näytettäviä tuloksia"])},
  "noRightsErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pääsy evätty. \nSinulla ei ole valtuuksia suorittaa tätä toimintoa"])},
  "noSearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tuloksia tällä kyselyllä"])},
  "noTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei tehtäviä"])},
  "notAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hyväksytty"])},
  "notDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei valmis"])},
  "notReadyYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä osio on rakenteilla"])},
  "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneiden määrä"])},
  "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jatkuva"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa"])},
  "openDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa tietonäkymä"])},
  "openPartnershipDetailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaa kumppaninäkymä"])},
  "openTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoin aika"])},
  "optionSelectedLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pituus")), " vaihtoehdot valittu"])},
  "order": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus"])}
  },
  "orderClientPage": {
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen lukenut ja hyväksyn käyttöehdot"])},
    "confirmClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe asiakastietojen vahvistuksen aikana"])},
    "confirmClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakastiedot on vahvistettu"])},
    "contactInfoDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytämme tietojasi vain ottaaksemme sinuun yhteyttä tarvittaessa"])},
    "tourTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiset ehdot"])},
    "updateClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilausasiakkaan päivityksen aikana tapahtui virhe"])},
    "updateClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilausasiakkaan päivitys onnistui"])}
  },
  "orderConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilausvahvistus"])},
  "orderItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaa tuote"])},
  "orderSummary": {
    "cancellationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauksen peruutus"])},
    "refundableLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautettavissa"])},
    "refundedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyvitetty"])}
  },
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkuperä"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])},
  "overviewCalendar": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenteri"])},
    "calendarSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenterin valinta"])},
    "calendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalentereita"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivä"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleinen"])},
    "generalView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleiskatsaus"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausi"])},
    "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("eventsNr")), " lisää"])},
    "multidayEventsInTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä usean päivän tapahtumat aikajanalla"])},
    "noCalendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kalentereita"])},
    "noOrdersInPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tällä ajanjaksolla ei ole tilauksia"])},
    "orderCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilausten yleiskatsaus"])},
    "orderNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tilaus #", _interpolate(_named("referenceNumber"))])},
    "orderType": {
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suoritettu"])},
      "CONFIRMED_OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaus"])},
      "CONFIRMED_ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistettu Tilaus"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruutettu"])},
      "LOCKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukittu"])},
      "OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjous"])}
    },
    "orderView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaukset"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaukset"])},
    "otherDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut Päivät"])},
    "requests": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erityistoiveet: ", _interpolate(_named("requests"))])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kaikki"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tänään"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikko"])}
  },
  "participants": {
    "maxGroupSizeText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupSize")), "/", _interpolate(_named("maxGroupSize")), " valittu"])},
    "participantsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osallistujat"])}
  },
  "partnership": {
    "acceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy kumppanuus"])},
    "acceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos molemmat osapuolet ovat hyväksyneet kumppanuuden, myyntiartikkelisi näkyvät toistensa sivuilla. \nMyös tulojen jakotoiminto otetaan uudelleen käyttöön."])},
    "backToPartnersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki kumppanuudet"])},
    "companyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensimmäinen kumppani"])},
    "confirmAcceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista hyväksyminen"])},
    "confirmUnacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista deaktivointi"])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuuden tiedot"])},
    "partnerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toinen kumppani"])},
    "payoutPercentageExampleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimerkiksi aktiivisen kumppanuuden aikana myyntiartikkelisi näkyvät kumppanisi myyntiartikkelisivujen alaosassa heidän ja muiden kumppaneidensa lisäksi. \nKumppani saa osuutensa, kun asiakas klikkaa sivullaan myyntiartikkeliasi ja jatkaa sen tilaamista ja maksamista."])},
    "payoutPercentageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosenttiosuus maksusta, jonka yritys on valmis jakamaan kumppaninsa kanssa. \nJakaminen tapahtuu vain, kun myynti saavutetaan suoraan kumppanin myyntiartikkelien kautta."])},
    "payoutPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanin maksuosuus"])},
    "payoutPercentageModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppani saa ilmoituksen, kun maksuosuutesi muuttuu."])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivinen"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epäaktiivinen"])}
    },
    "tabs": {
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsut"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteistyökumppanit"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteistyökumppanit"])},
    "unacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kumppanuus käytöstä"])},
    "unacceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuuden poistaminen käytöstä tarkoittaa, että myyntiartikkeleitasi ei näytetä toistensa sivuilla. \nMyös tulojen jakotoiminto poistetaan käytöstä molemmilta osapuolilta. \nTämä ei vaikuta tuleviin maksuihin aiemmasta tulonjaosta."])},
    "updatePartnershipAcceptanceErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuuden päivittäminen epäonnistui."])},
    "updatePartnershipAcceptanceSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuuden päivitys onnistui."])},
    "updatePartnershipPayoutPercentageErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanin maksuosuuden päivittäminen epäonnistui."])},
    "updatePartnershipPayoutPercentageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanin maksuosuuden päivitys onnistui."])},
    "updatePayoutPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta maksuosuutta"])}
  },
  "partnershipInvite": {
    "acceptInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy kutsu"])},
    "acceptInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanikutsun hyväksyminen epäonnistui."])},
    "acceptInviteRegisterFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos sinulla ei vielä ole palveluntarjoajan tiliä Touringeryssä, sinun on suoritettava rekisteröintiprosessi ennen kutsun uudelleen yrittämistä."])},
    "acceptInviteRequiresLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jotta voit hyväksyä kutsun, sinun on ensin kirjauduttava sisään palveluntarjoajatilillesi."])},
    "acceptInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanikutsun hyväksyminen onnistui. \nSinut ohjataan kumppanin tietonäkymään."])},
    "acceptInviteVisitorModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautuminen vaaditaan"])},
    "companyWantsYouToBecomeTheirPartner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("company")), " kutsuu sinut kumppanikseen Touringeryssa."])},
    "createInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanikutsun lähettäminen epäonnistui."])},
    "createInviteModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uusi kumppani"])},
    "createInviteSubmitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kutsu"])},
    "createInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanikutsun lähetys onnistui."])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuuskutsu"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu"])},
    "inviteFormInfoHelperFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanikutsu lähetetään määritettyyn sähköpostiosoitteeseen. \nSähköposti sisältää linkin, joka ohjaa vastaanottajan kumppanikutsun tietonäkymään. \nSiellä he voivat joko hyväksyä tai hylätä kutsun."])},
    "inviteFormInfoHelperSecondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsun hyväksymiseksi vierailijan on kirjauduttava sisään toisen palveluntarjoajan käyttäjänä. \nHylkäämisen voi tehdä kuka tahansa vierailija, jolla on pääsy linkkiin."])},
    "inviteInFinalStatusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu on jo saanut vastauksen."])},
    "reinviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uudelleenkutsun lähettäminen epäonnistui."])},
    "reinviteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä kutsu uudelleen"])},
    "reinviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu lähetettiin uudelleen."])},
    "rejectInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää kutsu"])},
    "rejectInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsun hylkääminen epäonnistui."])},
    "rejectInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu on hylätty."])},
    "statusLabel": {
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksytty"])},
      "DECLINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätty"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanhentunut"])},
      "OPENED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avattu"])},
      "SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetetty"])}
    },
    "whatHappensWhenYouAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuuden avulla voit näyttää muita tarjouksia ympärilläsi. \nSinä ja kumppanisi lunastatte myös pienen prosenttiosuuden myynnistä, jos se saavutetaan suoraan linkkien kautta."])}
  },
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana"])},
  "passwordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista salasana"])},
  "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salasana vaihdettu!"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksu"])},
  "paymentBeneficiaryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saajan nimi"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutiedot"])},
  "paymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutiedot"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutapa"])},
  "paymentOption": {
    "bankLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pankkimaksu"])},
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luottokortti"])}
  },
  "paymentTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuaika"])},
  "payoutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksu"])},
  "payoutInfo": {
    "completedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksu on suoritettu."])},
    "initiatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuprosessi on aloitettu."])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palkkaa ei makseta. \nTämä tapahtuu, kun peruutus tapahtuu täyden hyvityksen kanssa."])},
    "notReadyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksun oletustila. \nSe muuttuu, kun varaus on tehty tai peruutettu."])}
  },
  "payoutStatus": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksettu"])},
    "initiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloitettu"])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei maksua"])},
    "notReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pidossa"])}
  },
  "payoutStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksun tilat"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ihmiset"])},
  "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosenttiosuus"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kausi"])},
  "periodAvailability": {
    "availabilityHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatavuus"])},
    "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse päivä ja aika, jolloin resurssi on käytettävissä"])}
  },
  "periodUnit": {
    "monthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuukausi"])},
    "selectMonthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kuukausi"])},
    "selectPeriodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ajanjakso"])},
    "selectUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kausi"])},
    "weekLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viikko"])}
  },
  "permissions": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenteri"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaat"])},
    "helper": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voidaan lisätä uusi yritys"])},
      "readCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi nähdä kalenterin tiedot"])},
      "readClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee asiakkaan tiedot"])},
      "readCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee kanavanhallinnan ja asetusten sisällön"])},
      "readReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee kirjanpidon ja raporttien sisällön"])},
      "readResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee resursseja"])},
      "readServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee myyntiartikkeleita ja lisäpalvelutietoja"])},
      "readTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkee tehtävätiedot"])},
      "readTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näet lipputiedot"])},
      "updateClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi muokata asiakastietoja"])},
      "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi muokata yrityssopimuksia, maksutietoja, kanavan johtajaa ja kumppanuusasetuksia"])},
      "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi muokata yrityksen yleisiä tietoja ja yrityksen sijaintitietoja"])},
      "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osaa muokata yrityksen käyttäjärooleja ja asettaa oikeuksia"])},
      "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osaa muokata yrityksen sähköpostimalleja ja sisäänkirjautumislomakkeen kysymyksiä"])},
      "updateReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "updateResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi muokata resursseja"])},
      "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi lisätä uusia myyntiartikkeleita ja uusia lisäpalveluita. \nOsaa muokata myyntiartikkelien ja lisäpalvelun yleistä sisältöä ja multimediaa"])},
      "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osaa julkaista ja arkistoida myyntiartikkeleita ja lisäpalveluita"])},
      "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osaa muokata myyntiartikkelin resursseja, aukioloaikoja, hintoja, asuntokohtaisia ​​tietoja myyntiartikkelin valikosta ja myös kalenterista"])},
      "updateTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi muokata tehtäviä"])},
      "updateTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oikeus lippujen vahvistamiseen"])}
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportit"])},
    "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssit"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeleita"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asetukset"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävät"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liput"])},
    "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritysmaksut, sopimukset"])},
    "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen asetukset"])},
    "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen käyttäjät"])},
    "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen työnkulun avustajat"])},
    "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeleiden sisältö"])},
    "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelien julkaiseminen"])},
    "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelikäytäntö"])}
  },
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelinnumero"])},
  "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valokuvat"])},
  "pleaseAgreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse, että olet hyväksynyt ehdot jatkaaksesi"])},
  "pleaseSelectBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse maksupalveluntarjoaja"])},
  "pleaseSelectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse maksutapa"])},
  "plurals": {
    "adult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 aikuista"]), _normalize(["1 aikuinen"]), _normalize([_interpolate(_named("count")), " aikuista"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 lasta"]), _normalize(["1 lapsi"]), _normalize([_interpolate(_named("count")), " lasta"])])},
    "elderly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 vanhuutta"]), _normalize(["1 vanhin"]), _normalize([_interpolate(_named("count")), " vanhempaa"])])},
    "numberOfGuests": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 vierasta"]), _normalize(["1 vieras"]), _normalize([_interpolate(_named("count")), " vierasta"])])}
  },
  "politicallyExposed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poliittisesti altistunut henkilö"])},
  "preferences": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päivää"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etäisyys (km)"])},
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vieraita"])},
    "includedInPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisältyy hintaan"])},
    "labels": {
      "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esteettömyys"])},
      "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelut"])},
      "checkInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista aukioloajat"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopimus"])},
      "difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikeus"])},
      "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisätietoa"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieli"])},
      "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieraiden lukumäärä"])},
      "stayDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oleskelun kesto"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohde"])}
    },
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enimmäismäärä"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimi"])},
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])}
  },
  "preferredLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suosittu kieli"])},
  "prepaymentInvoicePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennakkomaksulaskun etuliite"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatselu"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen"])},
  "previousMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellisen kuukauden tulokset"])},
  "price": {
    "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäpalvelut"])},
    "additionalServicesVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäpalvelut ALV (20%)"])},
    "createTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo lippu"])},
    "descriptionPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintakuvaus (valinnainen)"])},
    "dynamicPricePerUnit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hinta per ", _interpolate(_named("unit"))])},
    "fullPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täysi hinta"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnan nimi"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta"])},
    "servicePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin hinta"])},
    "serviceVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntituote ALV (20%)"])}
  },
  "priceDynamicRows": {
    "addRowLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää hinnanmuutos"])},
    "durationFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikuttaako kesto hintaan?"])},
    "durationInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet määrittänyt, että kesto vaikuttaa hintoihin. \nTämä tarkoittaa, että sinun on määritettävä vähintään yksi hinnanmuutos."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestohinnan muutokset koostuvat seuraavista kentistä"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus kestohinnan muutoksista"])},
      "isImportantHourlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntihinnoittelussa kesto on aina tärkeä, eikä sitä voi poistaa käytöstä."])},
      "isImportantNightlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöhinnoittelussa kesto on aina tärkeä, eikä sitä voi poistaa käytöstä."])},
      "isImportantPackagedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakatun hinnoittelutyypin kanssa kesto ei ole tärkeä, eikä sitä voida ottaa käyttöön."])},
      "isPriorityHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tämä on valittuna, tämä hintamuutos koskee myös kaikkia aikaisempia tunteja ohittaen muut niihin mahdollisesti tehdyt hintamuutokset."])},
      "isPriorityNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tämä on valittuna, tämä hintamuutos koskee myös kaikkia aikaisempia öitä ohittaen muut niihin mahdollisesti kohdistuneet hintamuutokset."])},
      "lowerBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntien alaraja tietyillä hinnanmuutosrajoilla. \nAlaraja sisältää, hintamuutokset koskevat sitä."])},
      "lowerBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöpymisten alaraja tietyillä hinnanmuutosrajoilla. \nAlaraja sisältää, hintamuutokset koskevat sitä."])},
      "rowsHourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletusarvoisesti jokaisen varauksen tunnin hinta asetetaan aiemmin määritettyyn perushintaan. \nTäällä voit muokata hintaa jokaiselle tunnille lisäämällä hinnanmuutossääntöjä. \nLisätietoja otsikon vieressä olevasta info-kuvakkeesta."])},
      "rowsNightlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletusarvoisesti jokaisen varauksen yön hinta asetetaan aiemmin määritettyyn perushintaan. \nTäällä voit muokata kunkin yön hintaa lisäämällä hinnanmuutossääntöjä. \nLisätietoja otsikon vieressä olevasta info-kuvakkeesta."])},
      "rowsPackagedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kesto ei vaikuta pakatun tyypin hinnoitteluun. \nHinta on koko varauksen hinta jokaisen tunnin/yön sijaan."])},
      "upperBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntien yläraja tietyille hinnanmuutosrajoille. \nYläraja on yksinomainen, hintamuutokset eivät koske sitä.\n\nYlärajaa ei vaadita, mutta vain yksi kestosääntö voi olla rajaton."])},
      "upperBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yläraja annetuille hinnanmuutosrajoille. \nYläraja on yksinomainen, hintamuutokset eivät koske sitä.\n\nYlärajaa ei vaadita, mutta vain yksi kestosääntö voi olla rajaton."])}
    },
    "durationRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestoon perustuvat hintamuutokset"])},
    "groupSizeFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikuttaako ryhmän koko hintaan?"])},
    "groupSizeInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet määrittänyt, että ryhmän koko vaikuttaa hintaan. \nTämä tarkoittaa, että sinun on määritettävä vähintään yksi hinnanmuutos."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmäkoon hintamuutokset koostuvat seuraavista kentistä"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus ryhmäkoon hintamuutoksista"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tämä on valittuna, tämä hintamuutos koskee myös kaikkia aiempia ryhmän jäseniä ohittaen kaikki muut heihin mahdollisesti tehdyt hintamuutokset."])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alempi henkilömääräraja tietyille hinnanmuutosrajoille. \nAlaraja sisältää, hintamuutokset koskevat sitä."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oletuksena, kun ryhmän koko on tärkeä, ryhmän kokoa käytetään kertoimena lopullisen hinnan saamiseksi. \nTäällä voit muokata sitä, miten jokainen lisähenkilö vaikuttaa hintaan määrittämällä hintamuutokset. \nLisätietoja otsikon vieressä olevasta info-kuvakkeesta."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suurin sallittu määrä henkilöitä säännön soveltamiseen."])}
    },
    "groupSizeRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmäkokoon perustuvat hintamuutokset"])},
    "hourUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntia"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnissa"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnin"])}
    },
    "lowerBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "nightUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yöt"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per yö"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yö"])}
    },
    "noOverlapRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnanmuutosehdot eivät voi olla päällekkäisiä"])},
    "onlyOneLimitlessRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vain yhdellä hinnanmuutoksen ehdolla voi olla määrittelemätön (rajaton) yläraja"])},
    "personUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilöt"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per henkilö"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilö"])}
    },
    "priceChangeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvo, joka lisätään lopulliseen hintaan, jos varaus vastaa säännön ehtoa."])},
    "priceChangeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnan muutos"])},
    "priorityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä edelliseen"])},
    "quantityUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrät"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrää kohti"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])}
    },
    "relatedResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minkä liittyvän resurssin vaadittava määrä on annettu hinnanmuutos perustuu."])},
    "relatedResourceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvä resurssi"])},
    "relatedResourcePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse"])},
    "resourceConditionsPerRelatedResourceRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liittyvien resurssien hintamuutosten päällekkäisyyden rajoitus voi tapahtua vain samaan liittyvään resurssiin liittyvien hintamuutosten välillä."])},
    "resourcesFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikuttavatko asiaan liittyvät resurssit hintaan?"])},
    "resourcesInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet määrittänyt, että asiaan liittyvät resurssit vaikuttavat hinnoitteluun. \nTämä tarkoittaa, että sinun on määritettävä vähintään yksi hinnanmuutos."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssien hintamuutokset koostuvat seuraavista kentistä"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus asiaan liittyvistä resurssien hintamuutoksista"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tämä on valittuna, tätä hinnanmuutosta sovelletaan myös kaikkiin aikaisempiin liittyviin resurssimääriin/tunteihin ohittaen muut niihin mahdollisesti kohdistuneet hintamuutokset."])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pienin resurssimäärä, joka vaaditaan kestosäännön soveltamiseen."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun resurssit ovat tärkeitä, voit määrittää, kuinka kukin lisätty resurssi vaikuttaa lopulliseen hintaan.\n\nIhmisiä edustavien resurssien hinta muuttuu tuntimäärän mukaan.\n\nObjekteja edustavien resurssien hinta muuttuu varaukseen tarvittavien resurssien määrän mukaan. \nLisätietoja otsikon vieressä olevasta info-kuvakkeesta."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssimäärän/tuntien yläraja tietyillä hinnanmuutosrajoilla. \nYläraja on yksinomainen, hintamuutokset eivät koske sitä.\n\nYlärajaa ei vaadita, mutta vain yksi kestosääntö voi olla rajaton."])}
    },
    "resourcesRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssien perushinta muuttuu"])},
    "restrictionsSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rajoitukset"])},
    "upperBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siihen asti kun"])}
  },
  "pricingProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnoitteluominaisuudet"])},
  "pricingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnoittelutyyppi"])},
  "pricingTypes": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunneittain"])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurkki"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iltaisin"])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakattu"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])}
  },
  "pricingTypesInfo": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lippujen hinnat (aikuiset, vanhukset, lapset) koskevat koko tapahtumaa."])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötetty hinta on jokaiselta tunnilta ja se kerrotaan varauksen kestolla.\n\nTällä hetkellä voit määrittää vähimmäis- ja enimmäiskeston.\n\nTÄRKEÄ:\n\nTällä hetkellä alkamisaikoja ei voi rajoittaa, asiakas voi valita minkä tahansa aukioloaikaan sopivan ja ei ole varattu.\n\nRyhmäkoko ei vaikuta hintaan. \n2 tunnin varaus maksaa saman verran yhdelle henkilölle ja 10 hengen ryhmälle.\n\nTällä hetkellä vain yhtä samanaikaista varausta tuetaan."])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopullinen hinta asiakkaalle. \nPalveluntarjoaja määrittelee myyntiartikkelin"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta lasketaan varauksessa olevien öiden lukumäärän perusteella.\n\nTÄRKEÄ:\n\nTällä hetkellä koko varauksen hinta perustuu ensimmäisen yön hintaan."])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötetty hinta koskee koko varauksen kestoa, jonka voit määrittää valitessasi tämän hinnoittelutyypin.\n\nTÄRKEÄ:\n\nTällä hetkellä alkamisaikoja ei voi rajoittaa, se on asiakkaasta kiinni.\n\nRyhmäkoko ei vaikuta hintaan. \n2 tunnin varaus maksaa saman verran yhdelle henkilölle ja 10 hengen ryhmälle.\n\nTällä hetkellä vain yhtä samanaikaista varausta tuetaan."])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta lasketaan määrän perusteella"])}
  },
  "priority": {
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriittinen"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korkea"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matala"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskikokoinen"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiireellisyys"])}
  },
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tietosuojakäytäntö"])},
  "productCalendarDetails": {
    "noSalesForDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotteita ei ole ostettu tälle päivälle"])}
  },
  "profileSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiilin asetukset"])},
  "publicNoServiceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näyttää siltä, ​​että olet menossa vääriin kohteisiin."])},
  "publicNoServiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voi ei!"])},
  "publicService": {
    "archivedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli ei ole saatavilla"])}
  },
  "publicTransport": {
    "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lentokenttä"])},
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bussi"])},
    "harbor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satama"])},
    "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kouluttaa"])},
    "tram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raitiovaunu"])},
    "trolley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Johdinbussi"])}
  },
  "purchasedAtDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostopäivä"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue vähemmän"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää"])},
  "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syy"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanottaja"])},
  "recipientWithPlural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanottaja/Vastaanottajat"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanottajat"])},
  "recommendedCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisäänkirjautuminen klo"])},
  "recommendedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarkista asti"])},
  "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieltäytyä"])},
  "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieltäytyi"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröidy"])},
  "registration": {
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi yritys"])}
  },
  "registrationCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisteröintimaa"])},
  "registrationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin luominen epäonnistui"])},
  "registrationSuccessEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoittautuminen onnistui! \nPostilaatikkoosi on lähetetty aktivointisähköposti"])},
  "registryNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekisterikoodi"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylätty"])},
  "relate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suhtaudu"])},
  "relatedAvailableServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatavilla valittuina päivinä"])}
  },
  "relatedService": {
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso lisää"])}
  },
  "relatedServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse lisäksi"])},
    "partnersHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanki lisää kumppaneilta"])}
  },
  "relatedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät käyttäjät"])},
  "related_check_in_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät sisäänkirjautumiskysymykset"])},
  "related_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät resurssit"])},
  "related_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyviä myyntiartikkeleita"])},
  "related_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät sähköpostimallit"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
  "report": {
    "deposit": {
      "header": {
        "commissionsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välityspalkkio"])},
        "depositsBeforePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo ennen ajanjakso"])},
        "depositsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadut talletukset"])},
        "depositsEndOfPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo jakson jälkeen"])},
        "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talletuspäivämäärä"])},
        "partnersPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanien maksut"])},
        "payoutAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksupäivä"])},
        "refundedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautuspäivämäärä"])},
        "refundedDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyvitykset"])},
        "serviceProviderPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palveluntarjoajan maksut"])}
      }
    },
    "errorFetchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportin luominen epäonnistui"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuottaa"])},
    "generatePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luoda pdf"])},
    "partners": {
      "header": {
        "payoutAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksut"])},
        "payoutTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksun vastaanottaja"])}
      }
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportit"])},
    "sales": {
      "header": {
        "clientsCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaiden maa"])},
        "clientsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaiden nimi"])},
        "commissionFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisio"])},
        "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto"])},
        "partnerPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanin osuus"])},
        "payoutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksupäivä"])},
        "priceLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintalinja"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])},
        "refundFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautusmaksu"])},
        "refundedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palautuspäivä"])},
        "salesArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli"])},
        "salesReportGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiraportti luotu"])},
        "sumWithVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumma ALV:lla"])},
        "sumWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumma ilman arvonlisäveroa"])},
        "totalVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alv yhteensä"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARVONLISÄVERO(%)"])}
      }
    },
    "table": {
      "additionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäpalvelu"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokea"])},
      "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuminen"])},
      "moneyjar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurkki"])}
    },
    "tabs": {
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talletusraportti"])},
      "partnersReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteistyökumppanien raportti"])},
      "salesReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiraportti"])},
      "statisticsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilastoraportti"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki yhteensä"])},
    "touringeryCommission": {
      "header": {
        "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto"])},
        "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonaismyynti"])}
      }
    }
  },
  "reports": {
    "statistics": {
      "accomodatedEstonianNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viron asukkaat - vietetyt yöt"])},
      "accomodatedEstonianPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viron asukkaat - majoittuneiden matkailijoiden määrä"])},
      "accomodatedForeignNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkomaalaiset vierailijat - vietetyt yöt"])},
      "accomodatedForeignPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkomaalaiset vierailijat - majoittuneiden matkailijoiden määrä"])},
      "accomodatedNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietettyjen öiden määrä"])},
      "accomodatedPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoituneiden turistien määrä"])},
      "bedPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuodepaikat"])},
      "campingPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paikkojen määrä leirintäalueilla ja traileripaikoilla"])},
      "countryCodeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatunnus"])},
      "openDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avattujen päivien lukumäärä"])},
      "roomNightsSoldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuntojen käyttö (myydyt huoneyöt)"])},
      "roomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuntojen lukumäärä"])},
      "tripPurpose": {
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Työmatka"])},
        "conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..osallistuminen konferenssiin"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut (esim. kauttakulkumatkailussa, lääketieteellisessä matkailussa vietetyt yöt jne.)"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoituneiden matkailijoiden kokonaismäärä ja yöpymiset"])},
        "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomamatka"])}
      }
    }
  },
  "requestLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä linkkiä"])},
  "requestNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä uusi salasana"])},
  "requestSentLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyyntö lähetetty! \nOlemme lähettäneet salasanan palautuslinkin."])},
  "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyynnöt"])},
  "requiredSettingsAlert": {
    "beneficialOwnersNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todelliset omistajat/omistajat"])},
    "companyContractNotAgreedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hyväksy sopimusehdot"])},
    "companyNoServiceContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntisopimus palveluntarjoajan ja asiakkaan välillä"])},
    "dynamicShowAllLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Näytä lisää (", _interpolate(_named("count")), ")"])},
    "hideMoreSettingsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piilota jotkin puuttuvat asetukset"])},
    "paymentInfoNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutiedot maksuja varten"])}
  },
  "requirementsFilledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtui virhe. \nTarkista, että kaikki on täytetty oikein"])},
  "resendConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä tilausvahvistus"])},
  "resendConfirmationEmailAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täällä voit lähettää tilausvahvistuksen asiakkaalle uudelleen."])},
  "resendConfirmationEmailAlertWithLastDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Täällä voit lähettää tilausvahvistuksen asiakkaalle uudelleen. Edellinen lähetettiin: ", _interpolate(_named("emailConfirmationAt")), "."])},
  "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaus"])},
  "reservationData": {
    "cannotChangePeriodForExternalSourcesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikaa ei voi muuttaa, koska varaus tulee ulkoisesta lähteestä."])},
    "originalGuestsAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vieraiden määrä on muuttunut, alun perin se oli: ", _interpolate(_named("guests"))])}
  },
  "reservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varauspäivämäärä"])},
  "reservationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaus loppu"])},
  "reservationHousingUnit": {
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe asunnon tallentamisessa"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunnon tallennus onnistui"])}
  },
  "reservationLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varauksen pituus"])},
  "reservationSalesEndTimeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkkomyynnin päättymispäivä"])},
  "reservationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaus alkaa"])},
  "reservationStatus": {
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruutettu"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanhentunut"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksettu"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odottaa"])}
  },
  "reservationStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varausten tilat"])},
  "reservationType": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suhteen tyyppi"])},
    "oneCapacityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi kapasiteetti per henkilö"])},
    "oneQuantityPerEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi määrä tapahtumaa kohden"])},
    "oneQuantityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi määrä per henkilö"])},
    "oneQuantityPerReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksi määrä/tunti per varaus"])}
  },
  "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varata"])},
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssi"])},
  "resourceCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssi luotu onnistuneesti"])},
  "resourceCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssin luominen epäonnistui"])},
  "resourceDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooli/Otsikko"])},
  "resourceDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssin poistaminen onnistui"])},
  "resourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmä"])},
  "resourceGroupCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän luominen"])},
  "resourceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän luominen onnistui"])},
  "resourceGroupCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän luominen epäonnistui"])},
  "resourceGroupUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmäpäivitys"])},
  "resourceGroupUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän päivitys epäonnistui"])},
  "resourceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän päivitys onnistui"])},
  "resourceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
  "resourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssin nimi"])},
  "resourceObjectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsikko"])},
  "resourcePeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna päivämäärä, jona resurssi on käytettävissä"])},
  "resourceSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssi tallennettu onnistuneesti"])},
  "resourceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssin tallennus epäonnistui"])},
  "resourceUserTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooli"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurssit"])},
  "respondedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastannut"])},
  "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaus"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooli"])},
  "room": {
    "alertinfotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto on majoitusta vieraalle/vierasryhmälle. \nLisää jokainen asuntoyksikkö erikseen; \ntai lisää (samankaltaisten) asuntojesi lukumäärä"])},
    "maxGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieraiden enimmäismäärä"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huone"])},
    "roomInfoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä tämän asunnon huoneiden lukumäärä määrittääksesi kunkin huoneen tiedot"])},
    "roomType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huone tyyppi"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneet"])},
    "unitCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])}
  },
  "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huoneen mukavuudet"])},
  "roomLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yleistä tietoa"])},
  "roomsSoldEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkailijat Virosta"])},
  "roomsSoldNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkailijat muista maista"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivit per sivu"])},
  "rrule": {
    "end:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loppu:"])},
    "endDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättymispäivä:"])},
    "eventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuman tiedot"])},
    "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka"])},
    "everyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \npäivämäärä joka"])},
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka päivä"])},
    "everyMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka kuukausi"])},
    "everyOtherDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päivä"])},
    "everyOtherMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuukausi"])},
    "everyOtherWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viikko"])},
    "everyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajat"])},
    "everyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka viikko"])},
    "everyWorkDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joka työpäivä"])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ensimmäinen"])},
    "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neljäs"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perjantai"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maanantai"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuukausi"])},
    "recurresEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toistuu joka kerta"])},
    "repetitionFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toistotaajuus"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lauantai"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toinen"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunnuntai"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolmas"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torstai"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiistai"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keskiviikko"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallentaa"])},
  "saveAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna ja jatka"])},
  "saveNewCoordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanki karttakoordinaatit"])},
  "scanner": {
    "anonymousCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonyymi kamera"])},
    "cameraAccessSecureContextOnlyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kameran käyttöä tuetaan vain suojatussa kontekstissa, kuten https tai localhost."])},
    "cameraBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamerapohjainen skannaus"])},
    "cameraStreamingNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selain ei tue kameran suoratoistoa."])},
    "chooseAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse Toinen"])},
    "chooseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse Kuva"])},
    "chooseImageNoImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kuva - Ei kuvaa valittuna"])},
    "codeScanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koodin skanneri"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])},
    "fileBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedostopohjainen skannaus"])},
    "getUserMediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe haettaessa userMediaa, virhe ="])},
    "idle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyhjäkäynti"])},
    "launchingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kameraa käynnistetään..."])},
    "loadingImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladataan kuvaa..."])},
    "navigatorMediaDevicesNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laite ei tue parametria navigator.mediaDevices , vain tuettu cameraIdOrConfig on tässä tapauksessa deviceId-parametri (merkkijono)."])},
    "noCameraFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kameraa ei löytynyt"])},
    "noCameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei kameroita"])},
    "noImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaa ei ole valittu"])},
    "notAllowedPermissionDeniedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NotAllowedError: Lupa estetty"])},
    "orDropImageToScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tai pudota kuva skannattavaksi"])},
    "orDropImageToScanOtherFilesNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tai pudota kuva skannattavaksi (muita tiedostoja ei tueta)"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lupa"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimanlähteenä"])},
    "qrCodeParseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-koodin jäsennysvirhe, virhe ="])},
    "querySupportedDevicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuetuista laitteista ei voi tehdä kyselyä, tuntematon virhe."])},
    "reportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoita ongelmista"])},
    "requestCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä kameran käyttöoikeuksia"])},
    "requestingCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyydä kameran käyttöoikeuksia..."])},
    "scanAnImageFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannaa kuvatiedosto"])},
    "scanUsingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannaa suoraan kameralla"])},
    "scannerPaused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skanneri keskeytetty"])},
    "scanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannaus"])},
    "selectCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse Kamera"])},
    "startScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita skannaus"])},
    "stopScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lopeta skannaus"])},
    "switchOffTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammuta taskulamppu"])},
    "switchOffTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polttimen sammuttaminen epäonnistui"])},
    "switchOnTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kytke taskulamppu päälle"])},
    "switchOnTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polttimen sytyttäminen epäonnistui"])},
    "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zoomaus"])}
  },
  "scheduledEmails": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikataulutetut sähköpostit"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei sähköposteja"])},
    "scheduledAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aikataulutettu"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähettää sähköpostia"])},
    "sendEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostin lähetys epäonnistui"])},
    "sendEmailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostin lähetys onnistui"])},
    "sentAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeksi lähetetty klo"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sapluuna"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae"])},
  "searchExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae nykyisistä asiakkaista"])},
  "searchForAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi osoite"])},
  "searchToSeeResultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna päivämäärät ja vierailijatiedot nähdäksesi saatavuuden"])},
  "seeAllAdditionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki lisäpalvelut"])},
  "seeAllClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki asiakkaat"])},
  "seeAllResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki resurssit"])},
  "seeAllServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki myyntiartikkelit"])},
  "seeAllTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki tehtävät"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso lisää"])},
  "selectAdditionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse lisäpalvelut"])},
  "selectAdditionalServicesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse alta lisäpalvelut, joihin haluat liittää"])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse yritys"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse maa"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse päivämäärä"])},
  "selectRelatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse aiheeseen liittyvä resurssi"])},
  "selectResourcesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse alta resurssit, jotka haluat liittää"])},
  "sendInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähettää kutsuja"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä viesti"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli"])},
  "serviceAdditionalService": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täällä voit linkittää lisää tuotteita, jotka on aiemmin syötetty myyntiartikkelivalikosta. \nValitut lisätuotteet näkyvät ostoskorissa ennen maksua."])}
  },
  "serviceAdvancedTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitkälle kehittynyt"])},
  "serviceArchiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin arkistointi epäonnistui"])},
  "serviceArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli arkistoitu onnistuneesti"])},
  "serviceCheckInQuestions": {
    "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää kysymys"])},
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kysymyksiä, jotka lisätään myyntiartikkelin sisäänkirjautumislomakkeeseen tai poistetaan siitä"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutokset koskevat kaikkia asiaan liittyviä myyntiartikkeleita"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät sisäänkirjautumiskysymykset"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso kaikki kysymykset kohdassa Asetukset - Sisäänkirjautumiskysymykset. \nTäällä voit lisätä kysymyksiä nykyiseen palvelukohteeseen"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna suhteet"])},
    "selectQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa aiheeseen liittyviä kysymyksiä"])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikkien myyntiartikkeleiden sisäänkirjautumislomakkeen lisäkysymykset ovat kohdassa Asetukset -> Lähtöselvityslomake"])}
  },
  "serviceContact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteystiedot"])},
    "headerDefaultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kentät, joissa ei ole arvoja, käyttävät oletusarvoisesti yritysprofiilin asetuksia paikkamerkeissä."])},
    "headerGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä myyntiartikkelikohtaiset yhteystiedot asiakkaille."])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteyshenkilön nimi. \nKenttä on piilotettu asiakkailta, kun se jätetään tyhjäksi."])},
    "overrideNotificationsGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun tämä on valittuna, tätä palvelua koskevien sähköposti-ilmoitusten lähettämiseen käytetään annettua sähköpostiosoitetta yrityksen sähköpostin sijaan."])},
    "overrideNotificationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä sähköposti-ilmoituksiin"])},
    "overrideNotificationsSettingsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti-ilmoitusasetukset ovat edelleen voimassa."])}
  },
  "serviceDuplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli kopioitiin onnistuneesti"])},
  "serviceDuplicationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin kopiointi epäonnistui"])},
  "serviceEvent": {
    "availableCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytettävissä oleva kapasiteetti"])},
    "cancelAtHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruutusaika"])},
    "cancellationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täällä voit asettaa päivämäärän ja kellonajan, johon mennessä vähimmäisosallistujamäärä tulee täyttyä/myytyjen lippujen vähimmäismäärä. Jos vähimmäisosallistujamäärä ei täyty, tapahtuma peruuntuu automaattisesti. Tähän mennessä ostetuista lipuista saadut rahat palautetaan, osallistujille ilmoitetaan asiasta automaattisesti. Tapahtuman peruuttamiseen liittyvät siirtokustannukset on korvattava järjestäjän toimesta."])},
    "capacityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tapahtumasi vaatii vähimmäismäärän osallistujia, kirjoita se tähän. Jos tapahtuma kuitenkin järjestetään, vähimmäismäärää ei tarvitse määrittää, vain syötä osallistujien enimmäismäärä."])},
    "eventCapacityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasiteetti"])},
    "eventPeriodHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuman aika"])},
    "maxCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enimmäismäärä"])},
    "minCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimi"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
    "timeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kalenterista tapahtuman alkamispäivä, napsauta kellokuvaketta ja valitse alkamisaika."])},
    "updateEventTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä tapahtuma"])}
  },
  "serviceEventCalendar": {
    "onlyEventsFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vain tapahtuman yhteydessä"])}
  },
  "serviceEventsArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtumat arkistoitu onnistuneesti"])},
  "serviceEventsArchivedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtumien arkistointi epäonnistui"])},
  "serviceFilter": {
    "privateState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkyvyys"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osavaltio"])}
  },
  "serviceGeneral": {
    "categoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse lähin mahdollinen."])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedä tarvittaessa ikkunaa oikeasta alakulmasta suurempaan."])},
    "typeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luokkamäärittely."])}
  },
  "serviceLocation": {
    "accessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse kaikki toimivat vaihtoehdot."])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakuosoite käyttää Google-hakua - kirjoita osoitteesi, koska sinut löytyy Googlesta."])},
    "howToGetThereInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaile millä nimellä, miten sinut löytyy karttasovelluksista ja/tai reitti sinua lähimmästä nähtävyyskeskuksesta."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirrä hiiri haluamaasi paikkaan kartalla ja napsauta, nasta tulee näkyviin, siirrä nastaa tarvittaessa oikeaan paikkaan."])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse sijainti"])},
    "transportInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkitse lähin pysäkki ja etäisyys määränpäästä."])}
  },
  "serviceLocationInfo": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täytä koordinaatit automaattisesti napsauttamalla vaihtoehtoa \"Hae osoite\" -kentässä. \nTäytä ne manuaalisesti tai siirrä merkki kartalla ja paina \"Hae karttakoordinaatit\" -painiketta"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näkyy sijaintiluettelossa tiettyjen paikkojen tunnistamisen helpottamiseksi"])}
  },
  "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin nimi"])},
  "serviceNotSavedWarning": {
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hylkää muutokset"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet jatkamassa tallentamatta!"])}
  },
  "servicePeriod": {
    "activeHoursInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeleiden saatavuus viikoittain ja päivinä."])},
    "arrivalLengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saapumiseen perustuva oleskelun pituus"])},
    "lengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oleskelun kesto"])},
    "periodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä ajanjakso, jonka aikana tarjoat myyntiartikkelia. \nJos myyntiartikkeli on saatavilla ympäri vuoden, niin kalenterivuoden tai pidemmän ajan. \nJos et halua kauden päättyvän, jätä jakson loppu tyhjäksi."])},
    "restriction": {
      "info": {
        "arrivalLengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä on vähimmäis- ja enimmäismäärä yötä, jonka asiakkaat voivat varata saapuessaan tiettynä päivänä. \nNämä rajoitukset on ylitettävä varausjakson saapumispäivän perusteella."])},
        "bookingArrivalLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nämä rajoitukset vastaavat Booking.comin oleskelun vähimmäis- ja enimmäispituutta saapumisrajoituksista."])},
        "bookingLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nämä rajoitukset vastaavat Booking.comin oleskelun vähimmäis- ja enimmäispituusrajoituksia."])},
        "lengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä on vähimmäis- ja enimmäismäärä yötä, jotka asiakkaat voivat varata. \nNämä rajoitukset on ylitettävä kaikkien varausjakson päivien osalta."])},
        "restrictionSectionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiettyjä rajoituksia sovelletaan kaikkiin päivämääriin tietyllä ajanjaksolla."])},
        "specifiedMaxRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos määritetään, sen on oltava vähintään yhtä suuri kuin oleskelun keston ja saapumisperusteisen oleskelun keston vähimmäisarvot."])},
        "syncedToBookingGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun integrointi on aktiivinen ja majoitus on yhdistetty Booking.com-huoneeseen, tämä rajoitus koskee myös huoneiden hintoja."])},
        "unspecifiedOptionBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos vaihtoehtoa ei ole määritetty, rajoitusta ei synkronoida Booking.comin kanssa. \nTämä tarkoittaa, että kaikki aiemmin asetetut rajoitukset pysyvät siellä voimassa."])},
        "unspecifiedOptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tämä vaihtoehto valitaan, rajoitusta ei sovelleta."])},
        "unspecifiedOptionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrittelemätön vaihtoehto"])}
      }
    },
    "restrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rajoitukset"])},
    "stayOptionNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrittelemätön"])},
    "weekslotsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiiviset tunnit"])}
  },
  "servicePeriodList": {
    "addAnotherPeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää uusi jakso, jos myyntiartikkeli on kausiluonteisesti saatavilla eri vuorokaudenaikoina."])}
  },
  "servicePeriodPricing": {
    "descriptionHelperInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnan nimi on loppukäyttäjälle maksettavan hinnan nimi. \nKuvaus on valinnainen informatiivinen kenttä, joka ei näy loppukäyttäjälle."])},
    "discountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täällä voit valita minkä alennuksen haluat tarjota, millä ehdoilla ja minkä suuruisena. Tarvittaessa voit lisätä useita alennuksia."])},
    "identificatorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää yksilöllinen tunniste, jota järjestelmä käyttää hinnan tunnistamiseen. \nSe voi olla esimerkiksi hinnan nimi. \nÄlä käytä välilyöntejä tai erikoismerkkejä."])},
    "isMinimumPriceWarningAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vähimmäishintaa on sovellettu nykyiseen valintaan."])},
    "minPriceFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimihinta"])},
    "minPriceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos määritetty arvo, se pakotetaan varauksen lopulliseksi kokonaishinnaksi, jos alkuperäinen kokonaishinta olisi muuten alhaisempi (mukaan lukien alennukset). \nMinimihinta ei vaikuta lisäpalvelujen hintoihin. \nNiiden kustannukset lisätään vähimmäishinnan voimaantulon jälkeen."])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna lopullinen hinta veroineen"])},
    "pricingPropertiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuinka myyntiartikkelin hinta lasketaan hinnoittelutyypin perusteella:"])},
    "ticketCheckboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos valitset tämän, jokaisesta ostetusta tuotteesta luodaan ja lähetetään lippu QR-koodilla."])}
  },
  "servicePreference": {
    "difficultyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos myyntiartikkelissa on vaikeuksia, aseta, jos ei, määrittelemätön."])}
  },
  "servicePreferences": {
    "accessibilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse mitä tarjotaan."])},
    "amenitiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse hintaan sisältyvät lisäarvot, palvelut, vaihtoehdot."])},
    "contractInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse sopimus, jossa määritellään asiakkaan ja palveluntarjoajan väliset ehdot."])},
    "contractInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit määrittää sopimukset Asetukset - Sopimukset asetusosiossa."])},
    "difficultyLabels": {
      "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helppo"])},
      "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kovaa"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskikokoinen"])},
      "neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutraali"])}
    },
    "languagesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kielet, joilla asiakkaita palvellaan."])},
    "minReservationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna viimeisin aika, jolloin on mahdollista ostaa lippu. Jos rajaa ei ole, jätä tyhjäksi."])},
    "targetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tiedot ovat tärkeitä, valitse kohderyhmät, muussa tapauksessa jätä se tyhjäksi."])}
  },
  "servicePreview": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiemmin syötettyjen tietojen perusteella asiakkaasi näkemä näkymä. Jos kaikki tärkeä on siellä, paina sivun alareunassa olevaa julkaisupainiketta. Syntyy hajautettu url/linkki, jonka voit sijoittaa myyntikanaviisi (FB, kotisivu, yhteistyökumppanien sivut...). Kaikkia tietoja voidaan vielä muuttaa ensimmäiseen myyntitapahtumaan saakka. Ensimmäisen myynnin jälkeen aikaa ja hintaa ei voi muuttaa."])}
  },
  "servicePrivateStateFilter": {
    "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki"])},
    "privateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä yksityinen"])},
    "publicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä julkinen"])}
  },
  "serviceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palveluntarjoaja"])},
  "serviceProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palveluntarjoajat"])},
  "servicePublicURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin julkinen URL-osoite:"])},
  "servicePublishButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaista"])},
  "serviceResource": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos käytät resurssien suunnittelua, syötä se, ja jos et käytä sitä, siirry eteenpäin."])},
    "newResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tarvittavaa resurssia ei ole syötetty, voit tehdä sen täällä."])}
  },
  "serviceResources": {
    "removeResourceConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa tämän resurssin?"])}
  },
  "serviceStateFilter": {
    "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä aktiivinen"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kaikki"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä arkistoitu"])},
    "showUnpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä julkaisematon"])}
  },
  "serviceTemplates": {
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse sähköpostimallit, jotka haluat liittää tiettyyn myyntiartikkeliin"])},
    "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo malli"])},
    "createNewLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo uusi sähköpostimalli"])},
    "editRelationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallisuhteet"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät sähköpostimallit"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katso Asetukset - Sähköpostimallit kaikille malleille. \nTäällä voit lisätä malleja nykyiseen palvelukohteeseen"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe suhteiden tallentamisessa"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna suhteet"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suhteet tallennettu onnistuneesti"])},
    "selectTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa mallisuhteita"])}
  },
  "serviceType": {
    "eventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma"])},
    "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelu"])},
    "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asuminen"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelityyppi"])},
    "moneyJarLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurkki"])},
    "productDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tämä vaihtoehto, jos haluat luoda tuotemyyntiartikkelin. Tuotteita voidaan myydä suoraan tai muiden myyntituotteiden kautta."])},
    "productLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuote"])},
    "salesItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntituote"])}
  },
  "serviceUnits": {
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnin"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut"])},
    "otherUnitPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä yksikkö"])},
    "pcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPL"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilö"])},
    "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lemmikki"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksikkö"])}
  },
  "serviceView": {
    "duplicateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiointi on käytössä julkaistuissa myyntiartikkeleissa. \nKaikki myyntiartikkelitiedot kopioidaan paitsi tapahtumapäivämäärät (jos kyseessä on tapahtuma). \nMonista myyntiartikkeleita ei julkaista."])},
    "isArchivedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoitu"])},
    "isNotPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaisematon"])},
    "isPrivateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asiakkaat eivät voi ostaa yksityisiä myyntiartikkeleita suoraan, eivätkä ne näy niihin liittyvinä myyntiartikkeleina."])},
    "isPrivateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityinen"])},
    "isPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkinen"])},
    "isPublishedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaistu myyntiartikkeli on julkisesti näkyvissä."])},
    "isPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaistu"])},
    "publishError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkelin julkaiseminen epäonnistui."])},
    "publishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli julkaistu onnistuneesti."])}
  },
  "service_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapahtuma"])},
  "service_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aukioloajat ja hinnat"])},
  "service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeli"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myyntiartikkeleita"])},
  "setDurationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta kesto manuaalisesti"])},
  "setNewPassword": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta uusi salasana"])},
    "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen tunnus tai salasana"])},
    "tokenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen tunnus"])}
  },
  "setUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta käyttäjätiedot"])},
  "settings": {
    "ServicePublishWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos haluat julkaista myyntiartikkelin, sinun on ensin täytettävä seuraavat tiedot"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asetukset"])},
    "tabLabel": {
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähtöselvityslomake"])},
      "compucash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash-integraatio"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopimukset"])},
      "emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostit"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenraali"])},
      "googleCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-kalenteri"])},
      "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integraatiot"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainti"])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumppanuus"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksu"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiili"])},
      "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjän roolit"])}
    }
  },
  "settingsCheckIn": {
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo kysymys"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe poistamisessa"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistettu onnistuneesti"])},
    "displayForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä kuittilomake"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisaküsimused"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisäkysymys"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kysymys"])}
  },
  "settingsContracts": {
    "archiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe arkistointiin"])},
    "archiveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistointi onnistui"])},
    "archivedSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkistoidut myyntiartikkelit"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä sopimus sisältää tilauskohteita. \nSopimustietoja muutettaessa alkuperäinen sopimus arkistoidaan ja palvelusuhteet siirtyvät."])},
    "clientConsents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilaukseen liittyvät tuotteet"])},
    "confirmArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti arkistoida?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa?"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo sopimus"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopimukset"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä sopimus jokaiselle myyntiartikkelille. \nAsiakas vahvistaa suostumuksensa sopimusehtoihin uloskirjautumisen yhteydessä."])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopimus"])},
    "relatedActiveSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaistu myyntiartikkeleita"])},
    "relatedPublishedServicesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkaisi sopimukseen liittyviä myyntiartikkeleita"])},
    "saveAsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna uutena"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä arkistoitu"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopimus"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitetty klo"])}
  },
  "settingsEmails": {
    "tabs": {
      "companyEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmoitukset"])},
      "emailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostimallit"])}
    }
  },
  "settingsLocation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijainnit"])},
    "titleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki palveluntarjoajan sijainnit, jotka voidaan määrittää myyntiartikkeleille."])}
  },
  "settingsPayments": {
    "getFromAriregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae yritysrekisteristä"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täytä pakolliset kentät pankin nimi, vastaanottajan nimi ja IBAN ja paina sitten oikeassa alakulmassa olevaa \"tallenna\"-painiketta."])},
    "noAriregisterData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei dataa"])}
  },
  "settingsProfile": {
    "accountsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse, missä yrityksesi on edustettuna ja minkä kanssa haluat synkronoida kalenterisi."])},
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen lukenut ja samaa mieltä verkkoalustan kanssa"])},
    "basicInformationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki pakolliset kentät paitsi km velvollinen. Syötä yritystiedot manuaalisesti tai hae tiedot yritysrekisteristä."])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sopimuksen"])},
    "contractLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palveluntarjoajan ja Touringery välisen sopimuksen ehdot"])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjoita yrityksen kuvaukseen missio, slogan tai tärkeä viesti, joka koskee kaikkia tarjolla olevia palveluita ja tuotteita."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita kirjoittaminen hakuikkunaan. Käytämme Google-osoitehakua, joten kirjoita niin kuin löydät Googlesta. Jos et vieläkään löydä tarkkaa osoitetta, voit kirjoittaa läänin, kaupungin jne. manuaalisesti."])},
    "socialNetworkAccountsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syötä olemassa olevat, näytämme ne myyntiartikkelinäkymässä jokaisen myyntiartikkelin vieressä."])},
    "vatNumberInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos km ei ole pakollinen, jätä kenttä tyhjäksi."])}
  },
  "settingsTemplates": {
    "contentPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisällön esikatselu"])},
    "createInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo sähköpostimalli, ajoita lähetysaika ja määritä siihen liittyvät palvelut"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo malli"])},
    "createNewExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo palvelumalli"])},
    "createNewHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo asuntomalli"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallin poistaminen epäonnistui"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malli poistettiin onnistuneesti"])},
    "experienceDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierailun päivien lukumäärä"])},
    "experienceEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päättymispäivämäärä ja -aika"])},
    "experienceStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelun alkamispäivä ja -aika"])},
    "forHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asumista varten"])},
    "forService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palvelua varten"])},
    "housingDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierailun öiden lukumäärä"])},
    "housingEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeisin uloskirjautumisaika"])},
    "housingStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoituksen osalta sisäänkirjautumispäivämäärä ja -aikaväli, palveluiden osalta tapahtuman alkamispäivämäärä ja -aika"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostimallit"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täällä voit määrittää sähköpostit lähetettäväksi automaattisesti asiakkaalle"])},
    "relatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät palvelut"])},
    "relatedTimings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiheeseen liittyvät ajankohdat"])},
    "restrictionCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä lähetä, jos sisäänkirjautumislomake on lähetetty"])},
    "restrictionCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttää sähköpostien lähettämiseen shekkiä: jos asiakas on lähettänyt sisäänkirjautumislomakkeen, sähköpostia ei lähetetä"])},
    "restrictionNoCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Älä lähetä, jos sisäänkirjautumislomaketta ei lähetetä"])},
    "restrictionNoCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttää sähköpostien lähettämiseen shekkiä: jos asiakas ei ole lähettänyt sisäänkirjautumislomaketta, sähköpostia ei lähetetä"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallin tallennus epäonnistui"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malli on tallennettu"])},
    "sendRetroactively": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä takautuvasti olemassa oleviin varauksiin"])},
    "sendRetroactivelyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse, jos haluat lähettää sähköpostia jo olemassa oleviin varauksiin. \nSähköposti lähetetään, jos sähköpostipohjan asetuksissa oleva ajoitus ei ole kulunut varauksen suhteen."])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täällä voit määrittää tähän palveluartikkeliin liittyviä sähköpostimalleja. \nJos haluat muokata kaikkia sähköpostimalleja, siirry asetusnäkymään"])},
    "serviceCreateWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muista, että jos muutat sähköpostimallin sisältöä, ajoituksia tai muita asetuksia, muutokset koskevat kaikkia tähän malliin liittyviä myyntiartikkeleita."])},
    "serviceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tähän malliin sopiva myynti"])},
    "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoitus"])},
    "timingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse kirjeen ajankohta"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsikko"])}
  },
  "settingsUsers": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anna käyttäjänimi ja sähköpostiosoite, aseta rooli ja vastaanota kutsu. Vahvistettuaan sähköpostiin lähetetyn viestin, uusi käyttäjä voi aloittaa työskentelyn hänelle osoitetun roolin mukaisesti."])},
    "tabs": {
      "companyRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen roolit"])},
      "usersRignts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjien oikeudet"])}
    },
    "userRolesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä käyttäjäroolit ja rooliluvat ja määritä jokaiselle käyttäjälle rooli. \nKäyttäjät voivat nähdä ja muokata vain roolin mahdollistamaa sisältöä."])}
  },
  "shippingSlipPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetyslipun etuliite"])},
  "showAllAmenities": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Näytä kaikki ", _interpolate(_named("numOfAmenities")), " mukavuudet"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä vähemmän"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä lisää"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu sisään"])},
  "socialNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosiaalisen verkoston tilit"])},
  "somethingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jokin epäonnistui"])},
  "specialRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erikoispyynnöt"])},
  "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erikoispyynnöt"])},
  "specifyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä osoitetiedot"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alkaa"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloituspäivämäärä"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osavaltio"])},
  "statisticsReport": {
    "companyInformation": {
      "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisaation nimi"])},
      "organizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisaatio"])}
    }
  },
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katu"])},
  "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alaluokka"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteenveto"])},
  "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valvoja"])},
  "support": {
    "card": {
      "title": {
        "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota meihin yhteyttä"])},
        "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetä viesti"])}
      }
    },
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klo 9-17"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköposti"])},
    "facebookGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook ryhmä"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuki"])},
    "supportExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähettämällä tämän lomakkeen lähetät kirjeen Touringery-sivuston tukihenkilöstölle. \nTämän lomakkeen tarkoituksena on antaa palautetta ja raportoida sivustoa koskevista teknisistä ongelmista."])},
    "tabLabel": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottaa yhteyttä"])},
      "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohjekeskus"])}
    },
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhelin"])}
  },
  "switchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda yritystä"])},
  "tabs": {
    "all_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki varaukset"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varauslista"])},
    "servicePeriodsForEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinta"])}
  },
  "takeMeThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vie minut sinne"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä"])},
  "taskAcceptSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä hyväksytty"])},
  "taskColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän väri"])},
  "taskColorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän väri edustaa tehtäväkortin väriä kalenterinäkymässä. \nTämä parantaa kontrastia ja tekee kalenterista luettavamman."])},
  "taskCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän luominen onnistui"])},
  "taskCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän luominen epäonnistui"])},
  "taskDeleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän poistaminen epäonnistui"])},
  "taskDeletedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän poistaminen onnistui"])},
  "taskDurationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän kesto on käytettävissä vain, kun tehtävän tyypiksi on asetettu avoin aikaväli. \nTehtävän kesto antaa mahdollisuuden määrittää keston, joka on pienempi kuin aloitus- ja määräajan ero, jotta vastaanottaja voi tehdä tehtävän, kun sen tekemiseen on vapaata aikaa."])},
  "taskEndedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä on päättynyt, eikä sitä voi enää muuttaa"])},
  "taskGeneratedCreatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattinen tehtävä '", _interpolate(_named("taskTitle")), "' luotiin onnistuneesti"])},
  "taskGeneratedCreationError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattisen tehtävän '", _interpolate(_named("taskTitle")), "' luominen epäonnistui"])},
  "taskGeneratedDeleteError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattisen tehtävän '", _interpolate(_named("taskTitle")), "' poistaminen epäonnistui"])},
  "taskGeneratedDeleteSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattinen tehtävä '", _interpolate(_named("taskTitle")), "' poistettu onnistuneesti"])},
  "taskGeneratedUpdateError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattinen tehtävän '", _interpolate(_named("taskTitle")), "' päivitys epäonnistui"])},
  "taskGeneratedUpdatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaattinen tehtävä '", _interpolate(_named("taskTitle")), "' luotiin onnistuneesti"])},
  "taskGenerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisoidut tehtävät"])},
  "taskGeneratorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täällä voit määrittää tehtäviä, jotka luodaan automaattisesti annettujen muuttujien avulla"])},
  "taskHasEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä on päättynyt"])},
  "taskInformationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän tiedot tallennetaan"])},
  "taskInformationSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän tietojen tallentaminen epäonnistui"])},
  "taskMarkedAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä on merkitty suoritetuksi"])},
  "taskMarkedAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä on merkitty tekemättä"])},
  "taskPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtäväkausi"])},
  "taskRefuseSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävä hylätty"])},
  "taskRefuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kieltäytyä tehtävästä?"])},
  "taskReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muistutus vastaanottajalle"])},
  "taskReminderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävämuistutusaika muistuttaa vastaanottajaa sähköpostitse tulevasta tehtävästä"])},
  "taskWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varoitus valvojalle"])},
  "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävät"])},
  "teamPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joukkueen käyttöoikeudet"])},
  "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttöehdot"])},
  "textEditor": {
    "enterLinkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["syötä linkki"])}
  },
  "thisMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämän kuun tulokset"])},
  "ticket": {
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostaja"])},
    "fetchTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe haettaessa lipputietoja"])},
    "invalidScannedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannatun koodin sisällössä on odottamaton muoto"])},
    "notValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei validoitu"])},
    "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksettu klo"])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lippu"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liput"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])},
    "unValidateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipun vahvistus keskeytetty onnistuneesti"])},
    "unvalidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda vahvistustilaa"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa alkaen"])},
    "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa asti"])},
    "validateTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipun vahvistusvirhe"])},
    "validateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lippu on vahvistettu onnistuneesti"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistettu"])},
    "validatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validointiaika"])},
    "validatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvisti"])},
    "validationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validointi"])},
    "validationState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validointitila"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassaolo"])}
  },
  "ticketValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistus onnistui"])},
  "ticketValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistus epäonnistui"])},
  "ticketValidity": {
    "disclaimer": {
      "daysFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päivää ostosta"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siihen asti kun"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa"])},
      "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassa asti"])}
    },
    "fixedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiinteät päivämäärät"])},
    "fromUntilDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloitus- ja lopetuspäivät"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos määrität hinnan voimassaoloajan, nämä tiedot näkyvät tietyn ajanjakson lipuissa. \nVoit määrittää joko päivämäärät lomakkeen ja asti tai asettaa ajanjakson ostohetkestä alkaen"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnan voimassaoloaika"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrittämätön"])},
    "periodFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajanjakso ostosta"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voimassaoloajan tyyppi"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsikko"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastaanottaja"])},
  "toDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avata"])},
  "total": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrä"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki yhteensä"])},
    "totalWithDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä alennuksella"])},
    "totalWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä ilman arvonlisäveroa"])}
  },
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokonaishinta"])},
  "totalTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuotot majoituspalvelujen myynnistä"])},
  "travelPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkan tarkoitus"])},
  "travelingToDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkalla määränpäähän"])},
  "travellersCountry": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkustajan kansalaisuus"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maatunnus"])},
    "housingOvernight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yön aikana matkustavien määrä"])},
    "roomsSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkustajien määrä"])}
  },
  "travellingPurposesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majoituneiden henkilöiden jakautuminen matkan tarkoituksen mukaan"])},
  "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laukaista"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])},
  "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyypit"])},
  "unCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista kaikkien valinta"])},
  "unauthorized": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole lupaa käyttää tätä näkymää"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luvaton"])}
  },
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksikkö"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siihen asti kun"])},
  "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuleva"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivittää"])},
  "updateCartItemError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostoskorin päivitys epäonnistui"])},
  "updateCartItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostoskori päivitetty onnistuneesti"])},
  "updateInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä ostoskori"])},
  "uploadNewAvatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa uusi avatar"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjä"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henkilökohtaiset tiedot"])},
  "userNotAssociatedWithCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjää ei ole liitetty yritykseen"])},
  "userRoleSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjäroolin tallentaminen epäonnistui"])},
  "userRoleSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjän roolin tallennus onnistui"])},
  "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loma"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arvonlisävero"])},
  "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALV-numero"])},
  "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vierailija"])},
  "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vierailijoita"])},
  "warningInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtävän varoitusaika varoittaa vastaanottajaa ja valvojaa sähköpostitse reagoimattomasta tehtävästä"])},
  "week": {
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perjantai"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maanantai"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lauantai"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunnuntai"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torstai"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiistai"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keskiviikko"])}
  },
  "weekSlots": {
    "buttons": {
      "setClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyhjä"])},
      "setNineToFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9-17 työpäivää"])},
      "setTenToEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10-20 joka päivä"])},
      "setWholeWeek ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koko viikko"])}
    }
  },
  "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun"])},
  "whenAreYouGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milloin olet menossa?"])},
  "where": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missä"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehdä työtä"])},
  "wrongUserNameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väärä käyttäjätunnus tai salasana!"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joo"])},
  "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profiilisi"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postinumero"])}
}