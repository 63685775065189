<template>
  <div :class="['card', small && 'card--small']">
    <div v-if="$slots.header" class="card__header">
      <slot name="header"></slot>
    </div>
    <div
      class="card__body"
      :class="{ 'card__body--no-min-height': noMinHeight }"
    >
      <slot name="body"></slot>
    </div>
    <div v-if="$slots.footer" class="card__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    noMinHeight: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
@import '~/src/assets/scss/typography.scss';
@import '~/src/assets/scss/_mixins.scss';
.card {
  background: $white;
  border: 1px solid $line;
  box-shadow: $card-elevation-shadow;
  border-radius: 8px;
  padding: $sp-32 $sp-24;

  &--small {
    box-shadow: none;
    padding: $sp-16;
  }

  &__header {
    @extend %headline-3;
    display: flex;
    justify-content: space-between;
    margin-bottom: $sp-24;
    gap: $sp-8;

    @at-root .card--small & {
      @extend %headline-4;
    }
    @include flex-column-mobile;
  }

  &__body {
    min-height: 100px;

    &--no-min-height {
      min-height: initial;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    gap: $sp-16;
    border-top: 1px solid $line;
    padding-top: $sp-16;
    @include flex-column-mobile;
  }
}
</style>
