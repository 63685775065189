import {
  InvitedUserForm,
  SetNewPasswordForm,
  UserInfo,
  UserRegisterForm,
} from '@/models/auth.model'
import axios from 'axios'

interface LoginResponse {
  token: string
  user: UserInfo
}

export async function authenticateUser(email: string, password: string) {
  try {
    const { data } = await axios.post<LoginResponse>(
      '/auth/login/',
      { email, password },
      {
        headers: {
          Accept: 'application/json',
          Authorization: ``,
        },
      },
    )
    axios.defaults.headers.common['Authorization'] = `Token ${data.token}`
    localStorage.setItem('tour-token', data.token)
    return data
  } catch (error) {
    handleAxiosError(error)
  }
}

export async function getUser() {
  try {
    const { data } = await axios.get<UserInfo>('/user/', {
      headers: {
        Authorization: `Token ${localStorage.getItem('tour-token')}`,
      },
    })
    return data
  } catch (error) {
    handleAxiosError(error)
  }
}

export async function logoutUser() {
  try {
    const { data } = await axios.post<''>('/auth/logout/')
    return data
  } catch (error) {
    handleAxiosError(error)
  }
}

export async function registerUser(form: UserRegisterForm) {
  const { data } = await axios.post('/auth/register/', form, {
    headers: {
      Authorization: ``,
    },
  })
  return data
}

export async function requestPasswordReset(email: string) {
  const { data } = await axios.post(
    '/auth/password/reset/',
    { email },
    {
      headers: {
        Authorization: ``,
      },
    },
  )
  return data
}

export async function setNewPassword(form: SetNewPasswordForm) {
  const { data } = await axios.post('/auth/password/reset/confirm/', form, {
    headers: {
      Authorization: ``,
    },
  })
  return data
}

export async function setInvitedUserData(form: InvitedUserForm) {
  const { data } = await axios.post('/auth/invited/', form, {
    headers: {
      Authorization: ``,
    },
  })
  return data
}

function handleAxiosError(error: unknown) {
  if (axios.isAxiosError(error)) {
    throw error.message
  } else {
    throw 'An unexpected error occurred'
  }
}
