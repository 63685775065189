import {
  AddressDetailsPayload,
  AddressSuggestionsPayload,
} from '@/models/location.model'
import axios from 'axios'
import { Ref } from 'vue'
import { useMutation, useQueryClient } from 'vue-query'

// https://tkdodo.eu/blog/effective-react-query-keys
const googleAddressKeys = {
  all: ['googleAddresses'] as const,
  lists: () => [...googleAddressKeys.all, 'list'] as const,
  list: (addressSearchString: string) =>
    [...googleAddressKeys.lists(), addressSearchString] as const,
}

export function useFetchAddressSuggestions(
  addressSearchString: Ref<string>,
  sessionToken: Ref<string>,
) {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const { data } = await axios.get<AddressSuggestionsPayload[]>(
        'google/address/suggest/',
        {
          params: {
            search_string: addressSearchString.value,
            session_token: sessionToken.value,
          },
          headers: { Authorization: `` },
        },
      )
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(googleAddressKeys.lists())
        queryClient.setQueryData(
          googleAddressKeys.list(addressSearchString.value),
          data,
        )
      },
    },
  )
}

export function useFetchAddressDetails(sessionToken: Ref<string>) {
  return useMutation(async (placeId: string | number) => {
    const { data } = await axios.get<AddressDetailsPayload>(
      `google/address/find/`,
      {
        params: {
          place_id: placeId,
          session_token: sessionToken.value,
        },
        headers: { Authorization: `` },
      },
    )
    return data
  })
}
